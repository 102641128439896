import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';

import AddNewEngineModalStyles from './AddNewEngineModalStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import EngineService from '../../services/EngineService';
import EngineValidator from '../../lib/validators/EngineValidator';
import EventEmitter from '../../util/EventEmitter';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RegexEnum from '../../models/RegexEnum';
import RolesEnum from '../../models/RolesEnum';
import RoutePath from '../../lib/RoutePath';
import TextField from '@material-ui/core/TextField';
import { capitalizeFirstLetter } from '../../util/CommonUtils';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { toast } from 'material-react-toastify';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = AddNewEngineModalStyles;

class AddNewEngineModal extends Component {
  constructor(props) {
    super(props);
    // let tempProdMode = true;
    let tempProdMode = false;
    if (process.env.NODE_ENV === 'development' && !tempProdMode) {
      this.state = {
        uploadOption: 'image',
        engineName: 'EngineA',
        engineSerialNumber: `V${Math.floor(Math.random() * 90000) + 10000}`,
        ITCEngineerName: 'John Doe',
        engineLocation: 'Gurugram',
        esnError: { success: true },
        listOfUsers: [],
        selectedTechnician: '',
      };
    } else if (process.env.NODE_ENV === 'production' || tempProdMode) {
      this.state = {
        uploadOption: '',
        engineName: '',
        engineSerialNumber: '',
        ITCEngineerName: '',
        engineLocation: '',
        esnError: { success: true },
        listOfUsers: [],
        selectedTechnician: '',
      };
    }
  }

  componentDidMount = () => {
    this.handleGetListOfEmployees();
  };

  handleGetListOfEmployees = async () => {
    // let { initialStartDate, initialEndDate } = this.props;
    let data = {};
    let response = await EngineService.listOfUsers(data);
    if (response.success) {
      this.setState({
        listOfUsers: response.data,
      });
    }
  };

  handleSelectImage = () => {
    this.setState({ uploadOption: 'image' });
  };

  handleSelectVideo = () => {
    this.setState({ uploadOption: 'video' });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleESNChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let esnError = await EngineValidator.isValidESN(e.target.value);
    // console.log(esnError);
    this.setState({ esnError });
  };

  handleFileChange = (e) => {
    if (e.target.files.length) {
      this.setState({ image: e.target.files[0] });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    console.log('called');
    let { loggedUser } = this.props;
    let {
      image: file,
      engineName,
      engineSerialNumber,
      ITCEngineerName,
      uploadOption,
      esnError,
      engineLocation,
    } = this.state;
    console.log(file);
    if (uploadOption) {
      if (file) {
        if (esnError?.success) {
          this.setState({ isUploading: true });
          let formData = new FormData();
          formData.append('awi_label', engineName);
          formData.append('type', uploadOption);
          formData.append('awi_serial_num', engineSerialNumber);
          formData.append('itc_expert_name', ITCEngineerName);
          formData.append('location', engineLocation);

          if (!file.name) {
            formData.append('file', file, 'engine-image.jpeg');
          } else {
            formData.append('file', file);
          }
          for (var pair of formData.entries()) {
            console.log('formData', pair[0] + ', ' + pair[1]);
          }
          let response = await EngineService.addNewEngine(formData);
          if (response.success) {
            // EventEmitter.emit('newEngineAdded');
            this.handleAddEngineNotification(response.data);
            console.log('addNewEngine', response);
            toast.success(response.message);
            this.props.onClose();
          } else {
            toast.error(response.message);
            this.setState({ isUploading: false });
          }
        } else {
          toast.error(`Please match the provided ESN Pattern`);
        }
      } else {
        toast.error(`Please upload an image to proceed`);
      }
    } else {
      toast.error(`Please Select the Engine Recording Type`);
    }
  };

  handleAddEngineNotification = async (engineRegistrationResponseData) => {
    let { loggedUser } = this.props;
    let {
      // image: file,
      engineName,
      engineSerialNumber,
      ITCEngineerName,
      uploadOption,
      esnError,
      engineLocation,
    } = this.state;
    console.log('loggedUser', loggedUser);
    let data = {
      awi_engine_id: engineRegistrationResponseData.id,
      awi_content_id: engineRegistrationResponseData.awi_content_id,
      text: `Engine ${engineSerialNumber} added by ${loggedUser.userObj.email}`,
      type: 'new_engine',
      awi_content_type: 'engine',
      awi_content_label: 'engine',
    };
    console.log('handleAddEngineNotificationData', data);
    let response = await EngineService.addNewComment(data);
    console.log('handleAddEngineNotification', response);
    if (response.success) {
      this.setState({
        image: undefined,
        engineSerialNumber: '',
        engineName: '',
      });
      if (loggedUser.userObj?.level === RolesEnum.MANAGER) {
        this.handleAssignEngine(engineRegistrationResponseData);
      } else if (loggedUser.userObj?.level === RolesEnum.TECHNICIAN) {
        RoutePath.navigateTo(
          this.props,
          RoutePath.dataCollectionProfileSelectionPath(
            engineRegistrationResponseData.id
          )
        );
      }
    } else {
      toast.error(response.message);
      this.setState({ isUploading: false });
    }
  };

  handleAssignEngine = async (engineRegistrationResponseData) => {
    let { selectedTechnician } = this.state;

    let data = {
      awi_engine_id: engineRegistrationResponseData.id * 1,
      awi_user_id: selectedTechnician.id,
    };
    console.log(data);
    let response = await EngineService.assignEngineToTechnician(data);
    console.log('handleAssignEngine', response);
    if (response.success) {
      toast.success(response.message);
      RoutePath.navigateTo(
        this.props,
        RoutePath.engineDetails(engineRegistrationResponseData.id)
      );
    } else {
      toast.error(response.message);
      this.setState({ isUploading: false });
    }
  };

  isIos = () => {
    return navigator.platform.toLowerCase() === 'iphone';
  };

  render() {
    let { classes, open, onClose, loggedUser } = this.props;
    let {
      isUploading,
      uploadOption,
      engineName,
      engineSerialNumber,
      ITCEngineerName,
      image,
      esnError,
      engineLocation,
      listOfUsers,
      selectedTechnician,
    } = this.state;

    // console.log(image);
    return (
      <div className={classes.root}>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.modalContainer}>
            <Paper className={classes.modalPaper}>
              <div className={classes.closeButtonContainer}>
                <IconButton onClick={onClose} className={classes.closeButton}>
                  <CancelIcon />
                </IconButton>
              </div>

              <div
                className={clsx(classes.contentContainer, 'scrollbar_primary')}
              >
                <Grid container direction="column" spacing={2}>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Engine Serial Number"
                    error={!esnError?.success && !!engineSerialNumber}
                    helperText={esnError?.message}
                    // inputProps={{
                    //   // className: classes.input,
                    //   pattern: RegexEnum.ESNString,
                    // }}
                    // pattern={RegexEnum.ESN}
                    id="engineSerialNumber"
                    name="engineSerialNumber"
                    value={engineSerialNumber}
                    onChange={this.handleESNChange}
                  />
                  <TextField
                    required
                    // autoFocus
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Engine Name"
                    id="engineName"
                    name="engineName"
                    value={engineName}
                    onChange={this.handleChange}
                  />

                  <Grid item>
                    <Typography variant="body1">
                      Select your Choice* 
                    </Typography>
                    <ButtonGroup
                      fullWidth
                      // variant="contained"
                      color="primary"
                      aria-label="contained primary button group"
                      className={classes.uploadOptionSelector}
                    >
                      <Button
                        // disabled
                        variant={
                          uploadOption === 'image' ? 'contained' : 'outlined'
                        }
                        onClick={this.handleSelectImage}
                      >
                        Image
                      </Button>
                      <Button
                        // disabled={this.isIos()}
                        variant={
                          uploadOption === 'video' ? 'contained' : 'outlined'
                        }
                        onClick={this.handleSelectVideo}
                      >
                        Video
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  <Grid item style={{ maxWidth: '100%' }}>
                    <form onSubmit={this.handleSubmit}>
                      <Typography variant="body1">
                        Upload Thumbnail Image
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid item>
                          <input
                            multiple={false}
                            accept={'image/jpeg,image/png'}
                            // accept={
                            //   uploadOption === 'image'
                            //     ? 'image/jpeg,image/png'
                            //     : 'video/*'
                            // }
                            capture="camera"
                            className={classes.input}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={this.handleFileChange}
                          />
                          <label htmlFor="raised-button-file">
                            <Button
                              variant="contained"
                              component="span"
                              className={classes.button}
                            >
                              Choose File
                            </Button>
                          </label>
                        </Grid>
                        <Grid
                          item
                          xs
                          zeroMinWidth
                          // className={classes.fileNameTextContainer}
                        >
                          <Typography noWrap>{image?.name}</Typography>
                        </Grid>
                      </Grid>

                      <br />
                      <br />
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.engineTypeSelect}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Engine Type
                        </InputLabel>
                        <Select
                          disabled
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={'V2500'}
                          label="Engine Type"
                          // onChange={handleChange}
                        >
                          <MenuItem value={'V2500'}>V2500</MenuItem>
                          {/* <MenuItem value={20}>Twenty</MenuItem> */}
                          {/* <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      {loggedUser.userObj?.level === RolesEnum.MANAGER ? (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={classes.engineTypeSelect}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Technician
                          </InputLabel>
                          <Select
                            // disabled
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedTechnician}
                            name={'selectedTechnician'}
                            label="Technician"
                            onChange={this.handleChange}
                          >
                            <MenuItem value={''}>
                              <em>None</em>
                            </MenuItem>
                            {listOfUsers.map((userDetails, index) => {
                              return (
                                <MenuItem key={index} value={userDetails}>
                                  {capitalizeFirstLetter(
                                    userDetails?.awi_personal?.firstname
                                  )}{' '}
                                  {capitalizeFirstLetter(
                                    userDetails?.awi_personal?.lastname
                                  )}{' '}
                                  - {userDetails.email}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : null}

                      <TextField
                        required
                        // autoFocus
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Location"
                        id="engineLocation"
                        name="engineLocation"
                        value={engineLocation}
                        onChange={this.handleChange}
                      />

                      <br />
                      <br />
                      <br />
                      <Typography variant="body1" align="left">
                        I confirm that the following data collection process is
                        happening in the presence of an ITC expert
                      </Typography>
                      <TextField
                        required
                        // autoFocus
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="ITC Expert Name"
                        id="ITCEngineerName"
                        name="ITCEngineerName"
                        value={ITCEngineerName}
                        onChange={this.handleChange}
                      />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {/* <CircularProgress
                          color="inherit"
                          hidden={!isUploading}
                        /> */}

                        {isUploading ? (
                          <CircularProgress color="primary" />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            // onClick={this.handleFileUpload}
                            type="submit"
                            className={classes.submitButton}
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </form>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
        </Modal>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedIn: state.UserReducer.loggedIn,
    loggedUser: state.UserReducer.loggedUser,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    // loginUser: (data) => dispatch(UserActions.loginUser(data)),
    // setDarkMode: (value) => dispatch(SettingsActions.setDarkMode(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AddNewEngineModal)));
