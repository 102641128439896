import { Fab, Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import EngineService from '../../services/EngineService';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterModal from '../EngineList/FilterModal/FilterModal';
import TechnicianDetailsStyles from './TechnicianDetailsStyles';
import TechnicianEngineDetailsCard from './TechnicianEngineDetailsCard/TechnicianEngineDetailsCard';
import { capitalizeFirstLetter } from '../../util/CommonUtils';
import { isMobile } from 'mobile-device-detect';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = TechnicianDetailsStyles;

class TechnicianDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianData: undefined,
      engineList: [],
      filteredEngineList: [],

      searchString: '',
      selectedStatusList: [],
      selectedTechnicianList: [],
      filterModalOpen: false,
      selectedStartDate: undefined,
      selectedEndDate: undefined,
    };
  }

  componentDidMount = () => {
    this.handleGetTechnicianData();
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {
      engineList,

      searchString,
      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    console.log('cdu called')
    if (
      // prevState.engineList !== engineList ||
      prevState.selectedStatusList !== selectedStatusList ||
      prevState.selectedTechnicianList !== selectedTechnicianList ||
      prevState.selectedStartDate !== selectedStartDate ||
      prevState.selectedEndDate !== selectedEndDate
    ) {
      console.log('lol')
      this.getEngineStatusInHierarchy()();
      // this.getFilteredList();
    }

    if (
      // prevState.engineList !== engineList ||
      prevState.searchString !== searchString
    ) {
      this.getFilteredEngineList();
    }
  };

  handleGetTechnicianData = async () => {
    let { technicianID } = this.props.match.params;
    let data = {
      awi_ids: [technicianID * 1],
    };
    let response = await EngineService.listOfUsers(data);
    console.log('handleGetListOfUsers', response);
    if (response.success) {
      // TODO implement these
      this.setState(
        { technicianData: response.data[0] },
        this.getEngineStatusInHierarchy(response.data[0].engine_ids)
      );
    }
  };

  getEngineStatusInHierarchy = (engineIDList) => async () => {
    let { technicianID } = this.props.match.params;
    console.log('getEngineStatusInHierarchy called')
    let {
      // filteredEngineList,
      // filterModalOpen,
      // engineList,

      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    // console.log(selectedStartDate, selectedEndDate);
    let data = {
      start_time: selectedStartDate
        ? new Date(selectedStartDate).getTime()
        : undefined,
      end_time: selectedEndDate
        ? new Date(selectedEndDate).getTime()
        : undefined,
      status_filters: selectedStatusList,
      awi_user_ids: selectedTechnicianList.map((technicianData, index) => {
        return technicianData.id;
      }),
    };
    let response = await EngineService.getEngineStatusInHierarchy(data);
    console.log('getEngineStatusInHierarchy', response);
    if (response.success) {
      // TODO implement these
      this.setState({ engineList: response.data }, () => {
        if (!selectedStartDate) {
          this.setInitialDates();
        }
        this.getFilteredEngineList();
      });
    } else {
      this.setState({ engineList: [] }, this.getFilteredEngineList);
    }
  };

  setInitialDates = () => {
    // let { initialStartDate, initialEndDate } = this.props;
    let { engineList } = this.state;
    this.setState({
      selectedStartDate: engineList.length && engineList[0].ts_creation,
      selectedEndDate:
        engineList.length && engineList[engineList.length - 1].ts_creation,
    });
  };

  getFilteredEngineList = (engineList = this.state.engineList) => {
    let { searchString } = this.state;

    let filteredEngineList = engineList.filter((engineData, index) => {
      let searchStringMatched = false;
      console.log(engineData);
      if (searchString) {
        if (
          engineData.awi_serial_num
            .toLowerCase()
            .search(searchString.toLowerCase()) >= 0 ||
          engineData.location
            .toLowerCase()
            .search(searchString.toLowerCase()) >= 0 ||
          engineData.approved_status
            .toLowerCase()
            .search(searchString.toLowerCase()) >= 0
        ) {
          searchStringMatched = true;
        }
      } else {
        searchStringMatched = true;
      }
      return searchStringMatched;
    });
    console.log(filteredEngineList);
    this.setState({ filteredEngineList });
  };

  handleFilterModalOpen = () => {
    this.setState({ filterModalOpen: true });
  };

  handleFilterModalClose = () => {
    this.setState({ filterModalOpen: false });
  };

  setSearchString = (searchString) => {
    this.setState({ searchString });
  };

  handleSetSelectedStatus = (selectedStatusList) => {
    this.setState({ selectedStatusList });
  };

  setSelectedTechnicianList = (selectedTechnicianList) => {
    this.setState({ selectedTechnicianList });
  };

  setSelectedStartDate = (selectedStartDate) => {
    this.setState({ selectedStartDate });
  };

  setSelectedEndDate = (selectedEndDate) => {
    this.setState({ selectedEndDate });
  };

  render() {
    let { classes } = this.props;
    let {
      technicianData,
      engineList,
      filteredEngineList,
      filterModalOpen,

      searchString,
      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    console.log(technicianData);
    return (
      <div className={classes.root}>
        <div className={classes.headingContainer}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                TECHNICIAN DETAILS
              </Typography>
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                Technician Details
              </Typography>
            </div>
          )}
        </div>
        {technicianData ? (
          <div className={classes.pageContainer}>
            <Grid container direction="row">
              <Grid
                item
                xs={3}
                container
                direction="row"
                spacing={2}
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <div className={classes.profileIconThumbnail}>
                    {technicianData.awi_personal.firstname
                      .charAt(0)
                      .toUpperCase()}
                    {technicianData.awi_personal.lastname
                      .charAt(0)
                      .toUpperCase()}
                  </div>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    {capitalizeFirstLetter(
                      technicianData.awi_personal.firstname
                    )}
                    &nbsp;
                    {capitalizeFirstLetter(
                      technicianData.awi_personal.lastname
                    )}
                  </Typography>
                  <Typography>
                    {capitalizeFirstLetter(technicianData.title)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <Typography>Total Uploads</Typography>
                <Typography className={classes.boldText}>
                  {technicianData.total_engines.count}
                </Typography>
                <br />
                <br />

                <Typography>Last Active</Typography>
                <Typography className={classes.boldText}>
                  {moment(technicianData.ts_last_active).format('LL')}
                  <br />
                  {moment(technicianData.ts_last_active).format('LT')}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography>Active Engines</Typography>
                <Typography className={classes.boldText}>
                  {technicianData.total_engines.count -
                    technicianData?.engine_status.incomplete -
                    technicianData?.engine_status.approved -
                    technicianData?.engine_status.cancelled || 0}
                </Typography>
                <br />
                <br />

                <Typography>Incomplete Engines</Typography>
                <Typography className={classes.boldText}>
                  {technicianData?.engine_status.incomplete || 0}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography>Approved Engines</Typography>
                <Typography className={classes.boldText}>
                  {technicianData?.engine_status.approved || 0}
                </Typography>
                <br />
                <br />

                <Typography>Cancelled Engines</Typography>
                <Typography className={classes.boldText}>
                  {technicianData?.engine_status.cancelled || 0}
                </Typography>
              </Grid>
            </Grid>

            <br />
            <br />
            <br />
            <div>
              <Typography variant="h6">Previous Sessions:</Typography>
              <Grid container direction="row" spacing={2}>
                {filteredEngineList.map((engineData, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={engineData.id}>
                      <TechnicianEngineDetailsCard engineData={engineData} />
                    </Grid>
                  );
                })}
              </Grid>
            </div>

            <Fab
              onClick={this.handleFilterModalOpen}
              color="primary"
              aria-label="filter"
              className={classes.fab}
              style={!isMobile ? { bottom: 16 } : null}
            >
              <FilterListIcon />
            </Fab>
            <FilterModal
              open={filterModalOpen}
              onClose={this.handleFilterModalClose}
              searchString={searchString}
              setSearchString={this.setSearchString}
              selectedStatusList={selectedStatusList}
              setSelectedStatus={this.handleSetSelectedStatus}
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={this.setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={this.setSelectedEndDate}
              selectedTechnicianList={selectedTechnicianList}
              setSelectedTechnicianList={this.setSelectedTechnicianList}
              // selectedStartDate={
              //   engineList.length && engineList[engineList.length - 1].ts_creation
              // }
              // initialEndDate={this.handleSetSelectedStatus}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(TechnicianDetails);
