import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
} from '@material-ui/core';
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from '../CommonComponents/CustomAccordion/CustomAccordion';
import React, { Component } from 'react';
import { capitalizeFirstLetter, commonRoundOff } from '../../util/CommonUtils';

import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import EngineService from '../../services/EngineService';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import PortionSelectionActions from '../../redux/actions/PortionSelectionActions';
import PortionSelectionPageStyles from './PortionSelectionPageStyles';
import RefreshIcon from '@material-ui/icons/Refresh';
import RoutePath from '../../lib/RoutePath';
import { connect } from 'react-redux';
import { isMobile } from 'mobile-device-detect';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = PortionSelectionPageStyles;

class PortionSelectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList: [],
      engineData: undefined,
      expandedProfile: '',
      expandedSection: '',
      expandedSubSection: '',

      completionInfoOpenFlag: '',
    };
  }
  componentDidMount = () => {
    this.handleFetchPredefinedData();
  };

  handleFetchPredefinedData = async (e) => {
    let data = {};
    let response = await EngineService.preFetchEntriesInHierarchy(data);
    console.log('handleFetchPredefinedData', response);
    if (response.success) {
      this.setState(
        { profileList: response.data },
        this.handleFetchEngineStatus
      );
    }
  };

  handleFetchEngineStatus = async (e) => {
    let { engineID } = this.props.match.params;
    let profileList = JSON.parse(JSON.stringify(this.state.profileList));
    let data = {
      awi_engine_id: engineID * 1,
    };
    let response = await EngineService.getEngineStatusInHierarchy(data);
    console.log('handleFetchEngineStatus', response);
    if (response.success) {
      // console.log(object)
      this.setState({ engineData: response.data[0] });
      response.data[0].awi_profiles.forEach((completedProfileData, index) => {
        for (let profileIndex in profileList) {
          let profileData = profileList[profileIndex];
          // console.log('profileData', profileData);
          // console.log('profileData', response.data[0].awi_profiles);
          // console.log(
          //   completedProfileData.awi_label,
          //   profileList[profileIndex].awi_label
          // );
          let profileCompleted = false;
          // let subSectionCompleted = false;
          if (
            completedProfileData.awi_label ===
            profileList[profileIndex].awi_label
          ) {
            profileCompleted = true;
            if (
              profileData.list_of_sections.length ===
              completedProfileData.list_of_sections.length
            ) {
              profileCompleted = 'fully';
            }
            // console.log('completed profile', profileList[profileIndex]);
            profileList[profileIndex].completed = profileCompleted;

            //
            //
            //
            let sectionCompleted = false;

            let sectionList = profileData.list_of_sections;
            let completedSectionList = completedProfileData.list_of_sections;
            completedSectionList.forEach((completedSectionData, index) => {
              for (let sectionIndex in sectionList) {
                let sectionData = sectionList[sectionIndex];
                // console.log(
                //   completedProfileData.awi_label,
                //   profileList[profileIndex].awi_label
                // );

                let sectionCompleted = false;
                if (completedSectionData.awi_label === sectionData.awi_label) {
                  sectionCompleted = true;

                  if (
                    sectionData.list_of_sub_sections.length ===
                    completedSectionData.list_of_sub_sections.length
                  ) {
                    sectionCompleted = 'fully';
                  }

                  // console.log('completed section', profileList[profileIndex]);
                  profileList[profileIndex].list_of_sections[
                    sectionIndex
                  ].completed = sectionCompleted;

                  // console.log(profileList[profileIndex]);
                  // console.log(profileList[profileIndex].list_of_sections);
                  // console.log(
                  //   profileList[profileIndex].list_of_sections[sectionIndex]
                  // );

                  let subSectionCompleted = false;

                  let subSectionList = sectionData.list_of_sub_sections;
                  let completedSubSectionList =
                    completedSectionData.list_of_sub_sections;

                  completedSubSectionList.forEach(
                    (completedSubSectionData, index) => {
                      for (let subSectionIndex in subSectionList) {
                        let subSectionData = subSectionList[subSectionIndex];
                        // console.log(
                        //   completedProfileData.awi_label,
                        //   profileList[profileIndex].awi_label
                        // );

                        // let subSectionCompleted = false;
                        // console.log(completedSubSectionData);
                        // console.log(subSectionList);
                        // console.log(subSectionIndex, subSectionData);
                        if (
                          completedSubSectionData.awi_label ===
                          subSectionData.awi_label
                        ) {
                          subSectionCompleted = true;

                          // if (
                          //   subSectionData.list_of_sections.length ===
                          //   completedSubSectionData.list_of_sections.length
                          // ) {
                          //   subSectionCompleted = 'fully';
                          // }
                          // let subSectionCompleted = false;
                          // console.log(
                          //   'completed subsection',
                          //   profileList[profileIndex]
                          // );
                          profileList[profileIndex].list_of_sections[
                            sectionIndex
                          ].list_of_sub_sections[
                            subSectionIndex
                          ].completed = true;
                        }
                      }
                    }
                  );
                }
              }
            });
          }
          // }
        }
      });
    }
    console.log(profileList);
    this.setState({ profileList });
  };

  handleProfilePanelChange = (panel) => (event, newExpanded) => {
    this.props.setExpandedProfile(newExpanded ? panel : false);
    // this.setState({ expandedProfile: newExpanded ? panel : false });
  };

  handleSectionPanelChange = (panel) => (event, newExpanded) => {
    this.props.setExpandedSection(newExpanded ? panel : false);
    // this.setState({ expandedSection: newExpanded ? panel : false });
  };

  handleSubSectionPanelChange = (panel) => (event, newExpanded) => {
    this.props.setExpandedSubSection(newExpanded ? panel : false);
    // this.setState({ expandedSubSection: newExpanded ? panel : false });
  };

  handleCompletionInfoOpen = (event) => {
    this.setState({
      completionInfoAnchorEl: event.currentTarget,
    });
  };

  handleCompletionInfoClose = () => {
    this.setState({
      completionInfoAnchorEl: null,
    });
  };

  render() {
    let {
      classes,
      match,
      expandedProfile,
      expandedSection,
      expandedSubSection,
    } = this.props;
    let { engineID } = match.params;
    let {
      engineData,

      completionInfoOpenFlag,
      completionInfoAnchorEl,
      profileList,
    } = this.state;
    return (
      <div className={classes.root}>
        <div style={{ width: '100%' }}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                DATA COLLECTION NAVIGATOR
              </Typography>
              <Button
                variant="contained"
                onClick={this.handleFetchPredefinedData}
              >
                <RefreshIcon /> Refresh
              </Button>
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                Data Collection Navigator
              </Typography>
              <Button
                variant="contained"
                onClick={this.handleFetchPredefinedData}
              >
                <RefreshIcon /> Refresh
              </Button>
            </div>
          )}
        </div>

        <div className={classes.pageContainer}>
          {engineData ? (
            <div>
              <div className={classes.statusBarContainer}>
                <Grid item container direction="row" justify={'flex-end'}>
                  <Grid item xs={12}>
                    <div>
                      <LinearProgress
                        variant="determinate"
                        value={engineData.metrics.overall.percent}
                        valueBuffer={88}
                        // valueBuffer={engineData.metrics.overall.percent}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    container
                    direction={'row'}
                    justify={'flex-end'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography
                        variant={'caption'}
                        className={classes.boldText}
                      >
                        {commonRoundOff(engineData.metrics.overall.percent, 2)}%
                        Completed
                      </Typography>
                    </Grid>

                    <Grid>
                      <IconButton
                        size={'small'}
                        onClick={this.handleCompletionInfoOpen}
                      >
                        <InfoIcon />
                      </IconButton>
                      <Popover
                        id={
                          completionInfoOpenFlag
                            ? 'completion-info-modal'
                            : undefined
                        }
                        open={!!completionInfoAnchorEl}
                        anchorEl={completionInfoAnchorEl}
                        onClose={this.handleCompletionInfoClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div className={classes.completionInfoModalContainer}>
                          <Typography variant={'caption'}>Profiles:</Typography>
                          <Typography
                            variant={'caption'}
                            className={classes.boldText}
                          >
                            {engineData.metrics.profiles.actual}/
                            {engineData.metrics.profiles.expected}
                          </Typography>
                          <br />
                          <Typography variant={'caption'}>Sections:</Typography>
                          <Typography
                            variant={'caption'}
                            className={classes.boldText}
                          >
                            {engineData.metrics.sections.actual}/
                            {engineData.metrics.sections.expected}
                          </Typography>
                          <br />
                          <Typography variant={'caption'}>
                            Sub-Sections:
                          </Typography>
                          <Typography
                            variant={'caption'}
                            className={classes.boldText}
                          >
                            {engineData.metrics.sub_sections.actual}/
                            {engineData.metrics.sub_sections.expected}
                          </Typography>
                        </div>
                      </Popover>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              {profileList.length ? (
                <div style={{ width: '100%' }}>
                  {profileList.map((profileData, index) => {
                    return (
                      <CustomAccordion
                        key={index}
                        square
                        expanded={expandedProfile === profileData.awi_label}
                        onChange={this.handleProfilePanelChange(
                          profileData.awi_label
                        )}
                      >
                        <CustomAccordionSummary
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <Grid
                            container
                            direction="row"
                            justify={'space-between'}
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant={'caption'} align="left">
                                {capitalizeFirstLetter(profileData.awi_label)}
                              </Typography>
                            </Grid>
                            {!profileData.completed ? (
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  direction: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  component={Link}
                                  to={RoutePath.dataCollectionProfileUploadPath(
                                    engineID,
                                    profileData.awi_label
                                  )}
                                >
                                  Record
                                </Button>
                                <ExpandMoreOutlinedIcon />
                              </Grid>
                            ) : (
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  direction: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    direction: 'column',
                                    alignItems: 'center',
                                    color:
                                      // profileData.completed &&
                                      // profileData.completed === 'fully'
                                      false ? 'green' : 'orange',
                                  }}
                                >
                                  <CheckCircleSharpIcon />
                                </div>

                                <ExpandMoreOutlinedIcon />
                              </Grid>
                            )}
                          </Grid>
                          {/* <Typography>Collapsible Group Item #2</Typography> */}
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          {profileData.list_of_sections.length ? (
                            <div style={{ width: '100%' }}>
                              {profileData.list_of_sections.map(
                                (sectionData, index) => {
                                  return (
                                    <CustomAccordion
                                      key={index}
                                      square
                                      expanded={
                                        expandedSection ===
                                        sectionData.awi_label
                                      }
                                      onChange={this.handleSectionPanelChange(
                                        sectionData.awi_label
                                      )}
                                      style={{ width: '100%' }}
                                    >
                                      <CustomAccordionSummary
                                        aria-controls="panel2d-content"
                                        id="panel2d-header"
                                        style={{ width: '100%' }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          justify={'space-between'}
                                        >
                                          <Grid item>
                                            <Typography
                                              variant={'caption'}
                                              align="left"
                                            >
                                              {capitalizeFirstLetter(
                                                sectionData.awi_label
                                              )}
                                            </Typography>
                                          </Grid>
                                          {/* <div className={classes.grow} /> */}
                                          {!sectionData.completed ? (
                                            profileData.completed ? (
                                              <Grid item>
                                                <Button
                                                  color="primary"
                                                  variant="contained"
                                                  component={Link}
                                                  to={RoutePath.dataCollectionSectionPath(
                                                    engineID,
                                                    profileData.awi_label,
                                                    sectionData.awi_label
                                                  )}
                                                >
                                                  Record
                                                </Button>
                                                <ExpandMoreOutlinedIcon />
                                              </Grid>
                                            ) : (
                                              <Grid
                                                item
                                                style={{
                                                  display: 'flex',
                                                  direction: 'column',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                {/* <CheckCircleSharpIcon /> */}
                                                &nbsp;
                                                <Typography>
                                                  PROFILE PENDING
                                                </Typography>
                                                <ExpandMoreOutlinedIcon />
                                              </Grid>
                                            )
                                          ) : (
                                            <Grid
                                              item
                                              style={{
                                                display: 'flex',
                                                direction: 'column',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  direction: 'column',
                                                  alignItems: 'center',
                                                  color:
                                                    // sectionData.completed &&
                                                    // sectionData.completed === 'fully'
                                                    false ? 'green' : 'orange',
                                                }}
                                              >
                                                <CheckCircleSharpIcon />
                                              </div>

                                              <ExpandMoreOutlinedIcon />
                                            </Grid>
                                          )}
                                        </Grid>
                                        {/* <Typography>Collapsible Group Item #2</Typography> */}
                                      </CustomAccordionSummary>
                                      <CustomAccordionDetails>
                                        {sectionData.list_of_sub_sections
                                          .length ? (
                                          <div style={{ width: '100%' }}>
                                            {sectionData.list_of_sub_sections.map(
                                              (subSectionData, index) => {
                                                return (
                                                  <CustomAccordion
                                                    key={index}
                                                    square
                                                    // expanded={
                                                    //   expandedSubSection ===
                                                    //   subSectionData.awi_label
                                                    // }
                                                    // onChange={this.handleSubSectionPanelChange(
                                                    //   subSectionData.awi_label
                                                    // )}
                                                  >
                                                    <CustomAccordionSummary
                                                      aria-controls="panel2d-content"
                                                      id="panel2d-header"
                                                    >
                                                      {/* <div> */}
                                                      <Grid
                                                        container
                                                        direction="row"
                                                        justify={
                                                          'space-between'
                                                        }
                                                      >
                                                        <Grid item>
                                                          <Typography
                                                            variant={'caption'}
                                                            align="left"
                                                          >
                                                            {capitalizeFirstLetter(
                                                              subSectionData.awi_label
                                                            )}
                                                          </Typography>
                                                        </Grid>
                                                        {/* <div className={classes.grow} /> */}

                                                        {!subSectionData.completed ? (
                                                          sectionData.completed ? (
                                                            <Grid item>
                                                              <Button
                                                                color="primary"
                                                                variant="contained"
                                                                component={Link}
                                                                to={RoutePath.dataCollectionSubSectionPath(
                                                                  engineID,
                                                                  profileData.awi_label,
                                                                  sectionData.awi_label,
                                                                  subSectionData.awi_label
                                                                )}
                                                              >
                                                                Record
                                                              </Button>
                                                            </Grid>
                                                          ) : (
                                                            <Grid
                                                              item
                                                              style={{
                                                                display: 'flex',
                                                                direction:
                                                                  'column',
                                                                alignItems:
                                                                  'center',
                                                              }}
                                                            >
                                                              {/* <CheckCircleSharpIcon /> */}
                                                              &nbsp;
                                                              <Typography>
                                                                SECTION PENDING
                                                              </Typography>
                                                            </Grid>
                                                          )
                                                        ) : (
                                                          <Grid
                                                            item
                                                            style={{
                                                              display: 'flex',
                                                              direction:
                                                                'column',
                                                              alignItems:
                                                                'center',
                                                              color: false //when other greens will be implemented turn this to green
                                                                ? 'green'
                                                                : 'orange',
                                                            }}
                                                          >
                                                            <CheckCircleSharpIcon />
                                                          </Grid>
                                                        )}
                                                      </Grid>
                                                      {/* </div> */}
                                                      {/* <Typography>Collapsible Group Item #2</Typography> */}
                                                    </CustomAccordionSummary>
                                                    {/* <CustomAccordionDetails></CustomAccordionDetails> */}
                                                  </CustomAccordion>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          <Typography
                                            variant="h6"
                                            align="center"
                                          >
                                            No section data available. Please
                                            Contact your Manager
                                          </Typography>
                                        )}
                                      </CustomAccordionDetails>
                                    </CustomAccordion>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <Typography variant="h6" align="center">
                              No section data available. Please Contact your
                              Manager
                            </Typography>
                          )}
                        </CustomAccordionDetails>
                      </CustomAccordion>
                    );
                  })}
                </div>
              ) : (
                <Typography variant="h6" align="center">
                  No profile data available. Please Contact your Manager
                </Typography>
              )}
            </div>
          ) : (
            <Typography align="center" variant="h4">
              Loading Portions... <CircularProgress />
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    expandedProfile: state.PortionSelectionReducer.expandedProfile,
    expandedSection: state.PortionSelectionReducer.expandedSection,
    expandedSubSection: state.PortionSelectionReducer.expandedSubSection,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    setExpandedProfile: (data) =>
      dispatch(PortionSelectionActions.setExpandedProfile(data)),
    setExpandedSection: (data) =>
      dispatch(PortionSelectionActions.setExpandedSection(data)),
    setExpandedSubSection: (data) =>
      dispatch(PortionSelectionActions.setExpandedSubSection(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PortionSelectionPage));
