let ProfileListStyles = (theme) => ({
  root: {},
  profileSummaryContainer: {
    cursor: 'pointer',
  },
  outlineImage: {
    width: '100%',
    maxWidth: 150,
  },
  referenceImage: {
    width: '100%',
    maxWidth: 150,
  },
});

export default ProfileListStyles;
