import {
  Badge,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import React, { Component, Fragment } from 'react';
import { isIOS, isMobile } from 'mobile-device-detect';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import EngineDetailsProfileListStyles from './EngineDetailsProfileListStyles';
import EngineDetailsSectionList from './EngineDetailsSectionList/EngineDetailsSectionList';
import EngineService from '../../../services/EngineService';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import FlipCameraIosTwoToneIcon from '@material-ui/icons/FlipCameraIosTwoTone';
import ReviewConfirmationModal from '../ReviewConfirmationPage/ReviewConfirmationModal';
import RolesEnum from '../../../models/RolesEnum';
import RoutePath from '../../../lib/RoutePath';
import SmallCommentList from '../SmallCommentList/SmallCommentList';
import VideoPlayerActions from '../../../redux/actions/VideoPlayerActions';
import VideoPlayerContainer from '../../CommonComponents/VideoPlayerContainer/VideoPlayerContainer';
import { capitalizeFirstLetter } from '../../../util/CommonUtils';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

// import SectionList from './SectionList/SectionList';

let styles = EngineDetailsProfileListStyles;

class EngineDetailsProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList: [],
      commentsOpen: false,
      expandedProfile: undefined,
      reviewConfirmationModalOpenFlag: false,
      reviewConfirmationMode: undefined,
      reviewPortionData: undefined,

      playerVisible: false,
      autoPlay: false,
      controlsVisible: false,
      loopVideo: false,
      videoPlayerSelectedPortion: undefined,
    };
  }

  componentDidMount = () => {
    // "?q=12&pp=gg"
    console.log(this.props.location.search);
    let params = new URLSearchParams(this.props.location.search);
    let expandedProfile = params.get('expandedProfile');
    let expandedSection = params.get('expandedSection');
    let openComments = params.get('openComments');
    this.setState({
      expandedProfile,
      commentsOpen: !!openComments && !!expandedProfile && !expandedSection,
    });
  };

  handleProfileExpanded = (profileData) => (event) => {
    event.stopPropagation();
    let { expandedProfile } = this.state;
    this.setState({
      expandedProfile:
        expandedProfile === profileData.awi_label
          ? false
          : profileData.awi_label,
    });
  };

  handleModifyProfile = (e) => {
    e.stopPropagation();
    console.log('handleModifyProfile');
  };

  handleDeleteProfile = async (e) => {
    e.stopPropagation();
    console.log('handleDeleteProfile');
  };

  handleOpenReviewConfirmation = (portionData, reviewConfirmationMode) => {
    this.setState({
      reviewConfirmationMode,
      reviewPortionData: portionData,
      reviewConfirmationModalOpenFlag: true,
    });
  };

  handleCloseReviewConfirmation = (portionData, reviewConfirmationMode) => {
    this.setState({
      reviewConfirmationMode: undefined,
      reviewPortionData: portionData,
      reviewConfirmationModalOpenFlag: false,
    });
  };

  handleApprovePortion = (portionData) => async (e) => {
    e.stopPropagation();
    // console.log(portionData, portionType);
    this.handleOpenReviewConfirmation(portionData, 'approve');
  };

  handleRejectPortion = (portionData) => async (e) => {
    e.stopPropagation();
    // console.log(portionData, portionType);
    this.handleOpenReviewConfirmation(portionData, 'reject');
  };

  handleProfileCommentsToggle = (panel) => (event) => {
    event.stopPropagation();
    let { commentsOpen, expandedProfile } = this.state;
    this.setState({
      commentsOpen: expandedProfile === panel ? !commentsOpen : true,
      expandedProfile: panel,
      sectionCommentsOpen: false,
      subSectionCommentsOpen: false,
    });
  };

  handleVideoPlayerOpen = (selectedPortion) => (event) => {
    event.stopPropagation();
    let { engineData, openVideoPlayer } = this.props;
    // RoutePath.navigateTothis.props, RoutePath.engineDetails(engineData.id));
    this.setState({
      playerVisible: true,
      videoPlayerSelectedPortion: selectedPortion,
      autoPlay: true,
      controlsVisible: true,
      loopVideo: true,
      type: engineData.type,
    });
  };

  handleVideoPlayerClose = (event) => {
    event.stopPropagation();
    let { engineData, openVideoPlayer } = this.props;
    // RoutePath.navigateTothis.props, RoutePath.engineDetails(engineData.id));
    this.setState({
      playerVisible: false,
      videoPlayerSelectedPortion: undefined,
      autoPlay: false,
      controlsVisible: false,
      loopVideo: false,
      type: 'video',
    });
  };

  getRelatedStatusIcon = (approvalStatus) => {
    if (approvalStatus === 'pending') {
      return <ErrorTwoToneIcon fontSize="small" />;
    } else if (approvalStatus === 'approved') {
      return <CheckCircleTwoToneIcon fontSize="small" />;
    } else if (approvalStatus === 'rejected') {
      return <CancelTwoToneIcon fontSize="small" />;
    }
  };

  render() {
    let { profileList, classes, loggedUser, engineData } = this.props;
    let { engineID } = this.props.match.params;
    let {
      expandedProfile,
      commentsOpen,
      reviewConfirmationModalOpenFlag,
      reviewPortionData,
      reviewConfirmationMode,

      playerVisible,
      videoPlayerSelectedPortion,
      autoPlay,
      controlsVisible,
      loopVideo,
      type,
    } = this.state;

    console.log('engineData', engineData);
    return (
      <div className={classes.root}>
        {profileList.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Profile</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileList.map((profileData, index) => {
                  // console.log(profileData.awi_image_url);
                  return (
                    <Fragment key={profileData.id}>
                      <TableRow
                        hover
                        className={classes.profileSummaryContainer}
                        onClick={this.handleProfileExpanded(profileData)}
                        aria-label="profile-summary"
                      >
                        <TableCell
                          align="center"
                          className={classes.imageContainer}
                        >
                          <Badge
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            classes={{
                              badge: classes[profileData.approved_status],
                            }}
                            color="primary"
                            badgeContent={
                              <div
                                // key={profileData.id}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {this.getRelatedStatusIcon(
                                  profileData.approved_status
                                )}
                                &nbsp;
                                {capitalizeFirstLetter(
                                  profileData.approved_status
                                )}
                              </div>
                            }
                          >
                            {engineData.type === 'video' ? (
                              // !isIOS ? (
                              true ? (
                                <video
                                  muted
                                  playsInline
                                  autoPlay={isIOS}
                                  // src={
                                  //   'https://fchat.s3.ap-south-1.amazonaws.com/default/NX6DE2Io6O_1617198496014.mov'
                                  // }
                                  src={profileData.awi_image_url}
                                  alt={profileData.awi_content_id}
                                  className={classes.profileMedia}
                                  onClick={this.handleVideoPlayerOpen(
                                    profileData
                                  )}
                                />
                              ) : (
                                <Typography
                                  className={
                                    classes.unableToOpenVideoOnIOSError
                                  }
                                >
                                  Video playback not supported on IOS. Please
                                  open this page on a different platform.
                                </Typography>
                              )
                            ) : (
                              <img
                                src={profileData.awi_image_url}
                                alt={profileData.awi_content_id}
                                className={classes.profileMedia}
                                onClick={this.handleVideoPlayerOpen(
                                  profileData
                                )}
                              />
                            )}
                          </Badge>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>{profileData.awi_label}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {moment(profileData.ts_creation).format(
                            isMobile ? 'l' : 'LL'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              container
                              direction="row"
                              justify="center"
                              spacing={1}
                            >
                              {loggedUser.userObj?.level ===
                              RolesEnum.TECHNICIAN ? (
                                <Grid item>
                                  {isMobile ? (
                                    <IconButton
                                      component={Link}
                                      // exact
                                      to={RoutePath.dataReCollectionProfileUploadPath(
                                        engineID,
                                        profileData.awi_label
                                      )}
                                      aria-label="view-comments"
                                      className={classes.mobileCommentsButton}
                                    >
                                      <FlipCameraIosTwoToneIcon
                                        fontSize={'inherit'}
                                      />
                                    </IconButton>
                                  ) : (
                                    <Button
                                      component={Link}
                                      // exact
                                      to={RoutePath.dataReCollectionProfileUploadPath(
                                        engineID,
                                        profileData.awi_label
                                      )}
                                      startIcon={
                                        <FlipCameraIosTwoToneIcon
                                          fontSize={'inherit'}
                                        />
                                      }
                                      variant="outlined"
                                      className={classes.mobileNavLink}
                                    >
                                      Retake
                                    </Button>
                                  )}
                                </Grid>
                              ) : (
                                <>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      className={classes.approveButton}
                                      startIcon={
                                        <CheckCircleOutlineIcon
                                          fontSize={'inherit'}
                                        />
                                      }
                                      onClick={this.handleApprovePortion(
                                        profileData,
                                        'profile'
                                      )}
                                      aria-label="approve-button"
                                    >
                                      Approve
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      className={classes.rejectButton}
                                      startIcon={
                                        <CancelOutlinedIcon
                                          fontSize={'inherit'}
                                        />
                                      }
                                      onClick={this.handleRejectPortion(
                                        profileData,
                                        'profile'
                                      )}
                                      aria-label="reject-button"
                                    >
                                      Reject
                                    </Button>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                            <Grid item>
                              {isMobile ? (
                                <IconButton
                                  onClick={this.handleProfileCommentsToggle(
                                    profileData.awi_label
                                  )}
                                  aria-label="view-comments"
                                  className={classes.mobileCommentsButton}
                                >
                                  <ChatBubbleIcon />
                                </IconButton>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color={commentsOpen ? 'primary' : 'default'}
                                  className={classes.commentsButton}
                                  startIcon={
                                    <ChatBubbleIcon fontSize={'inherit'} />
                                  }
                                  onClick={this.handleProfileCommentsToggle(
                                    profileData.awi_label
                                  )}
                                  aria-label="view-comments"
                                >
                                  Comments
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell className={classes.expandIconContainer}>
                          <ExpandMoreOutlinedIcon />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={4}>
                          <Collapse
                            in={expandedProfile === profileData.awi_label}
                            timeout="auto"
                            unmountOnExit
                          >
                            {commentsOpen ? (
                              <SmallCommentList
                                open={commentsOpen}
                                portionData={{
                                  ...profileData,
                                }}
                              />
                            ) : (
                              <EngineDetailsSectionList
                                engineData={engineData}
                                profileData={profileData}
                                sectionList={profileData.list_of_sections}
                                handleGetEngineStatusInHeirarchy={
                                  this.props.handleGetEngineStatusInHeirarchy
                                }
                              />
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">No Profiles uploaded.</Typography>
        )}

        <ReviewConfirmationModal
          open={reviewConfirmationModalOpenFlag}
          onClose={this.handleCloseReviewConfirmation}
          reviewPortionData={reviewPortionData}
          reviewConfirmationMode={reviewConfirmationMode}
          handleGetEngineStatusInHeirarchy={
            this.props.handleGetEngineStatusInHeirarchy
          }
        />

        <VideoPlayerContainer
          open={playerVisible}
          onClose={this.handleVideoPlayerClose}
          portionDetails={videoPlayerSelectedPortion}
          autoPlay={autoPlay}
          controlsVisible={controlsVisible}
          loopVideo={loopVideo}
          type={type}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedUser: state.UserReducer.loggedUser,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    openVideoPlayer: (videoData) =>
      dispatch(VideoPlayerActions.openVideoPlayer(videoData)),
    closeVideoPlayer: () => dispatch(VideoPlayerActions.closeVideoPlayer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(EngineDetailsProfileList)));
