import {
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { Component, PureComponent } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import VideoPlayerContainerStyles from './VideoPlayerContainerStyles';
import clsx from 'clsx';
import { isIOS } from 'mobile-device-detect';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = VideoPlayerContainerStyles;

class VideoPlayerContainer extends Component {
  static defaultProps = {
    playerVisible: false,
    playerURL: '',
    autoPlay: false,
    controlsVisible: false,
    loopVideo: false,
    type: 'video',
    portionDetails: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      outlineImageDisplayedFlag: false,
    };
  }

  handleOutlineImageDisplayedFlagChange = (e) => {
    let { outlineImageDisplayedFlag } = this.state;
    this.setState({
      outlineImageDisplayedFlag: !outlineImageDisplayedFlag,
    });
  };

  render() {
    let {
      classes,
      open,
      onClose,
      // playerVisible,
      controlsVisible,
      loopVideo,
      autoPlay,
      type,
      portionDetails,
    } = this.props;

    let { outlineImageDisplayedFlag } = this.state;

    let playerURL = portionDetails?.awi_image_url;

    console.log('portionDetails', portionDetails);
    return (
      <div className={classes.root}>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.modalContainer}>
            <Paper className={classes.modalPaper}>
              <div className={classes.closeButtonContainer}>
                <IconButton onClick={onClose} className={classes.closeButton}>
                  <CancelIcon />
                </IconButton>
              </div>

              <FormControlLabel
                control={
                  <Switch
                    checked={outlineImageDisplayedFlag}
                    onChange={this.handleOutlineImageDisplayedFlagChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Toggle Outline Image"
              />

              {playerURL ? (
                <div className={classes.videoContainer}>
                  {type === 'video' ? (
                    // !isIOS ? (
                    true ? (
                      <video
                        playsInline
                        src={playerURL}
                        // src={
                        //   'https://fchat.s3.ap-south-1.amazonaws.com/default/NX6DE2Io6O_1617198496014.mov'
                        // }
                        className={clsx(classes.video, 'scrollbar_primary')}
                        loop={loopVideo}
                        controls={controlsVisible}
                        autoPlay={autoPlay}
                        onLoadedData={(e) => {
                          e.target.play();
                          e.target.volume = 0;
                        }}
                      />
                    ) : (
                      <Typography
                        className={classes.unableToOpenVideoOnIOSError}
                      >
                        Video playback not supported on IOS. Please open this
                        page on a different platform.
                      </Typography>
                    )
                  ) : (
                    <img
                      src={playerURL}
                      alt={'selected engine'}
                      className={clsx(classes.video, 'scrollbar_primary')}
                      // loop={loopVideo}
                      // controls={controlsVisible}
                      // autoPlay={autoPlay}
                      // onLoadedData={(e) => {
                      //   e.target.play();
                      //   e.target.volume = 0;
                      // }}
                    />
                  )}

                  {outlineImageDisplayedFlag ? (
                    <img
                      src={portionDetails.awi_overlay_image_url}
                      alt={'Unable To Load Outline'}
                      className={classes.outlineImage}
                    />
                  ) : null}
                </div>
              ) : (
                <Typography variant={'h6'} align="center">
                  Video URL not found! Please reopen the video
                </Typography>
              )}
            </Paper>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(VideoPlayerContainer);
