let LandingPageStyles = (theme) => ({
  root: {
     maxWidth:900,
     margin:theme.spacing(8,'auto'),
  },
  container: {
    width: '100%',
    padding:theme.spacing(1,1)
  },
});

export default LandingPageStyles;
