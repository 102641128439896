class ReduxActionEnum {
  //General
  static LOGIN_ACTION = 'login';
  static LOGOUT_ACTION = 'logout';

  //Settings
  static SET_DARK_MODE = 'setDarkMode';

  //Video Player
  static OPEN_PLAYER = 'openVideoPlayer';
  static CLOSE_PLAYER = 'closeVideoPlayer';

  //Portion Selection
  static SET_EXPANDED_PROFILE = 'setExpandedProfile';
  static SET_EXPANDED_SECTION = 'setExpandedSection';
  static SET_EXPANDED_SUB_SECTION = 'setExpandedSubSection';
}

export default ReduxActionEnum;
