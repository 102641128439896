import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  capitalizeFirstLetter,
  convertToTitleCase,
  convertUnderscoreCaseToTitleCase,
} from '../../../util/CommonUtils';

import CancelIcon from '@material-ui/icons/Cancel';
import { DateTimePicker } from '@material-ui/pickers';
import EngineService from '../../../services/EngineService';
import InputLabel from '@material-ui/core/InputLabel';
import NotificationPageFilterModalStyles from './NotificationPageFilterModalStyles';
import Paper from '@material-ui/core/Paper';
import RolesEnum from '../../../models/RolesEnum';
import Select from '@material-ui/core/Select';
import TypeEnum from '../../../models/TypeEnum';
import UserActions from '../../../redux/actions/UserActions';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = NotificationPageFilterModalStyles;

class NotificationPageFilterModal extends Component {
  static defaultProps = {
    initialStartDate: new Date(),
    initialEndDate: new Date(),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      listOfUsers: [],
    };
  }

  componentDidMount = () => {
    this.setInitialDates();
    this.handleGetListOfEmployees();
  };

  handleGetListOfEmployees = async () => {
    // let { initialStartDate, initialEndDate } = this.props;
    let data = {};
    let response = await EngineService.listOfUsers(data);
    if (response.success) {
      this.setState({
        listOfUsers: response.data,
      });
    }
  };

  setInitialDates = () => {
    let { initialStartDate, initialEndDate } = this.props;
    this.setState({
      selectedStartDate: initialStartDate,
      selectedEndDate: initialEndDate,
    });
  };

  handleSetSearchString = (e) => {
    this.props.setSearchString(e.target.value);
  };

  handleSelectedTypeChange = (e) => {
    this.props.setSelectedType(e.target.value);
  };

  handleSelectedLevelListChange = (e) => {
    this.props.setSelectedLevelList(e.target.value);
  };

  handleSelectedTechniciansChange = (e) => {
    this.props.setSelectedTechnicianList(e.target.value);
  };

  handleStartDateChange = (selectedStartDate) => {
    // console.log(
    //   'selectedStartDate',
    //   selectedStartDate,
    //   new Date(selectedStartDate)
    // );
    this.props.setSelectedStartDate(selectedStartDate);
  };

  handleEndDateChange = (selectedEndDate) => {
    this.props.setSelectedEndDate(selectedEndDate);
  };

  render() {
    let {
      classes,
      open,
      onClose,
      loggedUser,

      searchString,
      selectedTypeList,
      selectedLevelList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.props;
    let { listOfUsers } = this.state;
    // console.log('listOfUsers', listOfUsers);
    // console.log('selectedTypeList', selectedTypeList);
    console.log('selectedLevelList', selectedLevelList);
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalContainer}>
          <Paper className={classes.modalPaper}>
            <div className={classes.closeButtonContainer}>
              <IconButton onClick={onClose} className={classes.closeButton}>
                <CancelIcon />
              </IconButton>
            </div>

            <div className={classes.contentContainer}>
              <Typography variant={'h5'} className={classes.mainHeading}>
                Filters
              </Typography>

              <Grid container direction="column" spacing={3}>
                {/* <Grid item>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Search"
                    id="searchString"
                    name="searchString"
                    value={searchString}
                    onChange={this.handleSetSearchString}
                  />
                </Grid> */}

                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={6}>
                    <DateTimePicker
                      fullWidth
                      value={selectedStartDate}
                      inputVariant="outlined"
                      disableFuture
                      onChange={this.handleStartDateChange}
                      label="Start Date"
                      format={'lll'}
                      showTodayButton
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimePicker
                      fullWidth
                      minDate={selectedStartDate}
                      value={selectedEndDate}
                      inputVariant="outlined"
                      // disablePast
                      onChange={this.handleEndDateChange}
                      label="End Date"
                      format={'lll'}
                      showTodayButton
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="type-input-label">
                      Notification Type
                    </InputLabel>
                    <Select
                      labelId="type-input-label"
                      id="type-input"
                      multiple
                      value={selectedTypeList}
                      onChange={this.handleSelectedTypeChange}
                      label="Notification Type"
                      renderValue={(selected) => {
                        // console.log(selected);
                        let names = selected.map((selectedTypeOption) => {
                          return convertUnderscoreCaseToTitleCase(
                            selectedTypeOption
                          );
                        });
                        // let names = selected.map((selectedTypeOption) => {
                        //   return convertToTitleCase(
                        //     TypeEnum[selectedTypeOption]
                        //   );
                        // });
                        return names.join(', ');
                      }}
                      // input={<Input />}
                      // MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Engine Type</em>
                      </MenuItem>
                      {Object.keys(TypeEnum).map((typeName, index) => {
                        return (
                          <MenuItem key={typeName} value={TypeEnum[typeName]}>
                            <Checkbox
                              checked={
                                selectedTypeList.findIndex(
                                  (selectedType, index) => {
                                    return TypeEnum[typeName] === selectedType;
                                  }
                                ) > -1
                              }
                            />
                            <ListItemText
                              primary={`${convertUnderscoreCaseToTitleCase(
                                TypeEnum[typeName]
                              )}`}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                {loggedUser.userObj?.level !== RolesEnum.TECHNICIAN ? (
                  <Grid item>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="type-input-label">Senders</InputLabel>
                      <Select
                        labelId="type-input-label"
                        id="type-input"
                        multiple
                        value={selectedTechnicianList}
                        onChange={this.handleSelectedTechniciansChange}
                        label="Senders"
                        renderValue={(selected) => {
                          // console.log(selected);
                          let names = selected.map((selectedTechnicianData) => {
                            return listOfUsers[
                              listOfUsers.findIndex((technicianData) => {
                                // console.log('locationObj', locationObj);
                                // console.log('selectedLocationObj', selectedLocationObj);
                                return (
                                  technicianData?.email ===
                                  selectedTechnicianData?.email
                                );
                              })
                            ]?.email;
                          });
                          return names.join(', ');
                        }}
                        // input={<Input />}
                        // MenuProps={MenuProps}
                      >
                        <MenuItem value="" disabled>
                          <em>Select Technicians</em>
                        </MenuItem>
                        {listOfUsers.map((userDetails, index) => {
                          console.log(userDetails);
                          return (
                            <MenuItem key={userDetails.id} value={userDetails}>
                              <Checkbox
                                checked={
                                  selectedTechnicianList.findIndex(
                                    (selectedTechnicianData, index) => {
                                      return (
                                        userDetails?.email ===
                                        selectedTechnicianData?.email
                                      );
                                    }
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={`${capitalizeFirstLetter(
                                  userDetails?.awi_personal?.firstname
                                )} ${capitalizeFirstLetter(
                                  userDetails?.awi_personal?.lastname
                                )} - ${userDetails.email}`}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid item>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="type-input-label">Sender Level</InputLabel>
                    <Select
                      labelId="type-input-label"
                      id="type-input"
                      multiple
                      value={selectedLevelList}
                      onChange={this.handleSelectedLevelListChange}
                      label="Sender Level"
                      renderValue={(selected) => {
                        // console.log('selected', selected);
                        let names = selected.map((selectedRole) => {
                          return convertUnderscoreCaseToTitleCase(selectedRole);
                        });
                        return names.join(', ');
                      }}
                      // input={<Input />}
                      // MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Role</em>
                      </MenuItem>
                      {Object.keys(RolesEnum).map((roleName, index) => {
                        return (
                          <MenuItem
                            key={roleName}
                            value={roleName.toLowerCase()}
                          >
                            <Checkbox
                              checked={
                                selectedLevelList.findIndex(
                                  (selectedRole, index) => {
                                    return (
                                      roleName.toLowerCase() ===
                                      selectedRole.toLowerCase()
                                    );
                                  }
                                ) > -1
                              }
                            />
                            <ListItemText
                              primary={`${convertToTitleCase(roleName)}`}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUser: state.UserReducer.loggedUser,
    // isDarkMode: state.SettingsReducer.isDarkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logoutUser: () => dispatch(UserActions.logoutUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NotificationPageFilterModal));
