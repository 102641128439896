import StatusEnum from '../../models/StatusEnum';

let UploadOutlinesPageStyles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
    maxWidth: 900,
    margin: 'auto',
  },
  mobileHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1, 2),
    color: 'white',
    backgroundColor: '#204e79',
  },
  headingContainer: {
    width: '100%',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pageContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
  },
});

export default UploadOutlinesPageStyles;
