import PortionSelectionInitialState from '../states/PortionSelectionInitialState';
import ReduxActionEnum from '../../models/ReduxActionEnum';

const PortionSelectionReducer = (
  state = PortionSelectionInitialState,
  action
) => {
  switch (action.type) {
    case ReduxActionEnum.SET_EXPANDED_PROFILE:
      return {
        ...state,
        expandedProfile: action.payload,
      };
    case ReduxActionEnum.SET_EXPANDED_SECTION:
      return {
        ...state,
        expandedSection: action.payload,
      };
    case ReduxActionEnum.SET_EXPANDED_SUB_SECTION:
      return {
        ...state,
        expandedSubSection: action.payload,
      };

    default:
      return state;
  }
};

export default PortionSelectionReducer;
