import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfileListStyles from './ProfileListStyles';
import {
  Button,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EngineService from '../../../services/EngineService';
import { capitalizeFirstLetter } from '../../../util/CommonUtils';
import { Fragment } from 'react';
import SectionList from './SectionList/SectionList';

let styles = ProfileListStyles;

class ProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList: [],
    };
  }

  componentDidMount = () => {
    this.handleGetPredefinedData();
  };

  handleGetPredefinedData = async () => {
    let response = await EngineService.preFetchEntriesInHierarchy();
    console.log('handleGetPredefinedData', response);
    if (response.success) {
      this.setState({ profileList: response.data });
    }
  };

  handleProfileExpanded = (profileData) => (event) => {
    event.stopPropagation();
    let { expandedProfile } = this.state;
    this.setState({
      expandedProfile:
        expandedProfile === profileData.awi_label
          ? false
          : profileData.awi_label,
    });
  };

  handleModifyProfile = (e) => {
    e.stopPropagation();
    console.log('handleModifyProfile');
  };

  handleDeleteProfile = async (e) => {
    e.stopPropagation();
    console.log('handleDeleteProfile');
  };

  render() {
    let { classes } = this.props;
    let { profileList, expandedProfile } = this.state;
    return (
      <div className={classes.root}>
        {profileList.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Outline Image</TableCell>
                  <TableCell align="center">Reference Image</TableCell>
                  <TableCell align="center">Label</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileList.map((profileData, index) => (
                  <Fragment key={profileData.id}>
                    <TableRow
                      hover
                      className={classes.profileSummaryContainer}
                      onClick={this.handleProfileExpanded(profileData)}
                      aria-label="profile-summary"
                    >
                      <TableCell align="center">
                        <img
                          src={profileData.awi_overlay_image_url}
                          alt={profileData.awi_overlay_image_id}
                          className={classes.outlineImage}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={profileData.awi_reference_image_url}
                          alt={profileData.awi_reference_image_id}
                          className={classes.referenceImage}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {capitalizeFirstLetter(profileData.awi_label)}
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.handleModifyProfile}
                              aria-label="modify-profile"
                            >
                              Modify
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={this.handleDeleteProfile}
                              aria-label="delete-profile"
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.root}>
                      <TableCell colSpan={4}>
                        <Collapse
                          in={expandedProfile === profileData.awi_label}
                          timeout="auto"
                          unmountOnExit
                        >
                          <SectionList
                            sectionList={profileData.list_of_sections}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">No Profiles uploaded.</Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProfileList);
