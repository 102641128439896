import APIPath from '../lib/APIPath';
import APIServices from './serviceHelpers/APIServices';
import GenUtil from '../util/GenUtil';
import { getHost } from '../lib/Constants';

class EngineService {
  static async listOfUsers(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfUsers}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async listOfEngines(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfEngines}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  //returns all completed images
  static async getEngineStatus(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.getEngineStatus}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  //returns all completed images
  static async getEngineStatusInHierarchy(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.getEngineStatusInHierarchy}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  static async listOfProfiles(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfProfiles}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  static async listOfSections(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfSections}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  static async listOfSubSections(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfSubSections}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  static async preFetchEntries(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.preFetchEntries}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  static async preFetchEntriesInHierarchy(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.preFetchEntriesInHierarchy}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  static async listOfStaticDefinedData(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfStaticDefinedData}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async removeEngines(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.removeEngines}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async cancelEngines(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.cancelEngines}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async updateCommentStatus(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.updateCommentStatus}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async fetchComments(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.fetchComments}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async fetchAllRecievedComments(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.fetchAllRecievedComments}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async addNewComment(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.addNewComment}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async approvePortions(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.approvePortions}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async rejectPortions(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.rejectPortions}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async removePredefinedData(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.removePredefinedData}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async addPredefinedData(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.addPredefinedData}`,
      method: 'POST',
      crossDomain: true,
      headers: await GenUtil.getFormHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  
  static async addNewEngine(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.addNewEngine}`,
      method: 'POST',
      crossDomain: true,
      headers: await GenUtil.getFormHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
  
  static async assignEngineToTechnician(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.assignEngineToTechnician}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async uploadBulkImages(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.uploadBulkImages}`,
      method: 'POST',
      crossDomain: true,
      headers: await GenUtil.getFormHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async addNewProfile(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.addNewProfile}`,
      method: 'POST',
      crossDomain: true,
      headers: await GenUtil.getFormHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async addNewSection(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.addNewSection}`,
      method: 'POST',
      crossDomain: true,
      headers: await GenUtil.getFormHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async addNewSubSection(data = {}) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.addNewSubSection}`,
      method: 'POST',
      crossDomain: true,
      headers: await GenUtil.getFormHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    // console.log(axiosConfig)
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }
}

export default EngineService;
