import StatusEnum from '../../models/StatusEnum';

let EngineDetailsStyles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
    maxWidth: 900,
    margin: 'auto',
  },
  mobileHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1, 2),
    color: 'white',
    backgroundColor: '#204e79',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingContainer: {
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  pageContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(12),
  },
  boldText: {
    fontWeight: 'bold',
  },
  completionInfoModalContainer: {
    padding: theme.spacing(1),
  },
  profileDataSummaryRowContainer: {
    marginTop: 20,
  },
  profileMediaIcon: {
    // width: '100%',
    maxHeight: 82,
  },
  headerCell: {
    whiteSpace: 'nowrap',
  },
  approveButton: {
    color: theme.palette.success.main,
    marginRight: 5,
    // backgroundColor: theme.palette.success.main,
    // fontSize: '1.5rem',
  },
  rejectButton: {
    color: theme.palette.error.main,
    // backgroundColor: theme.palette.error.main,
    // fontSize: '1.5rem',
  },
  commentsButton: {
    marginTop: 5,
  },
  sectionRow: {
    cursor: 'pointer',
  },
  sectionMediaIcon: {
    width: '100%',
    maxWidth: 160,
    maxHeight: 90,
  },
  [StatusEnum.APPROVED]: {
    backgroundColor: theme.palette.success.main,
  },
  [StatusEnum.PENDING]: {
    backgroundColor: theme.palette.warning.main,
  },
  [StatusEnum.REJECTED]: {
    backgroundColor: theme.palette.error.main,
  },
});

export default EngineDetailsStyles;
