import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  capitalizeFirstLetter,
  convertToTitleCase,
} from '../../../util/CommonUtils';

import CancelIcon from '@material-ui/icons/Cancel';
import { DateTimePicker } from '@material-ui/pickers';
import EngineService from '../../../services/EngineService';
import FilterModalStyles from './FilterModalStyles';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import RolesEnum from '../../../models/RolesEnum';
import Select from '@material-ui/core/Select';
import StatusEnum from '../../../models/StatusEnum';
import UserActions from '../../../redux/actions/UserActions';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = FilterModalStyles;

class FilterModal extends Component {
  static defaultProps = {
    initialStartDate: new Date(),
    initialEndDate: new Date(),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      listOfUsers: [],
    };
  }

  componentDidMount = () => {
    this.setInitialDates();
    this.handleGetListOfEmployees();
  };

  handleGetListOfEmployees = async () => {
    // let { initialStartDate, initialEndDate } = this.props;
    let data = {};
    let response = await EngineService.listOfUsers(data);
    if (response.success) {
      this.setState({
        listOfUsers: response.data,
      });
    }
  };

  setInitialDates = () => {
    let { initialStartDate, initialEndDate } = this.props;
    this.setState({
      selectedStartDate: initialStartDate,
      selectedEndDate: initialEndDate,
    });
  };

  handleSetSearchString = (e) => {
    this.props.setSearchString(e.target.value);
  };

  handleSelectedStatusChange = (e) => {
    this.props.setSelectedStatus(e.target.value);
  };

  handleSelectedTechniciansChange = (e) => {
    this.props.setSelectedTechnicianList(e.target.value);
  };

  handleStartDateChange = (selectedStartDate) => {
    this.props.setSelectedStartDate(selectedStartDate);
  };

  handleEndDateChange = (selectedEndDate) => {
    this.props.setSelectedEndDate(selectedEndDate);
  };

  render() {
    let {
      classes,
      open,
      onClose,
      loggedUser,

      searchString,
      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.props;
    let { listOfUsers } = this.state;
    console.log('listOfUsers', listOfUsers);
    console.log('selectedStatusList', selectedStatusList);
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalContainer}>
          <Paper className={classes.modalPaper}>
            <div className={classes.closeButtonContainer}>
              <IconButton onClick={onClose} className={classes.closeButton}>
                <CancelIcon />
              </IconButton>
            </div>

            <div className={classes.contentContainer}>
              <Typography variant={'h5'} className={classes.mainHeading}>
                Filters
              </Typography>

              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Search"
                    id="searchString"
                    name="searchString"
                    value={searchString}
                    onChange={this.handleSetSearchString}
                  />
                </Grid>

                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={6}>
                    <DateTimePicker
                      fullWidth
                      value={selectedStartDate}
                      inputVariant="outlined"
                      disableFuture
                      onChange={this.handleStartDateChange}
                      label="Start Date"
                      format={'lll'}
                      showTodayButton
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimePicker
                      fullWidth
                      minDate={selectedStartDate}
                      value={selectedEndDate}
                      inputVariant="outlined"
                      // disablePast
                      onChange={this.handleEndDateChange}
                      label="End Date"
                      format={'lll'}
                      showTodayButton
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="status-input-label">
                      Engine Status
                    </InputLabel>
                    <Select
                      labelId="status-input-label"
                      id="status-input"
                      multiple
                      value={selectedStatusList}
                      onChange={this.handleSelectedStatusChange}
                      label="Engine Status"
                      renderValue={(selected) => {
                        // console.log(selected);
                        let names = selected.map((selectedStatusOption) => {
                          return convertToTitleCase(selectedStatusOption);
                        });
                        // let names = selected.map((selectedStatusOption) => {
                        //   return convertToTitleCase(
                        //     StatusEnum[selectedStatusOption]
                        //   );
                        // });
                        return names.join(', ');
                      }}
                      // input={<Input />}
                      // MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Engine Status</em>
                      </MenuItem>
                      {Object.keys(StatusEnum).map((statusName, index) => {
                        return (
                          <MenuItem
                            key={statusName}
                            value={StatusEnum[statusName]}
                          >
                            <Checkbox
                              checked={
                                selectedStatusList.findIndex(
                                  (selectedStatus, index) => {
                                    return (
                                      StatusEnum[statusName] === selectedStatus
                                    );
                                  }
                                ) > -1
                              }
                            />
                            <ListItemText
                              primary={`${convertToTitleCase(
                                StatusEnum[statusName]
                              )}`}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                {loggedUser.userObj?.level !== RolesEnum.TECHNICIAN ? (
                  <Grid item>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="status-input-label">
                        Technicians
                      </InputLabel>
                      <Select
                        labelId="status-input-label"
                        id="status-input"
                        multiple
                        value={selectedTechnicianList}
                        onChange={this.handleSelectedTechniciansChange}
                        label="Technicians"
                        renderValue={(selected) => {
                          // console.log(selected);
                          let names = selected.map((selectedTechnicianData) => {
                            return listOfUsers[
                              listOfUsers.findIndex((technicianData) => {
                                // console.log('locationObj', locationObj);
                                // console.log('selectedLocationObj', selectedLocationObj);
                                return (
                                  technicianData?.email ===
                                  selectedTechnicianData?.email
                                );
                              })
                            ]?.email;
                          });
                          return names.join(', ');
                        }}
                        // input={<Input />}
                        // MenuProps={MenuProps}
                      >
                        <MenuItem value="" disabled>
                          <em>Select Technicians</em>
                        </MenuItem>
                        {listOfUsers.map((userDetails, index) => {
                          console.log(userDetails);
                          return (
                            <MenuItem key={userDetails.id} value={userDetails}>
                              <Checkbox
                                checked={
                                  selectedTechnicianList.findIndex(
                                    (selectedTechnicianData, index) => {
                                      return (
                                        userDetails?.email ===
                                        selectedTechnicianData?.email
                                      );
                                    }
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={`${capitalizeFirstLetter(
                                  userDetails?.awi_personal?.firstname
                                )} ${capitalizeFirstLetter(
                                  userDetails?.awi_personal?.lastname
                                )} - ${userDetails.email}`}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </Paper>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUser: state.UserReducer.loggedUser,
    // isDarkMode: state.SettingsReducer.isDarkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logoutUser: () => dispatch(UserActions.logoutUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterModal));
