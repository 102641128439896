import StatusEnum from "../../../../models/StatusEnum";

let EngineDetailsSectionListStyles = (theme) => ({
  root: {},
  sectionSummaryContainer: {
    cursor: 'pointer',
    paddingBottom: theme.spacing(2),
    transform: 'scale(1)',
    height: theme.spacing(20),
  },
  sectionMedia: {
    height: '100%',
    maxWidth: '16vw',
    maxHeight: '09vw',
    '@media(max-width:600px)': {
      maxWidth: '32vw',
      maxHeight: '18vw',
    },
  },
  unableToOpenVideoOnIOSError: {
    border: '1px solid  black',
    padding: theme.spacing(.5,1),
    width: '16vw',
    // height: '09vw',
    '@media(max-width:600px)': {
      width: '32vw',
      // height: '18vw',
    },
  },
  [StatusEnum.APPROVED]: {
    backgroundColor: theme.palette.success.main,
  },
  [StatusEnum.PENDING]: {
    backgroundColor: theme.palette.warning.main,
  },
  [StatusEnum.REJECTED]: {
    backgroundColor: theme.palette.error.main,
  },
  approveButton: {
    color: theme.palette.success.main,
    marginRight: 5,
    // backgroundColor: theme.palette.success.main,
    // fontSize: '1.5rem',
  },
  rejectButton: {
    color: theme.palette.error.main,
    // backgroundColor: theme.palette.error.main,
    // fontSize: '1.5rem',
  },
  expandIconContainer: {
    position: 'absolute',
    bottom: -2,
    left: '50%',
    transform: 'translate(-50%,0)',
  },
});

export default EngineDetailsSectionListStyles;
