let NotificationsPageStyles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
    maxWidth: 1000,
    margin: 'auto',
  },
  mobileHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1, 2),
    color: 'white',
    backgroundColor: '#204e79',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingContainer: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100vw',
    width: '100%',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    //   color: 'white',
    //   backgroundColor: '#204e79',
  },
  pageContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(12),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(9),
    right: theme.spacing(2),
  },
});

export default NotificationsPageStyles;
