import { Button, Divider, Grid } from '@material-ui/core';
import {
  Canvas,
  Container,
  Flash,
  Overlay,
  Video,
  Wrapper,
} from './styled-comps';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import EventEmitter from '../../../../../util/EventEmitter';
import { Globals } from '../../../../../lib/Constants';
import Measure from 'react-measure';
import MediaTrackConstraints from '../MediaTrackConstraints';
import { ReactMediaRecorder } from 'react-media-recorder';
import RecordView from '../RecordView/RecordView.js';
import VideoPreviewStyles from './VideoPreviewStyles';
import clsx from 'clsx';
import { toast } from 'material-react-toastify';
import useCardRatio from './hooks/useCardRatio';
import useOffsets from './hooks/useOffsets';
import { useUserMedia } from './hooks/useProducts';
import { withStyles } from '@material-ui/core/styles';

let styles = VideoPreviewStyles;

const CAPTURE_OPTIONS = {
  audio: false,
  video: MediaTrackConstraints,
};

const VideoPreview = (props) => {
  let {
    style,
    stream,
    recordViewProps,
    width,
    height,
    classes,
    mediaBlobUrl,
    handleMediaBlobURLChanged,
  } = props;

  const videoRef = useRef();
  const videoPlayer = useRef();
  const canvasRef = useRef();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  const [videoPlayingFlag, setVideoPlayingFlag] = useState(true);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const mediaStreamOptions = {
    // audioBitsPerSecond: 0,
    // videoBitsPerSecond: 2500000,
    // mimeType: 'video/mp4',
  };

  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  let setVideoURL = () => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
    }
  };

  setVideoURL();

  function handleResize(contentRect) {
    setContainer({
      height: Math.round(contentRect.bounds.width / aspectRatio),
      width: contentRect.bounds.width,
    });
    // setContainer({ width, height });
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    videoRef.current.play();
  }

  let handleSetMediaBlobURL = (mediaBlobUrl) => {
    console.log(mediaBlobUrl);
    handleMediaBlobURLChanged(mediaBlobUrl);
  };
  let mediaRecorder;

  let blobo = [];
  function doPreview() {
    console.log(blobo);
    if (!blobo.length) return;
    handleSetMediaBlobURL(
      URL.createObjectURL(new Blob(blobo, { type: mediaRecorder.mimeType }))
    );
    // Let's concatenate blobs to preview the recorded content
    // video.src = URL.createObjectURL(new Blob(blobs, { type: mediaRecorder.mimeType }));
  }
  let startRecording = () => {
    mediaRecorder = new MediaRecorder(mediaStream);
    // mediaRecorder = new MediaRecorder(mediaStream, mediaStreamOptions);
    mediaRecorder.ondataavailable = (event) => {
      // Let's append blobs for now, we could also upload them to the network.
      console.log(event);
      // if (event.data) blobo = [event.data];
      if (event.data) blobo.push(event.data);
    };
    mediaRecorder.onstop = doPreview;
    // Let's receive 1 second blobs
    mediaRecorder.start();
  };
  function endRecording() {
    // Let's stop capture and recording
    mediaRecorder.stop();
    // stream.getTracks().forEach((track) => track.stop());
  }

  function handleClear() {
    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    handleSetMediaBlobURL(undefined);
    setIsCanvasEmpty(true);
  }

  function handleCapture(callbackFn) {
    const context = canvasRef.current.getContext('2d');
    handleClear();

    // // try {

    // context.drawImage(
    //   videoRef.current,
    //   offsets.x,
    //   offsets.y,
    //   videoRef.current && videoRef.current.videoWidth,
    //   videoRef.current && videoRef.current.videoHeight,
    //   // 0,
    //   // 0,
    //   // width,
    //   // height
    // );

    context.drawImage(
      videoRef.current,
      0,
      0,
      videoRef.current && videoRef.current.videoWidth,
      videoRef.current && videoRef.current.videoHeight
      // 0,
      // 0,
      // 1920,
      // 1080
    );

    // // } catch (e) {
    // //   toast.error('drawImage error');
    // // }

    canvasRef.current.toBlob(
      (blob) => handleSetMediaBlobURL(blob),
      'image/jpeg',
      1
    );
    setIsCanvasEmpty(false);
    // setTimeout(() => {
    //   callbackFn && callbackFn();
    // }, 1000);
  }

  useLayoutEffect(() => {
    EventEmitter.on('media_capture_start', handleCapture);
    EventEmitter.on('media_capture_clear', handleClear);
    // handleResize();
    // handleCapture(handleClear);
    // handleClear();
    return () => {
      EventEmitter.off('media_capture_start');
      EventEmitter.off('media_capture_clear');
    };
  }, []);

  useEffect(() => {
    mediaBlobUrl && setVideoURL();
  }, [mediaBlobUrl]);

  let handlePlayVideo = () => {
    setVideoPlayingFlag(true);
    videoPlayer.current.play();
  };

  let handlePauseVideo = () => {
    setVideoPlayingFlag(false);
    videoPlayer.current.pause();
  };

  // useEffect(() => {
  //   if (videoRef.current && stream) {
  //     videoRef.current.srcObject = stream;
  //   }
  // }, [stream]);
  // if (!stream) {
  //   return null;
  // }
  // <video playsInline volume={0.0} ref={videoRef} width={width} autoPlay />
  return (
    <div style={{ ...style, width: '100%' }}>
      {mediaBlobUrl ? (
        <div
          // item
          // xs={10}
          className={classes.capturedImageContainer}
          // style={{}}
        >
          {props.engineData.type === 'video' ? (
            <video
              loop
              // controls
              autoPlay
              playsInline
              muted
              volume={0.0}
              ref={videoPlayer}
              width={width}
              // style={{ margin: 'auto' }}
              // width={700}
              src={mediaBlobUrl}
              className={clsx(classes.replayVideoPlayer)}
              onLoadedData={(e) => {
                e.target.play();
                setVideoPlayingFlag(true);
                e.target.volume = 0;
              }}
            />
          ) : (
            <img
              src={URL.createObjectURL(mediaBlobUrl)}
              alt="captured engine"
              className={clsx(classes.replayVideoPlayer)}
              style={{ width }}
            />
          )}
        </div>
      ) : null}
      <div
        // item
        // xs={10}
        style={{
          position: 'relative',
          display: mediaBlobUrl ? 'none' : undefined,
        }}
      >
        <div
          // style={{
          //   width,
          //   height,
          // }}
          className={classes.streamContainer}
        >
          <Measure bounds onResize={handleResize}>
            {({ measureRef }) => (
              <Wrapper>
                <Container
                  ref={measureRef}
                  // maxHeight={videoRef.current && videoRef.current.videoHeight}
                  // maxWidth={videoRef.current && videoRef.current.videoWidth}
                  // maxWidth={width}
                  // maxHeight={height}
                  style={{
                    height: `${container.height}px`,
                  }}
                >
                  <div>
                    <Video
                      ref={videoRef}
                      hidden={!isVideoPlaying}
                      onCanPlay={handleCanPlay}
                      autoPlay
                      playsInline
                      muted
                      // height={height}
                      width={width}
                      style={
                        {
                          // width
                          // top: `-${offsets.y}px`,
                          // left: `-${offsets.x}px`,
                          // height: '100vh',
                        }
                      }
                      className={classes.video}
                    />
                  </div>

                  <Overlay hidden={!isVideoPlaying} />

                  {/* <div> */}
                  <Canvas
                    ref={canvasRef}
                    // width={container.width}
                    // height={container.height}
                    // height={height}
                    // width={width}
                    height={videoRef.current && videoRef.current.videoHeight}
                    width={videoRef.current && videoRef.current.videoWidth}
                    // style={{ display: 'none' }}
                  />
                  {/* </div> */}

                  <Flash
                    flash={isFlashing}
                    onAnimationEnd={() => setIsFlashing(false)}
                  />
                </Container>

                {/* {isVideoPlaying && ( */}
                {/* <Button
                  onClick={isCanvasEmpty ? handleCapture : handleClear}
                  variant="contained"
                  className={classes.captureButton}
                >
                  {isCanvasEmpty ? 'Take a picture' : 'Take another picture'}
                </Button> */}
                {/* )} */}
              </Wrapper>
            )}
          </Measure>
        </div>
      </div>

      {/* <Grid item xs={2}> */}
      <RecordView
        {...recordViewProps}
        handleCapture={handleCapture}
        handleClear={handleClear}
        videoPlayingFlag={videoPlayingFlag}
        handlePlayVideo={handlePlayVideo}
        handlePauseVideo={handlePauseVideo}
        startRecordingGG={startRecording}
        endRecordingGG={endRecording}
      />
    </div>
    // </Grid>
  );
  //   return <video ref={videoRef} height={height} width={width} autoPlay />;
};
// <div className={classes.root}>
//   <Measure bounds onResize={handleResize}>
//     {({ measureRef }) => (
//       <div className={classes.wrapper}>
//         <div
//           ref={measureRef}
//           maxHeight={videoRef.current && videoRef.current.videoHeight}
//           maxWidth={videoRef.current && videoRef.current.videoWidth}
//           style={{
//             height: `${container.height}px`,
//             maxWidth: videoRef.current && videoRef.current.videoHeight,
//             maxHeight: videoRef.current && videoRef.current.videoWidth,
//           }}
//           className={classes.container}
//         >
//           {isCanvasEmpty ? (
//             <video
//               ref={videoRef}
//               // hidden={!isVideoPlaying}
//               onCanPlay={handleCanPlay}
//               // onLoad={handleCanPlay}
//               autoPlay
//               playsInline
//               // width={width}
//               height={height}
//               muted
//               style={{
//                 top: `-${offsets.y}px`,
//                 left: `-${offsets.x}px`,
//               }}
//               // className={classes.video}
//             />
//           ) : null}
//           {/* <Overlay hidden={!isVideoPlaying} /> */}
//           {/* <div style={{ width, height }}> */}
//             <canvas
//               ref={canvasRef}
//               width={container.width}
//               height={container.height}
//               // width={width}
//               // height={height}
//               className={classes.canvas}
//             />
//           {/* </div> */}
//           {/* <Flash
//           flash={isFlashing}
//           onAnimationEnd={() => setIsFlashing(false)}
//         /> */}
//           {/* </div>*/}
//           {/* {isVideoPlaying && ( */}
//           <Button
//             onClick={isCanvasEmpty ? handleCapture : handleClear}
//             variant="contained"
//             className={classes.captureButton}
//           >
//             {isCanvasEmpty ? 'Take a picture' : 'Take another picture'}
//           </Button>
//           )}
//         </div>
//       </div>
//     )}
//   </Measure>
// </div>

export default withStyles(styles)(VideoPreview);

// const VideoPreview = ({ stream, height }) => {
//     const videoRef = useRef(null);

//     useEffect(() => {
//       if (videoRef.current && stream) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.height = height;
//       }
//     }, [stream, height]);
//     if (!stream) {
//       return null;
//     }
//     return <video ref={videoRef} autoPlay />;
//   };

//   // export default RecordView;
//   export default VideoPreview;
