import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';

import RolesEnum from '../../../models/RolesEnum';
import RoutePath from './../../../lib/RoutePath';
import TechnicianEngineDetailsCardStyles from './TechnicianEngineDetailsCardStyles';
import { capitalizeFirstLetter } from '../../../util/CommonUtils';
import clsx from 'clsx';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = TechnicianEngineDetailsCardStyles;

class TechnicianEngineDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleGoToEngineDetails = () => {
    let { engineData } = this.props;
    RoutePath.navigateTo(this.props, RoutePath.engineDetails(engineData.id));
  };

  render() {
    let { classes, engineData, loggedUser } = this.props;
    let { deleteConfirmationDialogOpen } = this.state;
    // console.log(engineData);
    return (
      <Card
        className={clsx({
          [classes.disabledCardRoot]:
            engineData.approved_status === 'cancelled',
        })}
      >
        <CardActionArea
          disabled={engineData.approved_status === 'cancelled'}
          onClick={
            // engineData.approved_status !== 'cancelled' &&
            this.handleGoToEngineDetails
          }
          aria-label="select-engine"
          className={clsx(classes.root, {
            [classes.disabledCard]: engineData.approved_status === 'cancelled',
          })}
        >
          <CardHeader
            // action={
            //   <IconButton
            //     aria-label="delete-engine"
            //     onClick={this.handleDeleteConfirmationDialogOpen}
            //   >
            //     <DeleteIcon />
            //   </IconButton>
            // }
            title={engineData.awi_serial_num}
            // subheader={engineData.awi_label}
            // subheader="September 14, 2016"
          />
          <CardMedia
            // component={engineData.type === 'video' ? 'video' : 'img'}
            component={'img'}
            alt={engineData.awi_label}
            height="140"
            image={engineData.awi_image_url}
            // title="Contemplative Reptile"
            // onClick={this.handleVideoPlayerOpen(engineData.awi_image_url)}
          />
          <CardContent>
            <Grid container direction="row" justify={'space-between'}>
              <Grid item>
                <Typography variant={'caption'}>Upload Date</Typography>
              </Grid>
              <Grid item>
                <Typography variant={'caption'}>Status</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justify={'space-between'}>
              <Grid item>
                <Typography variant={'caption'} className={classes.boldText}>
                  {moment(engineData.ts_creation).format('lll')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'caption'} className={classes.boldText}>
                  {capitalizeFirstLetter(engineData.approved_status)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedUser: state.UserReducer.loggedUser,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    // openVideoPlayer: (videoData) =>
    //   dispatch(VideoPlayerActions.openVideoPlayer(videoData)),
    // closeVideoPlayer: () => dispatch(VideoPlayerActions.closeVideoPlayer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(TechnicianEngineDetailsCard)));
