import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';

import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import EngineService from '../../services/EngineService';
import RoutePath from '../../lib/RoutePath';
import SearchIcon from '@material-ui/icons/Search';
import SwitchBase from '@material-ui/core/internal/SwitchBase';
import TechnicianListStyles from './TechnicianListStyles';
import { capitalizeFirstLetter } from '../../util/CommonUtils';
import { isMobile } from 'mobile-device-detect';
import tempOb from './tempOb';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = TechnicianListStyles;

function createData(
  image,
  technicianName,
  technicianEmail,
  activeEngines,
  incompleteEngines,
  approvedEngines,
  cancelledEngines,
  totalEngines,
  actions,
  technicianData
) {
  return {
    image,
    technicianName,
    technicianEmail,
    activeEngines,
    incompleteEngines,
    approvedEngines,
    cancelledEngines,
    totalEngines,
    actions,
    technicianData,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
class TechnicianList extends Component {
  constructor(props) {
    super(props);

    // image,
    // technicianName,
    // technicianEmail,
    // activeEngines,
    // incompleteEngines,
    // approvedEngines,
    // cancelledEngines,
    // totalEngines,
    // status,
    // actions,
    this.headCells = [
      {
        id: 'image',
        numeric: false,
        disablePadding: false,
        label: 'Profile Image',
        sortable: false,
      },
      {
        id: 'technicianName',
        numeric: false,
        disablePadding: false,
        label: 'Technician Name',
        sortable: true,
      },
      {
        id: 'technicianEmail',
        numeric: false,
        disablePadding: false,
        label: 'Technician Email',
        sortable: true,
      },
      {
        id: 'activeEngines',
        numeric: true,
        disablePadding: false,
        label: 'Active Engines',
        sortable: true,
      },
      {
        id: 'incompleteEngines',
        numeric: true,
        disablePadding: false,
        label: 'Incomplete Engines',
        sortable: true,
      },
      {
        id: 'approvedEngines',
        numeric: true,
        disablePadding: false,
        label: 'Approved Engines',
        sortable: true,
      },
      {
        id: 'cancelledEngines',
        numeric: true,
        disablePadding: false,
        label: 'Cancelled Engines',
        sortable: true,
      },
      {
        id: 'totalEngines',
        numeric: true,
        disablePadding: false,
        label: 'Total Engines',
        sortable: true,
      },
      // {
      //   id: 'actions',
      //   numeric: false,
      //   disablePadding: false,
      //   label: 'Actions',
      //   sortable: false,
      // },
    ];

    this.state = {
      order: 'asc',
      orderBy: 'createdAt',
      searchString: '',
      page: 0,
      dense: false,
      rowsPerPage: 5,
      rows: [],
      selected: [],
      technicianList: [],
      filteredTechnicianList: [],
    };
  }

  componentDidMount = () => {
    this.handleGetListOfUsers();
  };

  componentDidUpdate = (prevProps, prevState) => {
    let { searchString, technicianList } = this.state;
    if (prevState.technicianList !== technicianList) {
      this.createRows();
    }
    if (prevState.searchString !== searchString) {
      this.handleGetFilteredListOfUsers();
    }
  };

  handleGetListOfUsers = async () => {
    let data = {};
    let response = await EngineService.listOfUsers(data);
    console.log('handleGetListOfUsers', response);
    if (response.success) {
      // TODO implement these
      // let filteredTechnicianList = response.data;
      this.setState(
        { technicianList: response.data },
        this.handleGetFilteredListOfUsers
      );
    }
  };

  handleGetFilteredListOfUsers = (
    technicianList = this.state.technicianList
  ) => {
    let { searchString } = this.state;

    let filteredTechnicianList = technicianList.filter(
      (technicianData, index) => {
        let searchStringMatched = false;
        // console.log(technicianData);
        if (searchString) {
          if (
            technicianData.awi_personal.firstname
              .toLowerCase()
              .search(searchString.toLowerCase()) >= 0 ||
            technicianData.awi_personal.lastname
              .toLowerCase()
              .search(searchString.toLowerCase()) >= 0 ||
            technicianData.email
              .toLowerCase()
              .search(searchString.toLowerCase()) >= 0
          ) {
            searchStringMatched = true;
          }
        } else {
          searchStringMatched = true;
        }
        return searchStringMatched;
      }
    );
    // console.log(filteredTechnicianList);
    this.setState({ filteredTechnicianList }, this.createRows);
  };

  createRows = () => {
    let { classes } = this.props;
    let { filteredTechnicianList } = this.state;
    // console.log(filteredImageList);
    // let filteredTechnicianList = tempOb;

    const rows = filteredTechnicianList.map((technicianData, index) => {
      // console.log(technicianData);
      return createData(
        // image
        <div className={classes.profileIconThumbnail}>
          {technicianData.awi_personal.firstname.charAt(0).toUpperCase()}
          {technicianData.awi_personal.lastname.charAt(0).toUpperCase()}
        </div>,
        // technicianName,
        `${capitalizeFirstLetter(
          technicianData.awi_personal.firstname
        )} ${capitalizeFirstLetter(technicianData.awi_personal.lastname)}`,
        // technicianEmail,
        technicianData.email,
        // activeEngines,
        (technicianData.total_engines.count || 0) -
          (technicianData?.engine_status.approved || 0) -
          (technicianData?.engine_status.incomplete || 0) -
          (technicianData?.engine_status.cancelled || 0) || 0,
        // incompleteEngines,
        technicianData?.engine_status.incomplete || 0,
        // approvedEngines
        technicianData?.engine_status.approved || 0,
        // cancelledEngines
        technicianData?.engine_status.cancelled || 0,
        // totalEngines
        technicianData?.total_engines.count || 0,
        //actions
        <Grid container direction={'row'} justify={'center'}>
          <Grid item>
            <IconButton>{/* <DoneIcon /> */}</IconButton>
          </Grid>
          <Grid item>
            <IconButton>{/* <ClearIcon /> */}</IconButton>
          </Grid>
          <Grid item>
            <IconButton>{/* <DeleteForeverIcon /> */}</IconButton>
          </Grid>
        </Grid>,

        technicianData
      );
    });
    this.setState({ rows });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    let { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setOrder(isAsc ? 'desc' : 'asc');
    this.setOrderBy(property);
  };

  setOrder = (value) => {
    this.setState({ order: value });
  };

  setOrderBy = (value) => {
    this.setState({ orderBy: value });
  };

  setSelected = (value) => {
    this.setState({ selected: value });
  };

  setPage = (value) => {
    this.setState({ page: value });
  };

  setDense = (value) => {
    this.setState({ dense: value });
  };

  setRowsPerPage = (value) => {
    this.setState({ rowsPerPage: value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleGoToInvitePage = () => {
    RoutePath.navigateTo(this.props, RoutePath.invitePage);
  };

  handleGoToTechnicianList = (row, index) => () => {
    RoutePath.navigateTo(
      this.props,
      RoutePath.technicianDetailsPath(row.technicianData.id)
    );
  };

  render() {
    let { classes } = this.props;
    let {
      order,
      orderBy,
      selected,
      page,
      dense,
      rowsPerPage,
      rows,
      latestSelectedUserData,
      searchString,
      assignImageModalOpenFlag,
      latestSelectedImageAssign,
      deleteImageModalOpenFlag,
      latestSelectedImageToDelete,
      annotatorList,
      selectedDataset,
    } = this.state;

    let isSelected = (id) =>
      selected.findIndex((selectedObj, index) => {
        // console.log('selectedObj',selectedObj);
        return selectedObj.id === id;
      }) !== -1;

    let emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
      <div className={classes.root}>
        <div className={classes.headingContainer}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                TECHNICIAN LIST
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGoToInvitePage}
              >
                <AddCircleTwoToneIcon />
                &nbsp;Invite Technician
              </Button>
            </div>
          ) : null}
        </div>
        <div className={classes.pageContainer}>
          {isMobile ? null : (
            <div className={classes.headingContainer}>
              <Toolbar className={classes.toolbarRoot}>
                <Typography
                  variant="h4"
                  // align="center"
                  className={classes.heading}
                >
                  Technician List
                </Typography>
                <div className={classes.toolbarOptionsContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleGoToInvitePage}
                  >
                    <AddCircleTwoToneIcon />
                    &nbsp;Invite Technician
                  </Button>

                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      name={'searchString'}
                      value={searchString}
                      onChange={this.handleChange}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                </div>
              </Toolbar>
            </div>
          )}

          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              // size={'medium'}
              size={'small'}
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell> */}
                  {/*   <Checkbox*/}
                  {/*      indeterminate={selected.length > 0 && selected.length < rows.length}*/}
                  {/*      checked={rows.length > 0 && selected.length === rows.length}*/}
                  {/*      onChange={this.handleSelectAllClick}*/}
                  {/*      inputProps={{'aria-label': 'select all users'}}*/}
                  {/*   />*/}
                  {/*</TableCell>*/}
                  {this.headCells.map((headCell) => {
                    return (
                      <TableCell
                        key={headCell.id}
                        align={'center'}
                        // padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={this.createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length ? (
                  stableSort(rows, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.technicianEmail);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      {/* console.log('row', row); */}
                      return (
                        <TableRow
                          key={row.technicianEmail}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          onClick={this.handleGoToTechnicianList(row, index)}
                        >
                          {/*<TableCell>*/}
                          {/*   <Checkbox*/}
                          {/*      checked={isItemSelected}*/}
                          {/*      onClick={(event) => this.handleClick(event, {*/}
                          {/*         id: row.imageID,*/}
                          {/*         imageName: row.imageName.toLowerCase()*/}
                          {/*      })}*/}
                          {/*      inputProps={{'aria-labelledby': labelId}}*/}
                          {/*   />*/}
                          {/*</TableCell>*/}
                          <TableCell align="center" className={'truncateText'}>
                            {row.image}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.technicianName}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.technicianEmail}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.activeEngines}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.incompleteEngines}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.approvedEngines}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.cancelledEngines}
                          </TableCell>
                          <TableCell align="center" className={'truncateText'}>
                            {row.totalEngines}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            className={classes.actionCell}
                          >
                            {row.actions}
                          </TableCell> */}
                          {/*<TableCell align="center">{row.actionsButton}</TableCell>*/}
                        </TableRow>
                      );
                    })
                ) : searchString ? (
                  <TableRow
                    style={{ height: (dense ? 33 : 53) * emptyRows }}
                    className={classes.noDataText}
                  >
                    <TableCell
                      colSpan={this.headCells.length}
                      className={classes.noDataText}
                    >
                      No data available for selected filters
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    style={{ height: (dense ? 33 : 53) * emptyRows }}
                    className={classes.noDataText}
                  >
                    <TableCell
                      colSpan={this.headCells.length}
                      className={classes.noDataText}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && !searchString && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TechnicianList);
