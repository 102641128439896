import ReduxActionEnum from '../../models/ReduxActionEnum';
import generalInitialState from "../states/GeneralInitialState";

const GeneralReducer = (state = generalInitialState, action) => {
   switch (action.type) {
      default:
         return state;
   }
};

export default GeneralReducer;
