import { Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AccountPageStyles from './AccountPageStyles';
import GenUtil from '../../util/GenUtil';
import RoutePath from '../../lib/RoutePath';
import UserActions from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import { isMobile } from 'mobile-device-detect';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = AccountPageStyles;

class AccountPage extends Component {
  handleLogout = () => {
    //  this.handleMenuClose();
    this.props.logoutUser();
    GenUtil.removeAccessToken();
    toast.success('Logged Out Successfully!!!');
    RoutePath.navigateTo(this.props, RoutePath.loginPath);
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headingContainer}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                MY ACCOUNT
              </Typography>
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                My Account
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.pageContainer}>
          <Button
            onClick={this.handleLogout}
            fullWidth
            color="secondary"
            variant="contained"
          >
            LOGOUT
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.UserReducer.loggedIn,
    // isDarkMode: state.SettingsReducer.isDarkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(UserActions.logoutUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountPage));
