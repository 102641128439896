let EngineCardStyles = (theme) => ({
  root: {
    // width: '100%',

    marginBottom: theme.spacing(2),
    // paddingBottom: theme.spacing(12),
  },
  disabledCardRoot: {
    cursor: 'not-allowed',
  },
  cardHeaderContainer: {
    position: 'relative',
  },
  cameraIndicationIcon: {
    marginLeft:theme.spacing(1),
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.grey['300'],
    borderRadius: 10,
    // color:theme.palette.primary.contrastText
  },
  actionButtonsContainer: {
    position: 'relative',
  },
  deleteEngineButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  disabledCard: {
    background: 'rgba(0,0,0,0.3)',
  },
  boldText: {
    fontWeight: 'bold',
  },
  cardContentRoot: {
    '&:last-child': {
      paddingBottom: 13,
    },
  },
});

export default EngineCardStyles;
