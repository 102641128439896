/* eslint-disable */

import {
  Button,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import DeleteIcon from '@material-ui/icons/Delete';
import EngineCardStyles from './EngineCardStyles';
import EngineService from '../../../services/EngineService';
import EventEmitter from '../../../util/EventEmitter';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import PhotoCameraTwoToneIcon from '@material-ui/icons/PhotoCameraTwoTone';
import RolesEnum from './../../../models/RolesEnum';
import RoutePath from '../../../lib/RoutePath';
import VideoPlayerActions from '../../../redux/actions/VideoPlayerActions';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import VideocamTwoToneIcon from '@material-ui/icons/VideocamTwoTone';
import { capitalizeFirstLetter } from '../../../util/CommonUtils';
import clsx from 'clsx';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = EngineCardStyles;

class EngineCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmationDialogOpen: false,
    };
  }

  handleGoToEngineDetails = () => {
    let { classes, engineData } = this.props;
    RoutePath.navigateTo(this.props, RoutePath.engineDetails(engineData.id));
  };

  handleEngineDelete = async () => {
    let { classes, engineData } = this.props;
    console.log(engineData);
    // let { engineID } = this.props.match.params;
    let data = {
      awi_engine_ids: [engineData.id], // ARRAY OF SYSTEM_GENERATED_ENGINE_IDS
    };

    let response = await EngineService.removeEngines(data);
    if (response.success) {
      EventEmitter.emit('engineDeleted');
      toast.success(response.message);
      this.handleDeleteConfirmationDialogClose();
    } else {
      toast.error(response.message);
    }
  };

  handleDeleteConfirmationDialogOpen = (e) => {
    e.stopPropagation();
    this.setState({ deleteConfirmationDialogOpen: true });
  };

  handleDeleteConfirmationDialogClose = () => {
    this.setState({ deleteConfirmationDialogOpen: false });
  };

  cardContentContainer = () => {
    let { classes, engineData, loggedUser } = this.props;
    let { deleteConfirmationDialogOpen } = this.state;
    // console.log(engineData);
    return (
      <>
        <CardHeader
          action={
            (loggedUser.userObj?.level !== RolesEnum.TECHNICIAN ||
              engineData.awi_engine_action !== 'assigned') && (
              <IconButton
                disabled={false}
                aria-label="delete-engine"
                component={'div'}
                // className={classes.deleteEngineButton}
                onClick={this.handleDeleteConfirmationDialogOpen}
              >
                <DeleteIcon />
              </IconButton>
            )
          }
          title={
            <div className={'basic_flex_row align_items_center'}>
              {engineData.awi_serial_num}{' '}
              <div
                className={clsx(
                  classes.cameraIndicationIcon,
                  'basic_flex_row align_items_center'
                )}
              >
                {engineData.type === 'video' ? (
                  <VideocamOutlinedIcon />
                ) : (
                  <PhotoCameraOutlinedIcon />
                )}
              </div>
            </div>
          }
          subheader={'Location: ' + engineData.location}
          // subheader="September 14, 2016"
        />

        <CardMedia
          // component={engineData.type === 'video' ? 'video' : 'img'}
          component={'img'}
          alt={engineData.awi_label}
          height="140"
          image={engineData.awi_image_url}
          // title="Contemplative Reptile"
        />
        <CardContent classes={{ root: classes.cardContentRoot }}>
          {loggedUser.userObj?.level === RolesEnum.MANAGER ? (
            <>
              <Grid container direction="row" justify={'space-between'}>
                <Grid item>
                  <Typography variant={'caption'}>Technician Name:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'caption'}>Technician Email:</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justify={'space-between'}>
                <Grid item>
                  <Typography
                    nowrap="true"
                    variant={'caption'}
                    className={classes.boldText}
                  >
                    {capitalizeFirstLetter(engineData.user.firstname)}&nbsp;
                    {capitalizeFirstLetter(engineData.user.lastname)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    nowrap="true"
                    variant={'caption'}
                    className={classes.boldText}
                  >
                    {engineData.user_email}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : null}

          <Grid container direction="row" justify={'space-between'}>
            <Grid item>
              <Typography variant={'caption'}>Upload Date</Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>Status</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify={'space-between'}>
            <Grid item>
              <Typography variant={'caption'} className={classes.boldText}>
                {moment(engineData.ts_creation).format('lll')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'} className={classes.boldText}>
                {capitalizeFirstLetter(engineData.approved_status)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  };

  render() {
    let { classes, engineData, loggedUser } = this.props;
    let { deleteConfirmationDialogOpen } = this.state;
    // console.log(engineData);
    // console.log('loggedUser', loggedUser);
    return (
      <div>
        <Card
          // className={classes.root}
          className={clsx(classes.root, {
            [classes.disabledCardRoot]:
              engineData.approved_status === 'cancelled',
          })}
        >
          {engineData.approved_status === 'cancelled' ? (
            <div
              aria-label="select-engine"
              className={clsx({
                [classes.disabledCard]:
                  engineData.approved_status === 'cancelled',
              })}
            >
              {this.cardContentContainer()}
            </div>
          ) : (
            <CardActionArea
              // disabled={engineData.approved_status === 'cancelled'}
              onClick={
                engineData.approved_status !== 'cancelled' &&
                this.handleGoToEngineDetails
              }
              aria-label="select-engine"
              className={clsx({
                [classes.disabledCard]:
                  engineData.approved_status === 'cancelled',
              })}
            >
              {this.cardContentContainer()}
            </CardActionArea>
          )}
        </Card>

        <Dialog
          open={deleteConfirmationDialogOpen}
          onClose={this.handleDeleteConfirmationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Confirm Deletion'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              It will also delete its related profiles, sections and
              sub-sections.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDeleteConfirmationDialogClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={this.handleEngineDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedUser: state.UserReducer.loggedUser,
    // playerVisible: state.VideoPlayerReducer.playerVisible,
    // playerURL: state.VideoPlayerReducer.playerURL,
    // controlsVisible: state.VideoPlayerReducer.controlsVisible,
    // loopVideo: state.VideoPlayerReducer.loopVideo,
    // autoPlay: state.VideoPlayerReducer.autoPlay,
    // loggedIn: state.loggedIn
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    // openVideoPlayer: (videoData) =>
    //   dispatch(VideoPlayerActions.openVideoPlayer(videoData)),
    // closeVideoPlayer: () => dispatch(VideoPlayerActions.closeVideoPlayer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(EngineCard)));
