import { Badge, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import {
  capitalizeFirstLetter,
  convertUnderscoreCaseToTitleCase,
} from '../../../util/CommonUtils';

import DesktopNotificationCardStyles from './DesktopNotificationCardStyles';
import EngineService from '../../../services/EngineService';
import RoutePath from '../../../lib/RoutePath';
import TypeEnum from './../../../models/TypeEnum';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = DesktopNotificationCardStyles;

class DesktopNotificationCard extends Component {
  handleNavigateToEngineDetailsURL = () => {
    //     awi_comment_id: "1613536401879"
    // awi_content_label: "lf_1a"
    // awi_content_type: "sub_section"
    // awi_engine_type: "video"
    // awi_parent_label: "left front"
    // awi_root_parent_label: "left"
    // awi_serial_num: "V23444"
    // is_seen: false
    let { notificationData } = this.props;
    let queryParams;
    if (notificationData.comment_data.type === 'comment') {
      if (notificationData.comment_data.awi_root_parent_label) {
        queryParams = `?openComments=true&expandedProfile=${notificationData.comment_data.awi_root_parent_label}&expandedSection=${notificationData.comment_data.awi_parent_label}&expandedSubSection=${notificationData.comment_data.awi_content_label}`;
      } else if (notificationData.comment_data.awi_parent_label) {
        queryParams = `?openComments=true&expandedProfile=${notificationData.comment_data.awi_parent_label}&expandedSection=${notificationData.comment_data.awi_content_label}`;
      } else if (notificationData.comment_data.awi_content_label) {
        queryParams = `?openComments=true&expandedProfile=${notificationData.comment_data.awi_content_label}`;
      }
    } else {
      if (notificationData.comment_data.awi_root_parent_label) {
        queryParams = `?expandedProfile=${notificationData.comment_data.awi_root_parent_label}&expandedSection=${notificationData.comment_data.awi_parent_label}`;
      } else if (notificationData.comment_data.awi_parent_label) {
        queryParams = `?expandedProfile=${notificationData.comment_data.awi_parent_label}`;
      } else if (notificationData.comment_data.awi_content_label) {
        queryParams = '';
      }
    }
    this.handleMarkCommentAsRead(notificationData);
    if (notificationData.comment_data.type === 'cancellation') {
      RoutePath.navigateTo(this.props, RoutePath.engineList);
    } else {
      RoutePath.navigateTo(
        this.props,
        `${RoutePath.engineDetails(
          notificationData.comment_data.awi_engine_id
        )}${queryParams}`
      );
    }
  };

  handleMarkCommentAsRead = async (notificationData) => {
    let data = {
      awi_comment_ids: [notificationData.comment_data.awi_comment_id],
    };
    let response = await EngineService.updateCommentStatus(data);
    console.log('updateCommentStatus', data, response);
    if (response.success) {
      // toast.success(response.message);
      // this.handleFetchAllRecievedComments();
    }
  };

  render() {
    let { classes, notificationData } = this.props;
    let actionTaken = '';
    let borderColor = '';
    if (notificationData.comment_data.type === TypeEnum.COMMENT) {
      actionTaken = 'commented on your Image';
      borderColor = 'blue';
    } else if (notificationData.comment_data.type === TypeEnum.APPROVAL) {
      actionTaken = `approved a ${notificationData.comment_data.awi_content_type}`;
      borderColor = 'green';
    } else if (notificationData.comment_data.type === TypeEnum.REJECTION) {
      actionTaken = `rejected a ${notificationData.comment_data.awi_content_type}`;
      borderColor = 'red';
    } else if (notificationData.comment_data.type === TypeEnum.CANCELLATION) {
      actionTaken = 'cancelled an Engine';
      borderColor = 'orange';
    } else if (notificationData.comment_data.type === TypeEnum.NEW_ENGINE) {
      actionTaken = 'added a new Engine';
      borderColor = 'blue';
    }
    console.log(notificationData);
    return (
      <div
        className={classes.root}
        onClick={this.handleNavigateToEngineDetailsURL}
      >
        <Grid container direction="row" spacing={2}>
          <Grid
            item
            xs={3}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={7}>
              {moment(notificationData.comment_data.ts_creation).format('LL')}
              <br />
              {moment(notificationData.comment_data.ts_creation).format('LT')}
            </Grid>
            <Grid item xs={5}>
              <div className={classes.profileIconThumbnail}>
                {notificationData.comment_data.sender.firstname
                  .charAt(0)
                  .toUpperCase()}
                {notificationData.comment_data.sender.lastname
                  .charAt(0)
                  .toUpperCase()}
              </div>
            </Grid>
          </Grid>
          <Grid component={Paper} item xs={9} className={classes.rowContainer}>
            <Badge
              invisible={notificationData.comment_data.is_seen}
              variant="dot"
              color="error"
            >
              <div className={classes.rowInternalContainer}>
                <div className={classes.actionTakenDiv} style={{ borderColor }}>
                  <Typography>
                    {capitalizeFirstLetter(
                      notificationData.comment_data.sender.firstname
                    )}{' '}
                    {actionTaken}
                  </Typography>
                </div>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={2}>
                    {notificationData.comment_data.awi_engine_type ===
                    'image' ? (
                      <img
                        src={notificationData.awi_image_url}
                        alt={notificationData.comment_data.awi_content_label}
                        className={classes.thumbnailImage}
                      />
                    ) : (
                      <video
                        src={notificationData.awi_image_url}
                        alt={notificationData.comment_data.awi_content_label}
                        className={classes.thumbnailImage}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      ESN: {notificationData.comment_data.awi_serial_num}
                    </Typography>
                    <Typography>
                      {convertUnderscoreCaseToTitleCase(
                        notificationData.comment_data.awi_content_type
                      )}
                      : {notificationData.comment_data.awi_content_label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Comment:</Typography>
                    <Typography>
                      {notificationData.comment_data.text}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Badge>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DesktopNotificationCard));
