let SmallCommentListStyles = (theme) => ({
  root: {
    width: '100%',
  },
  messageContainer: {
    width:'100%',
    padding: theme.spacing(1, 1),
    minWidth: 170,
  },
  timelineOppositeContent: {
    flex: 'unset',
    padding: 6,
    whiteSpace: 'nowrap',
  },
  missingOppositeContent: {
    '&:before': {
      flex: 'unset',
      padding: 'unset',
    },
  },
  messageInputRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  messageInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  sendButton: {
    padding: 10,
  },
  messageInputDivider: {
    height: 28,
    margin: 4,
  },
  messageSenderName: {
    fontWeight: 'bold',
  },
  messageSenderDesignation: {
    // fontWeight: 'bold',
  },
  messageText: {
    overflowWrap: 'anywhere',
  },
});

export default SmallCommentListStyles;
