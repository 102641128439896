import { Backdrop, Grid, Typography } from '@material-ui/core';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import React, { Component } from 'react';

import { AutoSizer } from 'react-virtualized';
import DataCollectionPageStyles from './DataCollectionPageStyles';
import EngineService from '../../../services/EngineService';
import EventEmitter from '../../../util/EventEmitter';
import MediaTrackConstraints from './VideoRecorder/MediaTrackConstraints';
import { ReactMediaRecorder } from 'react-media-recorder';
import RecordView from './VideoRecorder/RecordView/RecordView';
import VideoPreview from './VideoRecorder/VideoPreview/VideoPreview';
import VideoRecorder from 'react-video-recorder';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = DataCollectionPageStyles;

class DataCollectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: [],
      cameraDimensions: {
        width: 0,
        height: 0,
      },
      screenOrientation: undefined,
      // selectedImageFlag: false,
      selectedImageFlag: !this.isIos(),
      recordingFlag: false,
      videoPlayingFlag: false,
      engineData: {
        type: 'image',
      },
    };

    this.imageRef = React.createRef();
    this.videoPlayer = React.createRef();
  }
  componentDidMount = async () => {
    await this.handleGetProfileData();
    await this.handleGetEngineStatusInHierarchy();
    this.handleImageResized();
    window.addEventListener('resize', this.handleImageResized);
    window.addEventListener('orientationchange', this.handleImageResized);
  };
  componentWillUnmount = async () => {
    window.removeEventListener('resize', this.handleImageResized);
    window.removeEventListener('orientationchange', this.handleImageResized);
  };

  handleGetEngineStatusInHierarchy = async (e) => {
    let { engineID } = this.props.match.params;
    let data = {
      awi_engine_id: engineID * 1,
    };
    let response = await EngineService.getEngineStatusInHierarchy(data);
    console.log('getEngineStatusInHierarchy', response);
    if (response.success) {
      this.setState({ engineData: response.data[0] });
    }
  };

  handleGetProfileData = async () => {
    // console.log(this.props.match.params);

    // console.log('devices', devices,navigator.mediaDevices);
    let {
      engineID,
      profileID,
      sectionID,
      subSectionID,
    } = this.props.match.params;
    let data = { awi_label: profileID };
    let response = await EngineService.preFetchEntries(data);
    if (response.success) {
      console.log('handleGetProfileData', response);
      this.setState(
        { profileData: response.data[0] },
        this.handleGetSectionsData
      );
    }
  };

  handleGetSectionsData = async () => {
    // console.log(this.props.match.params);
    // this.handleGetSelectedSectionData();
    // console.log('devices', devices,navigator.mediaDevices);
    let {
      engineID,
      profileID,
      sectionID,
      subSectionID,
    } = this.props.match.params;
    let data = {
      type: 'section',
      // awi_label: sectionID
    };
    let response = await EngineService.preFetchEntries(data);
    if (response.success) {
      console.log('preFetchEntries handleGetSectionsData', response);
      let selectedSection;
      if (sectionID) {
        selectedSection = response.data.find((sectionData) => {
          return sectionID === sectionData.awi_label;
        });
        console.log('preFetchEntries selectedSection', selectedSection);
      }
      this.setState(
        { sectionsData: response.data[0], selectedSection },
        this.handleGetSubSectionsData
      );
    }
  };

  handleGetSubSectionsData = async () => {
    // console.log(this.props.match.params);

    // console.log('devices', devices,navigator.mediaDevices);
    let {
      engineID,
      profileID,
      sectionID,
      subSectionID,
    } = this.props.match.params;
    let data = {
      type: 'sub_section',
      // awi_label: profileID
    };
    let response = await EngineService.preFetchEntries(data);
    if (response.success) {
      console.log('preFetchEntries handleGetSubSectionsData', response);
      let selectedSubSection;
      if (subSectionID) {
        selectedSubSection = response.data.find((subSectionData) => {
          return subSectionID === subSectionData.awi_label;
        });
        console.log('preFetchEntries selectedSubSection', selectedSubSection);
      }
      this.setState({ subSectionsData: response.data[0], selectedSubSection });
    }
  };

  handleImageLoaded = (e) => {
    // console.log(e.target);
    // console.log(e.target.width);
    // console.log(e.target.height);
    let { width, height } = e.target;
    this.setState({ cameraDimensions: { width, height } });
    this.setScreenOrientation();
    // EventEmitter.emit('media_capture_clear')
  };

  handleImageResized = (e) => {
    let imageObj = this.imageRef.current;
    console.log(imageObj);
    if (imageObj) {
      console.log(imageObj.width);
      console.log(imageObj.height);
      let { width, height } = imageObj;
      this.setState({ cameraDimensions: { width, height } });
    }
    this.setScreenOrientation();
  };

  setScreenOrientation = () => {
    if (window.matchMedia('(orientation: portrait)').matches) {
      console.log('orientation: portrait');
      this.setState({
        screenOrientation: 'portrait',
      });
    }

    if (window.matchMedia('(orientation: landscape)').matches) {
      console.log('orientation: landscape');
      this.setState({
        screenOrientation: 'landscape',
      });
    }
  };

  //   onRecordingComplete = (blob) => {
  //     console.log(blob);
  //     this.video = <video src={URL.createObjectURL(blob)}></video>;
  //   };

  renderActions = (e) => {
    // console.log(e);
    return (
      <>
        <button
          onClick={() => {
            e.onStartRecording();
          }}
          style={{ zIndex: 10000 }}
        >
          Record
        </button>
        <button
          onClick={() => {
            e.onStopRecording();
          }}
          style={{ zIndex: 10000 }}
        >
          Stop
        </button>
      </>
    );
  };

  handleVideoURLChanged = (videoURL) => {
    //   console.log(videoURL);
    this.setState({ videoURL });
  };

  handleSelectedImageChanged = () => {
    //   console.log(videoURL);
    let { selectedImageFlag } = this.state;
    this.setState({ selectedImageFlag: !selectedImageFlag });
  };

  handleSetRecordingFlag = (recordingFlag) => {
    this.setState({ recordingFlag });
  };

  handlePlayVideo = () => {
    this.setState({ videoPlayingFlag: true });
    this.videoPlayer.current.play();
  };

  handlePauseVideo = () => {
    this.setState({ videoPlayingFlag: false });
    this.videoPlayer.current.pause();
  };

  isIos = () => {
    return navigator.platform.toLowerCase() === 'iphone';
  };

  videoPreview = () => {
    let { classes, history, match } = this.props;
    let { engineID, profileID } = match.params;
    let {
      engineData,
      profileData,
      sectionsData,
      selectedSection,
      subSectionsData,
      selectedSubSection,
      cameraDimensions,
      selectedImageFlag,
      screenOrientation,
      videoURL,
      recordingFlag,
      videoPlayingFlag,
    } = this.state;
    let { width, height } = cameraDimensions;
    return (
      <VideoPreview
        width={width}
        height={height}
        engineData={engineData}
        profileData={profileData}
        selectedSection={selectedSection}
        selectedSubSection={selectedSubSection}
        mediaBlobUrl={videoURL}
        handleMediaBlobURLChanged={this.handleVideoURLChanged}
        style={
          {
            // width,
            // height,
            // display: videoURL ? 'none' : undefined,
          }
        }
        recordViewProps={{
          engineID: engineID,
          engineData: engineData,
          profileData: profileData,
          sectionsData: sectionsData,
          selectedImage: selectedImageFlag,
          handleImageChange: this.handleSelectedImageChanged,
          history: history,
          match: match,
          screenOrientation: screenOrientation,
          mediaBlobUrl: videoURL,
          handleMediaBlobURLChanged: this.handleVideoURLChanged,
          recordingFlag: recordingFlag,
          handleSetRecordingFlag: this.handleSetRecordingFlag,
          // videoPlayingFlag: videoPlayingFlag,
          // handlePlayVideo: this.handlePlayVideo,
          // handlePauseVideo: this.handlePauseVideo,
        }}
        // stream={previewStream}

        // overlayImageProps={{
        //   profileSelected,
        //   profileData,
        //   selectedImageFlag,
        //   videoURL,
        //   recordingFlag,
        //   selectedSection,
        //   sectionSelected,
        //   selectedImageFlag,
        //   screenOrientation,
        // }}
      />
    );
  };

  render() {
    let { classes, history, match } = this.props;
    let { engineID, profileID } = match.params;
    let {
      engineData,
      profileData,
      sectionsData,
      selectedSection,
      subSectionsData,
      selectedSubSection,
      cameraDimensions,
      selectedImageFlag,
      screenOrientation,
      videoURL,
      recordingFlag,
      videoPlayingFlag,
    } = this.state;
    let { width, height } = cameraDimensions;

    let subSectionSelected = !!selectedSubSection;
    let sectionSelected = !!selectedSection && !subSectionSelected;
    let profileSelected =
      !!profileData && !sectionSelected && !subSectionSelected;

    // console.log(!!profileData, !!selectedSection, !!selectedSubSection);
    // console.log(profileSelected, sectionSelected, subSectionSelected);
    return (
      <div
        className={clsx({
          [classes.portraitRoot]: screenOrientation === 'portrait',
          [classes.landscapeRoot]: screenOrientation === 'landscape',
        })}
      >
        {screenOrientation === 'landscape' ? (
          <div
            // item
            // xs={10}
            className={classes.previewContainer}
            // style={{ width }}
          >
            <div className={classes.overlayImageContainer}>
              {profileSelected ? (
                selectedImageFlag ? (
                  <>
                    <Typography className={classes.labelText}>
                      Reference Image <br />
                      Profile: {profileData.awi_label}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      className={classes.bottomInstructions}
                    >
                      Please try to capture a view of the engine similar to the
                      one shown above.
                    </Typography>
                    <img
                      ref={this.imageRef}
                      // key={uuid()}
                      src={profileData.awi_reference_image_url}
                      onLoad={this.handleImageLoaded}
                      alt={`${profileData.awi_label} reference`}
                      className={clsx(classes.referenceImage, {
                        // [classes.portraitImageHeight]: screenOrientation === 'portrait',
                        [classes.landscapeImageHeight]:
                          screenOrientation === 'landscape',
                      })}
                    />
                  </>
                ) : (
                  <>
                    {engineData?.type === 'video' ? (
                      recordingFlag ? (
                        !videoURL ? (
                          <Typography
                            variant={'body2'}
                            className={classes.bottomInstructions}
                          >
                            Recording...
                          </Typography>
                        ) : (
                          <Typography
                            variant={'body2'}
                            className={classes.bottomInstructions}
                          >
                            Playing Recorded Video
                          </Typography>
                        )
                      ) : null
                    ) : !videoURL ? (
                      <Typography
                        variant={'body2'}
                        className={classes.bottomInstructions}
                      >
                        Captured Image
                      </Typography>
                    ) : null}
                    <img
                      ref={this.imageRef}
                      // key={uuid()}
                      src={profileData.awi_overlay_image_url}
                      onLoad={this.handleImageLoaded}
                      alt={`${profileData.awi_label} overlay`}
                      className={clsx(classes.overlayImage, {
                        // [classes.portraitImageHeight]: screenOrientation === 'portrait',
                        [classes.landscapeImageHeight]:
                          screenOrientation === 'landscape',
                      })}
                    />
                  </>
                )
              ) : null}

              {sectionSelected ? (
                selectedImageFlag ? (
                  <>
                    <Typography className={classes.labelText}>
                      Reference Image <br />
                      Section: {selectedSection.awi_label}
                    </Typography>
                    {/* <Typography className={classes.imageLabel}>
                      Reference Image
                    </Typography> */}
                    <Typography
                      variant={'body2'}
                      className={classes.bottomInstructions}
                    >
                      Please try to capture a view of the engine similar to the
                      one shown above.
                    </Typography>
                    <img
                      ref={this.imageRef}
                      // key={uuid()}
                      src={selectedSection.awi_reference_image_url}
                      onLoad={this.handleImageLoaded}
                      alt={`${selectedSection.awi_label} reference`}
                      className={clsx(classes.referenceImage, {
                        // [classes.portraitImageHeight]: screenOrientation === 'portrait',
                        [classes.landscapeImageHeight]:
                          screenOrientation === 'landscape',
                      })}
                    />
                  </>
                ) : (
                  <>
                    {engineData?.type === 'video' ? (
                      recordingFlag ? (
                        !videoURL ? (
                          <Typography
                            variant={'body2'}
                            className={classes.bottomInstructions}
                          >
                            Recording...
                          </Typography>
                        ) : (
                          <Typography
                            variant={'body2'}
                            className={classes.bottomInstructions}
                          >
                            Playing Recorded Video
                          </Typography>
                        )
                      ) : null
                    ) : !videoURL ? (
                      <Typography
                        variant={'body2'}
                        className={classes.bottomInstructions}
                      >
                        Captured Image
                      </Typography>
                    ) : null}
                    <img
                      ref={this.imageRef}
                      // key={uuid()}
                      src={selectedSection.awi_overlay_image_url}
                      onLoad={this.handleImageLoaded}
                      alt={`${selectedSection.awi_label} overlay`}
                      className={clsx(classes.overlayImage, {
                        // [classes.portraitImageHeight]: screenOrientation === 'portrait',
                        [classes.landscapeImageHeight]:
                          screenOrientation === 'landscape',
                      })}
                    />
                  </>
                )
              ) : null}

              {subSectionSelected ? (
                selectedImageFlag ? (
                  <>
                    <Typography variant={'body2'} className={classes.labelText}>
                      Reference Image <br />
                      Sub-Section: {selectedSubSection.awi_label}
                    </Typography>
                    {/* <Typography className={classes.imageLabel}>
                      Reference Image
                    </Typography> */}
                    <Typography
                      variant={'body2'}
                      className={classes.bottomInstructions}
                    >
                      Please focus your camera on the area highlighted in green
                    </Typography>
                    <img
                      ref={this.imageRef}
                      // key={uuid()}
                      src={selectedSubSection.awi_reference_image_url}
                      onLoad={this.handleImageLoaded}
                      alt={`${selectedSubSection.awi_label} reference`}
                      className={clsx(classes.referenceImage, {
                        // [classes.portraitImageHeight]: screenOrientation === 'portrait',
                        [classes.landscapeImageHeight]:
                          screenOrientation === 'landscape',
                      })}
                    />
                  </>
                ) : (
                  <>
                    {engineData?.type === 'video' ? (
                      recordingFlag ? (
                        !videoURL ? (
                          <Typography
                            variant={'body2'}
                            className={classes.bottomInstructions}
                          >
                            Recording...&nbsp;&nbsp;&nbsp;
                          </Typography>
                        ) : (
                          <Typography
                            variant={'body2'}
                            className={classes.bottomInstructions}
                          >
                            Playing Recorded Video
                          </Typography>
                        )
                      ) : null
                    ) : !videoURL ? (
                      <Typography
                        variant={'body2'}
                        className={classes.bottomInstructions}
                      >
                        Captured Image
                      </Typography>
                    ) : null}
                    <img
                      ref={this.imageRef}
                      // key={uuid()}
                      src={selectedSubSection.awi_overlay_image_url}
                      onLoad={this.handleImageLoaded}
                      alt={`${selectedSubSection.awi_label} overlay`}
                      className={clsx(classes.overlayImage, {
                        // [classes.portraitImageHeight]: screenOrientation === 'portrait',
                        [classes.landscapeImageHeight]:
                          screenOrientation === 'landscape',
                      })}
                    />
                  </>
                )
              ) : null}
            </div>

            <div className={classes.videoContainer}>
              {/* {false ? ( */}
              {false ? (
                // {engineData?.type === 'video' && !this.isIos() ? (
                <div>
                  <ReactMediaRecorder
                    video={MediaTrackConstraints}
                    audio={false}
                    mediaRecorderOptions={{
                      audioBitsPerSecond: 0,
                      videoBitsPerSecond: 25000000,
                      // mimeType: 'video/mp4',
                    }}
                    render={this.videoPreview}
                  />
                </div>
              ) : (
                <div>{this.videoPreview()}</div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Typography variant={'h4'} align="center">
              Please rotate your phone to landscape mode
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DataCollectionPage);
