//notification type
let TypeEnum = {
  REJECTION: 'reject',
  APPROVAL: 'approve',
  COMMENT: 'comment',
  CANCELLATION: 'cancellation',
  NEW_ENGINE: 'new_engine',
};

export default TypeEnum;
