import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  convertToTitleCase,
  convertUnderscoreCaseToTitleCase,
} from '../../util/CommonUtils';

import EngineService from '../../services/EngineService';
import PortionTypeEnum from '../../models/PortionTypeEnum';
import ProfileList from './ProfileList/ProfileList';
import UploadOutlinesPageStyles from './UploadOutlinesPageStyles';
import { isMobile } from 'mobile-device-detect';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

// import SwitchBase from '@material-ui/core/internal/SwitchBase';

let styles = UploadOutlinesPageStyles;

class UploadOutlinesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addProfileViewFlag: true,
      staticData: [],

      portionType: '',
      portionLabel: '',
      parentID: '',
      rootParentID: '',
      // addProfileViewFlag: false,
    };
  }

  componentDidMount = () => {
    this.handleFetchStaticDefinedData();
  };

  componentDidUpdate = (prevProps, prevState) => {
    let { portionType, portionLabel } = this.state;
    if (prevState.portionType !== portionType) {
      this.setState({
        portionLabel: '',
        parentID: '',
        rootParentID: '',
      });
    }

    if (prevState.portionLabel !== portionLabel) {
      this.setState({
        parentID: '',
        rootParentID: '',
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleAddPredefinedData();
  };

  handleFetchStaticDefinedData = async () => {
    let data = {};
    let response = await EngineService.listOfStaticDefinedData(data);
    console.log('handleFetchStaticDefinedData', response);
    if (response.success) {
      this.setState({ staticData: response.data });
    }
  };

  handleAddPredefinedData = async (e) => {
    // console.log('called');
    let {
      outlineImage,
      referenceImage,
      portionLabel,
      portionType,
      // parentID,
      // rootParentID,
    } = this.state;

    // console.log(file);

    if (outlineImage && referenceImage) {
      let awi_label = '';
      let parentID;
      let rootParentID;
      
      if (portionLabel?.type === PortionTypeEnum.PROFILE) {
        awi_label = portionLabel.profileData.awi_name;
      } else if (portionLabel?.type === PortionTypeEnum.SECTION) {
        awi_label = portionLabel.profileData.sectionData.awi_name;
        parentID = portionLabel.profileData.awi_name;
      } else if (portionLabel?.type === PortionTypeEnum.SUB_SECTION) {
        awi_label =
          portionLabel.profileData.sectionData.subSectionData.awi_name;
        parentID = portionLabel.profileData.sectionData.awi_name;
        rootParentID = portionLabel.profileData.awi_name;
      }

      let formData = new FormData();
      formData.append('awi_label', awi_label);
      formData.append('type', portionType);
      formData.append('awi_parent_id', parentID);
      formData.append('awi_root_parent_id', rootParentID);

      if (!outlineImage.name) {
        formData.append('file', outlineImage, 'outline-image.jpeg');
      } else {
        formData.append('file', outlineImage);
      }

      if (!referenceImage.name) {
        formData.append(
          'reference_file',
          referenceImage,
          'reference-image.jpeg'
        );
      } else {
        formData.append('reference_file', referenceImage);
      }

      for (var pair of formData.entries()) {
        console.log('formData', pair[0] + ', ' + pair[1]);
      }

      let response = await EngineService.addPredefinedData(formData);
      if (response.success) {
        console.log('addPredefinedData', response);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(`Please upload the images to proceed`);
    }
  };

  handleOutlineFileChange = (e) => {
    if (e.target.files.length) {
      this.setState({ outlineImage: e.target.files[0] });
    }
  };

  handleReferenceFileChange = (e) => {
    if (e.target.files.length) {
      this.setState({ referenceImage: e.target.files[0] });
    }
  };

  handleChange = (event) => {
    console.log(event.target);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeView = (event) => {
    let { addProfileViewFlag } = this.state;
    this.setState((prevState) => ({
      addProfileViewFlag: !prevState.addProfileViewFlag,
    }));
  };

  labelSelectOptions = () => {
    let {
      outlineImage,
      referenceImage,
      portionLabel,
      portionType,
      parentID,
      rootParentID,
      staticData,
      addProfileViewFlag,
    } = this.state;

    console.log('staticData', staticData);

    if (portionType === PortionTypeEnum.PROFILE) {
      return staticData.map((profileData, index) => {
        return (
          <MenuItem
            key={index}
            value={{
              type: PortionTypeEnum.PROFILE,
              profileData: {
                awi_name: profileData.awi_name,
              },
            }}
          >
            {convertToTitleCase(profileData.awi_name)}
          </MenuItem>
        );
      });
    } else if (portionType === PortionTypeEnum.SECTION) {
      return staticData.map((profileData, index) => {
        return [
          <ListSubheader value="" style={{ backgroundColor: 'white' }}>
            {convertToTitleCase(profileData.awi_name)}
          </ListSubheader>,
          profileData.list_of_sections.map((sectionData, index) => {
            return (
              <MenuItem
                key={index}
                value={{
                  type: PortionTypeEnum.SECTION,
                  profileData: {
                    awi_name: profileData.awi_name,
                    sectionData: {
                      awi_name: sectionData.awi_name,
                    },
                  },
                }}
              >
                {convertToTitleCase(sectionData.awi_name)}
              </MenuItem>
            );
          }),
        ];
      });
    } else if (portionType === PortionTypeEnum.SUB_SECTION) {
      return staticData.map((profileData, index) => {
        return profileData.list_of_sections.map((sectionData, index) => {
          return [
            <ListSubheader value="" style={{ backgroundColor: 'white' }}>
              {convertToTitleCase(profileData.awi_name)} -&nbsp;
              {convertToTitleCase(sectionData.awi_name)}
            </ListSubheader>,
            sectionData.list_of_sub_sections.map((subSectionData, index) => {
              return [
                <MenuItem
                  key={index}
                  // value={{
                  //   label: subSectionData.awi_name,
                  //   type: PortionTypeEnum.SUB_SECTION,
                  //   profileLabel: profileData.awi_name,
                  //   sectionLabel: sectionData.awi_name,
                  // }}
                  value={{
                    type: PortionTypeEnum.SUB_SECTION,
                    profileData: {
                      awi_name: profileData.awi_name,
                      sectionData: {
                        awi_name: sectionData.awi_name,
                        subSectionData: {
                          awi_name: subSectionData.awi_name,
                        },
                      },
                    },
                  }}
                >
                  {convertToTitleCase(subSectionData.awi_name)}
                </MenuItem>,
              ];
            }),
          ];
        });
      });
    }
  };

  render() {
    let { classes } = this.props;
    let {
      outlineImage,
      referenceImage,
      portionLabel,
      portionType,
      parentID,
      rootParentID,
      addProfileViewFlag,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.headingContainer}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                UPLOAD OUTLINES
              </Typography>
              <Button variant="contained" onClick={this.handleChangeView}>
                {!addProfileViewFlag ? 'Add New Entity' : 'View Entity List'}
              </Button>
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={addProfileViewFlag}
                    onChange={this.handleChange}
                    name="addProfileViewFlag"
                    color="primary"
                  />
                }
                label=""
              /> */}
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                Upload Outlines
              </Typography>
              <Button variant="contained" onClick={this.handleChangeView}>
                {!addProfileViewFlag ? 'Add New Entity' : 'View Entity List'}
              </Button>
            </div>
          )}
        </div>
        <div className={classes.pageContainer}>
          {addProfileViewFlag ? (
            <Grid item style={{ maxWidth: '100%' }}>
              <form onSubmit={this.handleSubmit}>
                <Typography variant="body1">Upload Outline Image</Typography>
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid item>
                    <input
                      multiple={false}
                      accept={'image/jpeg,image/png'}
                      // accept={
                      //   uploadOption === 'image'
                      //     ? 'image/jpeg,image/png'
                      //     : 'video/*'
                      // }
                      className={classes.input}
                      style={{ display: 'none' }}
                      id="raised-button-outlineImage"
                      type="file"
                      onChange={this.handleOutlineFileChange}
                    />
                    <label htmlFor="raised-button-outlineImage">
                      <Button
                        variant="contained"
                        component="span"
                        className={classes.button}
                      >
                        Choose File
                      </Button>
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    // className={classes.fileNameTextContainer}
                  >
                    <Typography noWrap>{outlineImage?.name}</Typography>
                  </Grid>
                </Grid>

                <br />
                <Typography variant="body1">Upload Reference Image</Typography>
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid item>
                    <input
                      multiple={false}
                      accept={'image/jpeg,image/png'}
                      // accept={
                      //   uploadOption === 'image'
                      //     ? 'image/jpeg,image/png'
                      //     : 'video/*'
                      // }
                      className={classes.input}
                      style={{ display: 'none' }}
                      id="raised-button-referenceImage"
                      type="file"
                      onChange={this.handleReferenceFileChange}
                    />
                    <label htmlFor="raised-button-referenceImage">
                      <Button
                        variant="contained"
                        component="span"
                        className={classes.button}
                      >
                        Choose File
                      </Button>
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs
                    zeroMinWidth
                    // className={classes.fileNameTextContainer}
                  >
                    <Typography noWrap>{referenceImage?.name}</Typography>
                  </Grid>
                </Grid>

                <br />

                <FormControl
                  required
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="portionType-label">Type</InputLabel>
                  <Select
                    labelId="portionType-label"
                    label="Type"
                    id="portionType"
                    value={portionType}
                    name={'portionType'}
                    onChange={this.handleChange}
                  >
                    <MenuItem value={''} disabled>
                      <em>Select Portion Type</em>
                    </MenuItem>
                    {Object.keys(PortionTypeEnum).map(
                      (portionTypeKey, index) => {
                        return (
                          <MenuItem
                            key={PortionTypeEnum[portionTypeKey]}
                            value={PortionTypeEnum[portionTypeKey]}
                          >
                            {convertUnderscoreCaseToTitleCase(
                              PortionTypeEnum[portionTypeKey]
                            )}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>

                {portionType ? (
                  <>
                    <FormControl
                      required
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="grouped-select">Label</InputLabel>
                      <Select
                        label="Label"
                        id="grouped-select"
                        value={portionLabel}
                        renderValue={(selected) => {
                          // let labelValue = '';
                          console.log(selected, 'selected');
                          if (selected?.type === PortionTypeEnum.PROFILE) {
                            return convertToTitleCase(
                              portionLabel.profileData.awi_name
                            );
                          } else if (
                            selected?.type === PortionTypeEnum.SECTION
                          ) {
                            return convertToTitleCase(
                              portionLabel.profileData.sectionData.awi_name
                            );
                          } else if (
                            selected?.type === PortionTypeEnum.SUB_SECTION
                          ) {
                            return convertToTitleCase(
                              portionLabel.profileData.sectionData
                                .subSectionData.awi_name
                            );
                          }

                          return '';
                        }}
                        name={'portionLabel'}
                        onChange={this.handleChange}
                      >
                        <MenuItem value={''} disabled>
                          <em>None</em>
                        </MenuItem>
                        {this.labelSelectOptions()}
                      </Select>
                    </FormControl>

                    {/* <TextField
                      required
                      // autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Label"
                      id="portionLabel"
                      name="portionLabel"
                      value={portionLabel}
                      onChange={this.handleChange}
                    /> */}

                    {/* <TextField
                      required
                      // autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Parent ID"
                      id="parentID"
                      name="parentID"
                      value={parentID}
                      onChange={this.handleChange}
                    />

                    <TextField
                      required
                      // autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Root Parent ID"
                      id="rootParentID"
                      name="rootParentID"
                      value={rootParentID}
                      onChange={this.handleChange}
                    /> */}
                    <br />
                    <br />
                    <br />
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.submitButton}
                    >
                      Add Outline Data
                    </Button>
                  </>
                ) : null}
              </form>
            </Grid>
          ) : (
            <div>
              <ProfileList />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UploadOutlinesPage);
