import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SubSectionListStyles from './SubSectionListStyles';
import {
  Button,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Fragment } from 'react';
import EngineService from '../../../../../services/EngineService';
import { capitalizeFirstLetter } from '../../../../../util/CommonUtils';

let styles = SubSectionListStyles;

class SubSectionList extends Component {
  static defaultProps = {
    subSectionList: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      subSectionList: [],
    };
  }

  handleModifySubSection = (e) => {
    e.stopPropagation();
    console.log('handleModifySubSection');
  };

  handleDeleteSubSection = (e) => {
    e.stopPropagation();
    console.log('handleDeleteSubSection');
  };

  render() {
    let { subSectionList, classes } = this.props;
    let { expandedSubSection } = this.state;
    return (
      <div className={classes.root}>
        {subSectionList.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Outline Image</TableCell>
                  <TableCell align="center">Reference Image</TableCell>
                  <TableCell align="center">Label</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subSectionList.map((subsectionData, index) => (
                  <Fragment key={subsectionData.id}>
                    <TableRow
                      // hover
                      className={classes.subsectionSummaryContainer}
                      aria-label="subsection-summary"
                    >
                      <TableCell align="center">
                        <img
                          src={subsectionData.awi_overlay_image_url}
                          alt={subsectionData.awi_overlay_image_id}
                          className={classes.outlineImage}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={subsectionData.awi_reference_image_url}
                          alt={subsectionData.awi_reference_image_id}
                          className={classes.referenceImage}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {capitalizeFirstLetter(subsectionData.awi_label)}
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.handleModifySubSection}
                              aria-label="modify-subsection"
                            >
                              Modify
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={this.handleDeleteSubSection}
                              aria-label="delete-subsection"
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>

                    {/* <TableRow className={classes.root}>
                    <TableCell colSpan={4}>
                      <Collapse
                        in={expandedSubSection === subsectionData.awi_label}
                        timeout="auto"
                        unmountOnExit
                      >
                        <SectionList
                          sectionList={subsectionData.list_of_sections}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow> */}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">
            No sub-sections uploaded for this section.
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SubSectionList);
