import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import { capitalizeFirstLetter, commonRoundOff } from '../../util/CommonUtils';

import CancelEngineConfirmationDialog from './CancelEngineConfirmationDialog/CancelEngineConfirmationDialog';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EngineDetailsProfileList from './EngineDetailsProfileList/EngineDetailsProfileList';
import EngineDetailsStyles from './EngineDetailsStyles';
import EngineService from '../../services/EngineService';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import RolesEnum from '../../models/RolesEnum';
import RoutePath from '../../lib/RoutePath';
import VideoPlayerActions from '../../redux/actions/VideoPlayerActions';
import { connect } from 'react-redux';
import { isMobile } from 'mobile-device-detect';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = EngineDetailsStyles;

class EngineDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engineData: undefined,
      profileList: [],
      sectionList: {},
      subSectionList: {},
      // expandedProfile: 'right',
      expandedProfile: '',
      completionValue: 0,

      completedProfiles: 0,
      totalProfiles: 0,
      completedSections: 0,
      totalSections: 0,
      completedSubSections: 0,
      totalSubSections: 0,

      completionInfoOpenFlag: false,
      completionInfoAnchorEl: null,

      cancelEngineConfirmationOpenFlag: false,
    };
  }

  componentDidMount = () => {
    this.handleGetEngineStatusInHeirarchy();
    // this.handleGetPredefinedData();
    // this.handleGetEngineData();
  };

  handleGetEngineStatusInHeirarchy = async () => {
    let { engineID } = this.props.match.params;
    let data = {
      awi_engine_id: engineID * 1,
    };
    let response = await EngineService.getEngineStatusInHierarchy(data);
    console.log('handleGetEngineStatusInHeirarchy', response);
    if (response.success) {
      // let output = groupBy(response.data, 'type');
      // console.log(response.data, output);
      this.setState({
        engineData: response.data[0],
        profileList: response.data[0].awi_profiles,
      });
      // this.handleGetEngineStatus(output);
    }
  };

  goToPortionSelection = () => {
    let { engineID } = this.props.match.params;
    RoutePath.navigateTo(
      this.props,
      RoutePath.dataCollectionProfileSelectionPath(engineID)
    );
  };

  handleCompletionInfoOpen = (event) => {
    this.setState({
      completionInfoAnchorEl: event.currentTarget,
    });
  };

  handleCompletionInfoClose = () => {
    this.setState({
      completionInfoAnchorEl: null,
    });
  };

  handleCancelEngine = async (commentString) => {
    let { engineID } = this.props.match.params;
    let data = { awi_engine_ids: [engineID] };
    console.log(data);
    let response = await EngineService.cancelEngines(data);
    console.log(response);
    if (response.success) {
      toast.success(response.message);
      this.handleCancelEngineNotifications(commentString);
      RoutePath.navigateTo(this.props, RoutePath.engineList);
    } else {
      toast.error(response.message);
    }
  };

  handleCancelEngineNotifications = async (commentString) => {
    let { engineID } = this.props.match.params;
    let { engineData } = this.state;
    // let { commentString } = this.state;
    console.log('handleCancelEngineNotifications', engineData);
    let data = {
      awi_engine_id: engineID,
      awi_content_id: engineData.awi_content_id,
      text: commentString,
      type: 'cancellation',
      awi_content_type: 'engine',
      awi_content_label: 'cancelled',
    };
    console.log('handleApprovePortionNotifications', data);
    let response = await EngineService.addNewComment(data);
    console.log('handleApprovePortionNotifications', response);
    if (response.success) {
      this.props.onClose();
    }
  };

  handleCancelEngineConfirmationOpen = () => {
    this.setState({ cancelEngineConfirmationOpenFlag: true });
  };

  handleCancelEngineConfirmationClose = () => {
    this.setState({ cancelEngineConfirmationOpenFlag: false });
  };

  render() {
    let { classes, loggedUser } = this.props;

    let { engineID } = this.props.match.params;
    let {
      engineData,
      profileList,
      completionInfoAnchorEl,
      cancelEngineConfirmationOpenFlag,
    } = this.state;

    console.log('profileList', profileList);

    let completionInfoOpenFlag = Boolean(completionInfoAnchorEl);
    // console.log(
    //   'sectionData',
    //   profileList.length && sectionList[profileList[0].awi_label]
    // );
    // console.log(
    //   'sectionData',
    //   profileList.length && typeof sectionList[profileList[0].awi_label]
    // );
    return (
      <div className={classes.root}>
        <div className={classes.headingContainer}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                ENGINE DETAILS
              </Typography>

              <div
                className="basic_flex_row align_items_center"
                style={{ maxWidth: '60vw' }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleGetEngineStatusInHeirarchy}
                >
                  &nbsp;
                  <RefreshIcon /> Refresh&nbsp;&nbsp;
                </Button>
                &nbsp;&nbsp;&nbsp;
                {!(loggedUser.userObj?.level === RolesEnum.TECHNICIAN) ? (
                  <Button
                    startIcon={<ClearIcon />}
                    size="small"
                    variant="contained"
                    onClick={this.handleCancelEngineConfirmationOpen}
                    color="secondary"
                    noWrap
                  >
                    {/* <Typography variant="body2" noWrap> */}
                    Cancel Engine
                    {/* </Typography> */}
                  </Button>
                ) : null}
              </div>
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                Engine Details
              </Typography>

              <div>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleGetEngineStatusInHeirarchy}
                >
                  <RefreshIcon /> Refresh
                </Button>
                &nbsp;&nbsp;&nbsp;
                {!(loggedUser.userObj?.level === RolesEnum.TECHNICIAN) ? (
                  <Button
                    startIcon={<ClearIcon />}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={this.handleCancelEngineConfirmationOpen}
                  >
                    Cancel Engine
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className={classes.pageContainer}>
          {engineData ? (
            <div>
              <Grid container direction="column" spacing={2}>
                <Grid item container direction="row" justify={'space-between'}>
                  <Grid item xs={6} sm={4} md={1}>
                    <Typography
                      variant={'caption'}
                      // className={classes.boldText}
                    >
                      ESN:
                    </Typography>
                    <br />
                    <Typography
                      variant={'caption'}
                      className={classes.boldText}
                    >
                      {engineData.awi_serial_num}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={4} md={2}>
                    <Typography
                      variant={'caption'}
                      // className={classes.boldText}
                    >
                      Engine Name:
                    </Typography>
                    <br />
                    <Typography
                      variant={'caption'}
                      className={classes.boldText}
                    >
                      {engineData.awi_label}
                    </Typography>
                  </Grid>
                  {!(loggedUser.userObj?.level === RolesEnum.TECHNICIAN) ? (
                    <>
                      <Grid item xs={6} sm={4} md={2}>
                        <Typography
                          variant={'caption'}
                          // className={classes.boldText}
                        >
                          Technician Name:
                        </Typography>
                        <br />
                        <Typography
                          variant={'caption'}
                          className={classes.boldText}
                        >
                          {capitalizeFirstLetter(engineData.user.firstname)}
                          &nbsp;
                          {capitalizeFirstLetter(engineData.user.lastname)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} md={3}>
                        <Typography
                          variant={'caption'}
                          // className={classes.boldText}
                        >
                          Technician Email:
                        </Typography>
                        <br />
                        <Typography
                          variant={'caption'}
                          className={classes.boldText}
                        >
                          {engineData.user_email}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={6} sm={4} md={2}>
                    <Typography
                      variant={'caption'}
                      // className={classes.boldText}
                    >
                      Upload Date
                    </Typography>
                    <br />
                    <Typography
                      variant={'caption'}
                      className={classes.boldText}
                    >
                      {moment(engineData.ts_creation).format('lll')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Typography
                      variant={'caption'}
                      // className={classes.boldText}
                    >
                      Status
                    </Typography>
                    <br />
                    <Typography
                      variant={'caption'}
                      className={classes.boldText}
                    >
                      {capitalizeFirstLetter(engineData.approved_status)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container direction="row" justify={'flex-end'}>
                  <Grid item xs={12}>
                    <div>
                      <LinearProgress
                        variant="determinate"
                        value={engineData.metrics.overall.percent}
                        valueBuffer={88}
                        // valueBuffer={engineData.metrics.overall.percent}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    container
                    direction={'row'}
                    justify={'flex-end'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography
                        variant={'caption'}
                        className={classes.boldText}
                      >
                        {commonRoundOff(engineData.metrics.overall.percent, 2)}%
                        Completed
                      </Typography>
                    </Grid>

                    <Grid>
                      <IconButton
                        size={'small'}
                        onClick={this.handleCompletionInfoOpen}
                      >
                        <InfoIcon />
                      </IconButton>
                      <Popover
                        id={
                          completionInfoOpenFlag
                            ? 'completion-info-modal'
                            : undefined
                        }
                        open={completionInfoOpenFlag}
                        anchorEl={completionInfoAnchorEl}
                        onClose={this.handleCompletionInfoClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div className={classes.completionInfoModalContainer}>
                          <Typography variant={'caption'}>Profiles:</Typography>
                          <Typography
                            variant={'caption'}
                            className={classes.boldText}
                          >
                            {engineData.metrics.profiles.actual}/
                            {engineData.metrics.profiles.expected}
                          </Typography>
                          <br />
                          <Typography variant={'caption'}>Sections:</Typography>
                          <Typography
                            variant={'caption'}
                            className={classes.boldText}
                          >
                            {engineData.metrics.sections.actual}/
                            {engineData.metrics.sections.expected}
                          </Typography>
                          <br />
                          <Typography variant={'caption'}>
                            Sub-Sections:
                          </Typography>
                          <Typography
                            variant={'caption'}
                            className={classes.boldText}
                          >
                            {engineData.metrics.sub_sections.actual}/
                            {engineData.metrics.sub_sections.expected}
                          </Typography>
                        </div>
                      </Popover>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {loggedUser.userObj?.level === RolesEnum.TECHNICIAN ? (
                <div style={{ textAlign: 'center', padding: 24 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.resumeRecordingButton}
                    onClick={this.goToPortionSelection}
                  >
                    Resume Recording
                  </Button>
                </div>
              ) : null}

              {profileList.length ? (
                <div>
                  <EngineDetailsProfileList
                    handleGetEngineStatusInHeirarchy={
                      this.handleGetEngineStatusInHeirarchy
                    }
                    engineData={engineData}
                    profileList={profileList}
                  />
                </div>
              ) : (
                <Typography variant={'h6'} align="center">
                  No Profile data uploaded yet
                </Typography>
              )}
            </div>
          ) : (
            <div>Loading Engine Data</div>
          )}
        </div>

        <CancelEngineConfirmationDialog
          open={cancelEngineConfirmationOpenFlag}
          onClose={this.handleCancelEngineConfirmationClose}
          handleCancelEngine={this.handleCancelEngine}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedUser: state.UserReducer.loggedUser,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    // openVideoPlayer: (videoData) =>
    //   dispatch(VideoPlayerActions.openVideoPlayer(videoData)),
    // closeVideoPlayer: () => dispatch(VideoPlayerActions.closeVideoPlayer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EngineDetails));
