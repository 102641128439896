class APIPath {
  // static addUserPath = '/user';
  // static editUserPath = (id = ':id') => `/user/${id}`;

  static loginPath = '/awiros/v1/rtx/ext/auth/login';
  static signupPath = '/awiros/v1/rtx/ext/auth/registeration';
  static invitePath = '/awiros/v1/rtx/ext/auth/invite_user';
  // static logoutPath = '/api/logout';
  static saveFCMToken = '/api/save_fcm_token';
  static listOfUsers = '/awiros/v1/rtx/ext/auth/list_of_users';

  static addPredefinedData = '/awiros/v1/rtx/ext/pre/add_new';
  static removePredefinedData = '/awiros/v1/rtx/ext/pre/remove_existing';

  static uploadBulkImages = '/awiros/v1/rtx/ext/misc/upload_bulk_images';

  static addNewEngine = '/awiros/v1/rtx/ext/misc/add_new_engine';
  static assignEngineToTechnician = '/awiros/v1/rtx/ext/misc/assign_engine';
  static addNewProfile = '/awiros/v1/rtx/ext/misc/add_new_profile';
  static addNewSection = '/awiros/v1/rtx/ext/misc/add_new_section';
  static addNewSubSection = '/awiros/v1/rtx/ext/misc/add_new_sub_section';

  static removeEngines = '/awiros/v1/rtx/ext/misc/remove_engines';
  static cancelEngines = '/awiros/v1/rtx/ext/misc/cancel_engine';
  static approveEngines = 'awiros/v1/rtx/ext/misc/approve_engine';
  static rejectEngines = '/awiros/v1/rtx/ext/misc/reject_engine';

  static approvePortions = '/awiros/v1/rtx/ext/misc/approve_portion';
  static rejectPortions = '/awiros/v1/rtx/ext/misc/reject_portion';

  static addNewComment = '/awiros/v1/rtx/ext/comment/add_new';
  static updateCommentStatus = '/awiros/v1/rtx/ext/comment/update_comment_status';
  static fetchComments = '/awiros/v1/rtx/ext/comment/fetch_comments';
  static fetchAllSentComments = '/awiros/v1/rtx/ext/comment/all_sent_comments';
  static fetchAllRecievedComments =
    '/awiros/v1/rtx/ext/comment/all_received_comments';

  static getEngineStatus = '/awiros/v1/rtx/ext/misc/get_engine_status';
  static getEngineStatusInHierarchy =
    '/awiros/v1/rtx/ext/misc/get_engine_status_in_hierarchy';
  static listOfProfiles = '/awiros/v1/rtx/ext/misc/list_of_profiles';
  static listOfSections = '/awiros/v1/rtx/ext/misc/list_of_sections';
  static listOfSubSections = '/awiros/v1/rtx/ext/misc/list_of_sub_sections';

  static listOfEngines = '/awiros/v1/rtx/ext/misc/list_of_engines';
  static listOfStaticDefinedData =
    '/awiros/v1/rtx/ext/misc/list_of_static_defined_data';
  static preFetchEntries = '/awiros/v1/rtx/ext/pre/fetch_entries';
  static preFetchEntriesInHierarchy =
    '/awiros/v1/rtx/ext/pre/fetch_entries_in_hierarchy';
}

export default APIPath;
