import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SectionListStyles from './SectionListStyles';
import {
  Button,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EngineService from '../../../../services/EngineService';
import { capitalizeFirstLetter } from '../../../../util/CommonUtils';
import { Fragment } from 'react';
import SubSectionList from './SubSectionList/SubSectionList';

let styles = SectionListStyles;

class SectionList extends Component {
  static defaultProps = {
    sectionList: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      // sectionList: [],
    };
  }

  handleSectionExpanded = (sectionData) => (event) => {
    event.stopPropagation();
    let { expandedSection } = this.state;
    this.setState({
      expandedSection:
        expandedSection === sectionData.awi_label
          ? false
          : sectionData.awi_label,
    });
  };

  handleModifySection = (e) => {
    e.stopPropagation();
    console.log('handleModifySection');
  };

  handleDeleteSection = async (e) => {
    e.stopPropagation();
    let data = {

    }
    let response = await EngineService.
    console.log('handleDeleteSection');
  };

  render() {
    let { classes, sectionList } = this.props;
    let { expandedSection } = this.state;
    return (
      <div className={classes.root}>
        {sectionList.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Outline Image</TableCell>
                  <TableCell align="center">Reference Image</TableCell>
                  <TableCell align="center">Label</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sectionList.map((sectionData, index) => (
                  <Fragment key={sectionData.id}>
                    <TableRow
                      hover
                      className={classes.sectionSummaryContainer}
                      onClick={this.handleSectionExpanded(sectionData)}
                      aria-label="section-summary"
                    >
                      <TableCell align="center">
                        <img
                          src={sectionData.awi_overlay_image_url}
                          alt={sectionData.awi_overlay_image_id}
                          className={classes.outlineImage}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={sectionData.awi_reference_image_url}
                          alt={sectionData.awi_reference_image_id}
                          className={classes.referenceImage}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {capitalizeFirstLetter(sectionData.awi_label)}
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.handleModifySection}
                              aria-label="modify-section"
                            >
                              Modify
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={this.handleDeleteSection}
                              aria-label="delete-section"
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.root}>
                      <TableCell colSpan={4}>
                        <Collapse
                          in={expandedSection === sectionData.awi_label}
                          timeout="auto"
                          unmountOnExit
                        >
                          <SubSectionList
                            subSectionList={sectionData.list_of_sub_sections}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">
            No sections uploaded for this profile.
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SectionList);
