let AccountPageStyles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
  },
  mobileHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1, 2),
    color: 'white',
    backgroundColor: '#204e79',
  },
  headingContainer: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    //   color: 'white',
    //   backgroundColor: '#204e79',
  },
  pageContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
  },
});

export default AccountPageStyles;
