let VideoPlayerContainerStyles = (theme) => ({
  root: {},
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    width: '100%',
    padding: 10,
    maxHeight: '95vh',
    outline: 0,
    [`@media(min-width:${theme.breakpoints.values.lg}px)`]: {
      maxWidth: 800,
    },
  },
  modalPaper: {
    position: 'relative',
    margin: theme.spacing(3, 1),
    padding: theme.spacing(3, 3),
    paddingTop: theme.spacing(6),
    maxHeight: '85vh',
  },
  closeButtonContainer: {
    // position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  uploadOptionSelector: {},
  fileNameTextContainer: {
    // maxWidth:'50%'
  },
  submitButton: {
    marginTop: 15,
  },
  videoContainer: {
    width: '100%',
    overflow: 'auto',
    maxHeight: '75vh',
    position: 'relative',
  },
  outlineImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  unableToOpenVideoOnIOSError: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: '1px solid black',
  },
  video: {
    width: '100%',
    '&::-webkit-media-controls-volume-slider-container': {
      display: 'none',
    },
    '&::-webkit-media-controls-volume-slider': {
      display: 'none',
    },
    '&::-webkit-media-controls-mute-button': {
      display: 'none',
    },
  },
});

export default VideoPlayerContainerStyles;
