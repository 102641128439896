import {
  Badge,
  Divider,
  Icon,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';

import EngineService from '../../../services/EngineService';
import SendIcon from '@material-ui/icons/Send';
import SmallCommentListStyles from './SmallCommentListStyles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { capitalizeFirstLetter } from '../../../util/CommonUtils';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import { v4 as uuid } from 'uuid';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = SmallCommentListStyles;

class SmallCommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsData: [],
      commentsList: [],
      addedComment: '',
    };
    this.commentListRoot = React.createRef();
  }

  componentDidMount = () => {
    this.handleFetchConversation(this.handleMarkAllAsRead);
  };
  
  handleFetchConversation = async (callback) => {
    let { portionData } = this.props;
    console.log('awi_content_id', portionData.awi_content_id);
    console.log('portionData', portionData);
    let data = {
      awi_content_id: portionData.awi_content_id,
    };
    let response = await EngineService.fetchComments(data);
    console.log('fetchComments', response);
    if (response.success) {
      this.setState({
        commentsData: response.data,
        commentsList: response.data.length
          ? response.data[0].comments.reverse()
          : [],
      });
      callback && callback();
      // toast.success(response.message);
    } else {
      this.setState({ commentsData: [], commentsList: [] });
      // toast.error(response.message);
    }
  };

  handleAddComment = async (e) => {
    e.preventDefault();
    let { portionData } = this.props;
    let { commentsData, addedComment } = this.state;
    console.log('portionData', portionData);
    console.log(addedComment);
    let parentLabel = undefined;
    let rootParentLabel = undefined;
    if (portionData.sectionData) {
      parentLabel = portionData.sectionData.awi_label;
      rootParentLabel = portionData.profileData.awi_label;
    } else if (portionData.profileData) {
      parentLabel = portionData.profileData.awi_label;
    }
    let data = {
      awi_engine_id: portionData.awi_engine_id,
      awi_content_id: portionData.awi_content_id,
      text: addedComment,
      type: 'comment',
      awi_content_type: portionData.awi_type,
      awi_content_label: portionData.awi_label,
      awi_parent_label: parentLabel,
      awi_root_parent_label: rootParentLabel,
    };
    console.log(data);

    let response = await EngineService.addNewComment(data);
    if (response.success) {
      this.setState({ addedComment: '' });
      this.handleFetchConversation();
      toast.success(response.message);
      // window.comsa= this.commentListRoot.current
      setTimeout(this.commentListRoot.current.scrollIntoView, 2000);
    } else {
      toast.error(response.message);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleMarkAllAsRead = async () => {
    let { loggedUser } = this.props;
    let { commentsList } = this.state;

    let temp_awi_comment_ids = commentsList.map((commentData, index) => {
      if (loggedUser.userObj.email !== commentData.sender_email) {
        return commentData.awi_comment_id;
      }
    });
    let awi_comment_ids = temp_awi_comment_ids.filter((a) => !!a);
    let data = { awi_comment_ids };
    let response = await EngineService.updateCommentStatus(data);
    console.log('updateCommentStatus', data, response);
    if (response.success) {
      // toast.success(response.message);
      this.handleFetchConversation();
    }
  };

  render() {
    let { classes, portionData, open, loggedUser } = this.props;
    let { commentsData, commentsList, addedComment } = this.state;
    console.log('loggedUser', loggedUser);
    return (
      <div ref={this.commentListRoot} className={classes.root}>
        <Paper
          component="form"
          onSubmit={this.handleAddComment}
          className={classes.messageInputRoot}
        >
          <InputBase
            className={classes.messageInput}
            placeholder="Add a comment..."
            inputProps={{ required: true, 'aria-label': 'add a comment' }}
            name={'addedComment'}
            value={addedComment}
            onChange={this.handleChange}
          />
          <Divider
            className={classes.messageInputDivider}
            orientation="vertical"
          />
          <IconButton
            color="primary"
            className={classes.sendButton}
            aria-label="send"
            type="submit"
          >
            <SendIcon />
          </IconButton>
        </Paper>
        <Timeline align="left">
          {commentsList.length ? (
            commentsList.map((comment, index) => {
              let commentText = comment.text;
              if (!commentText) {
                if (comment.type === 'approve') {
                  commentText = `${comment.sender.firstname} approved this ${comment.awi_content_type}`;
                } else if (comment.type === 'reject') {
                  commentText = `${comment.sender.firstname} approved this ${comment.awi_content_type}`;
                }
              }
              return (
                <TimelineItem
                  key={uuid()}
                  classes={{
                    missingOppositeContent: classes.missingOppositeContent,
                  }}
                >
                  <TimelineOppositeContent
                    classes={{ root: classes.timelineOppositeContent }}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {moment(comment.ts_creation).format('LT')}
                    </Typography>
                    <br />
                    <Typography variant="caption" color="textSecondary">
                      {moment(comment.ts_creation).format('l')}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      {/* <LaptopMacIcon /> */}
                      <Icon>
                        <Typography align="center">
                          {comment.sender.firstname.charAt(0).toUpperCase()}
                          {comment.sender.lastname.charAt(0).toUpperCase()}
                        </Typography>
                      </Icon>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Badge
                      invisible={comment.is_seen}
                      variant="dot"
                      color="error"
                      style={{ width: '100%' }}
                    >
                      <Paper elevation={3} className={classes.messageContainer}>
                        <Typography
                          variant="body2"
                          component="span"
                          className={classes.messageSenderName}
                        >
                          {capitalizeFirstLetter(comment.sender.firstname)}
                          &nbsp;
                          {capitalizeFirstLetter(comment.sender.lastname)}
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className={classes.messageSenderDesignation}
                        >
                          &nbsp;({comment.sender_title})
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.messageText}
                        >
                          {comment.text}
                        </Typography>
                      </Paper>
                    </Badge>
                  </TimelineContent>
                </TimelineItem>
              );
            })
          ) : (
            <Typography align="center" variant="h6">
              No Comments added yet...
            </Typography>
          )}
        </Timeline>
      </div>
    );
  }
}
let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedUser: state.UserReducer.loggedUser,
    // loggedIn: state.loggedIn
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    // loginUser: (data) => dispatch(UserActions.loginUser(data)),
    // setDarkMode: (value) => dispatch(SettingsActions.setDarkMode(value)),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SmallCommentList));
