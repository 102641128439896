import React, { Component } from 'react';

import AccountPage from '../AccountPage/AccountPage';
import AppContainerStyles from './AppContainerStyles';
import { BrowserRouter } from 'react-router-dom';
import DataCollectionPage from '../DataCollectionFlow/DataCollectionPage/DataCollectionPage';
import EngineDetails from '../EngineDetails/EngineDetails';
import EngineList from '../EngineList/EngineList';
import InvitePage from '../InvitePage/InvitePage';
import Navbar from '../Navbar/Navbar';
import NotificationsPage from '../NotificationsPage/NotificationsPage';
import NotificationsPageStyles from '../NotificationsPage/NotificationsPageStyles';
import { Portal } from '@material-ui/core';
import PortionSelectionPage from '../PortionSelectionPage/PortionSelectionPage';
import PrivateRoute from '../../util/PrivateRoute';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import RoutePath from '../../lib/RoutePath';
import SettingsActions from '../../redux/actions/SettingsActions';
import { Switch } from 'react-router-dom';
import TechnicianDetails from '../TechnicianDetails/TechnicianDetails';
import TechnicianList from '../TechnicianList/TechnicianList';
import UploadOutlinesPage from '../UploadOutlinesPage/UploadOutlinesPage';
import UserActions from '../../redux/actions/UserActions';
import VideoPlayerActions from '../../redux/actions/VideoPlayerActions';
import VideoPlayerContainer from '../CommonComponents/VideoPlayerContainer/VideoPlayerContainer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = AppContainerStyles;

class AppContainer extends Component {
  handleVideoPlayerClose = () => {
    this.props.closeVideoPlayer();
  };
  render() {
    let {
      classes,
      playerVisible,
      playerURL,
      controlsVisible,
      loopVideo,
      autoPlay,
      videoPlayerType,
    } = this.props;
    console.log('videoPlayerType',videoPlayerType)
    return (
      <div className={classes.root}>
        {/* <BrowserRouter> */}
        <Navbar>
          <Switch>
            <PrivateRoute
              exact
              path={RoutePath.engineList}
              authorized={this.props.loggedIn}
              component={EngineList}
            />
            <PrivateRoute
              exact
              path={RoutePath.accountPagePath}
              authorized={this.props.loggedIn}
              component={AccountPage}
            />

            {/* <Redirect
              exact
              from={RoutePath.dataCollectionRoute}
              to={RoutePath.engineList}
            /> */}
            {/* <PrivateRoute
              exact
              path={RoutePath.dataCollectionProfileSelectionPath()}
              authorized={this.props.loggedIn}
              component={ProfileSelectionPage}
            /> */}
            <PrivateRoute
              exact
              path={RoutePath.dataCollectionProfileSelectionPath()}
              authorized={this.props.loggedIn}
              component={PortionSelectionPage}
            />

            <PrivateRoute
              exact
              path={RoutePath.engineDetails()}
              authorized={this.props.loggedIn}
              component={EngineDetails}
            />

            <PrivateRoute
              exact
              path={RoutePath.technicianListPath}
              authorized={this.props.loggedIn}
              component={TechnicianList}
            />

            <PrivateRoute
              exact
              path={RoutePath.technicianDetailsPath()}
              authorized={this.props.loggedIn}
              component={TechnicianDetails}
            />

            <PrivateRoute
              exact
              path={RoutePath.invitePage}
              authorized={this.props.loggedIn}
              component={InvitePage}
            />

            <PrivateRoute
              exact
              path={RoutePath.uploadOutlinesPath}
              authorized={this.props.loggedIn}
              component={UploadOutlinesPage}
            />
 
            <PrivateRoute
              exact
              path={RoutePath.notificationPath}
              authorized={this.props.loggedIn}
              component={NotificationsPage}
            />
            <Redirect to={RoutePath.engineList} />
          </Switch>
        </Navbar>
        {/* <VideoPlayerContainer
          open={playerVisible}
          onClose={this.handleVideoPlayerClose}
          playerURL={playerURL}
          controlsVisible={controlsVisible}
          loopVideo={loopVideo}
          autoPlay={autoPlay}
          type={videoPlayerType}
        /> */}
        {/* </BrowserRouter> */}
      </div>
    );
  }
}
let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedIn: state.UserReducer.loggedIn,
    loggedUser: state.UserReducer.loggedUser,
    isDarkMode: state.SettingsReducer.isDarkMode,
    
    playerVisible: state.VideoPlayerReducer.playerVisible,
    playerURL: state.VideoPlayerReducer.playerURL,
    controlsVisible: state.VideoPlayerReducer.controlsVisible,
    loopVideo: state.VideoPlayerReducer.loopVideo,
    autoPlay: state.VideoPlayerReducer.autoPlay,
    videoPlayerType: state.VideoPlayerReducer.type,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (data) => dispatch(UserActions.loginUser(data)),
    setDarkMode: (value) => dispatch(SettingsActions.setDarkMode(value)),
    openVideoPlayer: (videoData) =>
      dispatch(VideoPlayerActions.openVideoPlayer(videoData)),
    closeVideoPlayer: () => dispatch(VideoPlayerActions.closeVideoPlayer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AppContainer)));
