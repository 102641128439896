let SignupContainerStyles = (theme) => ({
  root: {
    // margin: theme.spacing(0),
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoImage: {
    width: 140,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  poweredByAwirosLogo: {
    width: 180,
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
});

export default SignupContainerStyles;
