import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core';
import { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';

import AlbumIcon from '@material-ui/icons/Album';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EngineService from './../../../../../services/EngineService';
import EventEmitter from '../../../../../util/EventEmitter';
import { Globals } from '../../../../../lib/Constants';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import MediaTrackConstraints from '../MediaTrackConstraints';
import PauseCircleFilledTwoToneIcon from '@material-ui/icons/PauseCircleFilledTwoTone';
import PlayCircleFilledTwoToneIcon from '@material-ui/icons/PlayCircleFilledTwoTone';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import React from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import RecordViewStyles from './RecordViewStyles';
import RoutePath from '../../../../../lib/RoutePath';
import { toast } from 'material-react-toastify';
import { useReactMediaRecorder } from 'react-media-recorder';
import { withStyles } from '@material-ui/core/styles';

let styles = RecordViewStyles;

const RecordView = (props) => {
  let {
    classes,
    screenOrientation,
    // engineID,
    engineData,
    selectedImage,
    handleImageChange,
    profileData,
    history,
    match,
    handleMediaBlobURLChanged,
  } = props;

  if (!handleMediaBlobURLChanged) {
    handleMediaBlobURLChanged = () => undefined;
  }

  let {
    status,
    startRecording,
    stopRecording,
    clearBlobUrl,
    mediaBlobUrl,
  } = useReactMediaRecorder({ video: MediaTrackConstraints, audio: false });

  let isIos = () => {
    return navigator.platform.toLowerCase() === 'iphone';
  };

  let [isUploading, setIsUploading] = useState(false);

  // if (engineData.type !== 'video' || isIos()) {
    mediaBlobUrl = props.mediaBlobUrl;
  // }

  // console.log('params', match.params);
  let { engineID, profileID, sectionID, subSectionID } = match.params;

  let handleSetMediaBlobURL = () => {
    handleMediaBlobURLChanged(mediaBlobUrl);
  };

  let handleFileChange = (e) => {
    if (e.target.files.length) {
      handleMediaBlobURLChanged(URL.createObjectURL(e.target.files[0]));
      // this.setState({ image: e.target.files[0] });
    }
  };

  useEffect(() => {
    handleSetMediaBlobURL();
  }, [mediaBlobUrl]);

  useLayoutEffect(() => {
    handleGetListOfEntries();
  }, []);

  // let handleClearRecordedVideo = () => {
  //   // handleMediaBlobURLChanged('');
  //   clearBlobUrl();
  // };
  let handleGetListOfEntries = async (data = {}) => {
    // let data = { type: 'section' };
    let response = await EngineService.preFetchEntries(data);
    if (response.success) {
      console.log(response);
      return response.data;
      // this.setState({ profileList: response.data });
    }
  };

  // console.log(screenOrientation);

  let rootClass = classes.hiddenRoot;
  if (screenOrientation === 'portrait') {
    rootClass = classes.portraitRoot;
  } else if (screenOrientation === 'landscape') {
    rootClass = classes.landscapeRoot;
  }

  let handleAddProfile = async (e) => {
    // e.preventDefault();
    // let { engineID, profileID } = match.params;
    if (mediaBlobUrl) {
      setIsUploading(true);
      let formData = new FormData();
      formData.append('awi_label', profileData.awi_label);
      formData.append('awi_engine_id', engineID);
      formData.append('awi_overlay_image_id', profileData.awi_overlay_image_id);

      // console.log(RoutePath.dataReCollectionProfileUploadPath());
      // console.log(match.path);
      if (match.path === RoutePath.dataReCollectionProfileUploadPath()) {
        console.log('profile recollected');
        formData.append('update_status', true);
      }

      // let { image: file } = this.state;
      let file;
      if (engineData.type === 'video') {
        file = await fetch(mediaBlobUrl).then((r) => r.blob());
      } else {
        file = mediaBlobUrl;
      }
      console.log('file', file);
      if (!file.name) {
        formData.append('file', file, 'engine-image');
      } else {
        formData.append('file', file);
      }

      // for (var pair of formData.entries()) {
      //   console.log('formData', pair[0] + ', ' + pair[1]);
      // }

      // formData.append('file', this.state.image);
      let response = await EngineService.addNewProfile(formData);
      if (response.success) {
        // EventEmitter.emit('newProfileAdded');
        console.log('addNewProfile', response);
        toast.success(response.message);
      } else {
        setIsUploading(false);
        toast.error(response.message);
      }

      if (match.path === RoutePath.dataReCollectionProfileUploadPath()) {
        RoutePath.navigateTo(
          { history, match },
          RoutePath.engineDetails(engineID)
        );
      } else {
        RoutePath.navigateTo(
          { history, match },
          RoutePath.dataCollectionProfileSelectionPath(engineID)
        );
      }
    } else {
      toast.error('Please Record the video/photo first!');
    }
  };

  let handleGetUploadedProfileData = async () => {
    // let { engineID } = match.params;
    setIsUploading(true);
    let data = {
      awi_engine_ids: [engineID * 1], //ARRAY OF ENGINE_IDS
    };
    let response = await EngineService.listOfProfiles(data);
    console.log('record view listOfProfiles', response);
    if (response.success) {
      //   let engineData = response.data.find((engineData, index) => {
      //     return engineData.id == engineID;
      //   });
      //   console.log(engineData);
      let profile = response.data.find((portionData, index) => {
        return portionData.awi_label == profileID;
      });
      return profile;
      // this.setState({ profileList: response.data }, this.handleGetSectionsData);
    }
  };

  let handleAddSection = async (e) => {
    // e.preventDefault();
    // let { engineID, profileID } = match.params;
    let uploadedProfileData = await handleGetUploadedProfileData();
    let predefinedSectionData = await handleGetListOfEntries({
      awi_label: sectionID,
    });
    console.log('predefinedSectionData', predefinedSectionData);
    // console.log(await handleGetProfileData())
    if (mediaBlobUrl) {
      setIsUploading(true);
      let formData = new FormData();
      formData.append('awi_label', sectionID);
      // formData.append('awi_label', profileData.awi_label);
      formData.append('awi_engine_id', engineID * 1);
      formData.append('awi_profile_id', uploadedProfileData.id);
      // formData.append('awi_overlay_image_id', profileData.awi_overlay_image_id);
      formData.append(
        'awi_overlay_image_id',
        predefinedSectionData[0].awi_overlay_image_id
      );

      if (match.path === RoutePath.dataReCollectionSectionPath()) {
        console.log('section recollected');
        formData.append('update_status', true);
      }
      // let { image: file } = this.state;

      let file;
      if (engineData.type === 'video') {
        file = await fetch(mediaBlobUrl).then((r) => r.blob());
      } else {
        file = mediaBlobUrl;
      }
      console.log(file);
      if (!file.name) {
        formData.append('file', file, 'engine-image.jpeg');
      } else {
        formData.append('file', file);
      }
      // console.log('formData', formData.entries());
      for (var pair of formData.entries()) {
        console.log('formData', pair[0] + ', ' + pair[1]);
      }
      // formData.append('file', this.state.image);
      // setIsUploading(true)
      let response = await EngineService.addNewSection(formData);
      if (response.success) {
        // EventEmitter.emit('newSectionAdded');
        // console.log('addNewSection', response);
        toast.success(response.message);
      } else {
        setIsUploading(false);
        toast.error(response.message);
      }

      if (match.path === RoutePath.dataReCollectionSectionPath()) {
        RoutePath.navigateTo(
          { history, match },
          RoutePath.engineDetails(engineID)
        );
      } else {
        RoutePath.navigateTo(
          { history, match },
          RoutePath.dataCollectionProfileSelectionPath(engineID)
        );
      }
    } else {
      toast.error('Please Record the video/photo first!');
    }
  };

  let handleGetUploadedSectionData = async (uploadedProfileData) => {
    // let { engineID } = match.params;
    let data = {
      awi_profile_ids: [uploadedProfileData.id * 1], //ARRAY OF ENGINE_IDS
    };
    let response = await EngineService.listOfSections(data);
    console.log('record view listOfSections', response);
    if (response.success) {
      //   let engineData = response.data.find((engineData, index) => {
      //     return engineData.id == engineID;
      //   });
      //   console.log(engineData);
      let section = response.data.find((portionData, index) => {
        return portionData.awi_label == sectionID;
      });
      console.log('section', section);
      return section;
      // this.setState({ profileList: response.data }, this.handleGetSectionsData);
    }
  };

  let handleAddSubSection = async (e) => {
    // e.preventDefault();
    // let { engineID, profileID } = match.params;
    let uploadedProfileData = await handleGetUploadedProfileData();

    let uploadedSectionData = await handleGetUploadedSectionData(
      uploadedProfileData
    );

    let predefinedSubSectionData = await handleGetListOfEntries({
      awi_label: subSectionID,
    });
    console.log('predefinedsubSectionData', predefinedSubSectionData);

    if (mediaBlobUrl) {
      setIsUploading(true);
      let formData = new FormData();
      formData.append('awi_label', subSectionID);
      formData.append('awi_engine_id', engineID * 1);
      formData.append('awi_profile_id', uploadedProfileData.id);
      formData.append('awi_section_id', uploadedSectionData.id);
      formData.append(
        'awi_overlay_image_id',
        predefinedSubSectionData[0].awi_overlay_image_id
      );
      if (match.path === RoutePath.dataReCollectionSubSectionPath()) {
        console.log('sub section recollected');
        formData.append('update_status', true);
      }
      // let { image: file } = this.state;

      let file;
      if (engineData.type === 'video') {
        file = await fetch(mediaBlobUrl).then((r) => r.blob());
      } else {
        file = mediaBlobUrl;
      }
      console.log(file);
      if (!file.name) {
        formData.append('file', file, 'engine-image.jpeg');
      } else {
        formData.append('file', file);
      }

      for (var pair of formData.entries()) {
        console.log('formData', pair[0] + ', ' + pair[1]);
      }

      // formData.append('file', this.state.image);
      // setIsUploading(true)
      let response = await EngineService.addNewSubSection(formData);
      if (response.success) {
        // EventEmitter.emit('newSectionAdded');
        console.log('addNewSubSection', response);
        toast.success(response.message);
        if (match.path === RoutePath.dataReCollectionSectionPath()) {
          RoutePath.navigateTo(
            { history, match },
            RoutePath.engineDetails(engineID)
          );
        } else {
          RoutePath.navigateTo(
            { history, match },
            RoutePath.dataCollectionProfileSelectionPath(engineID)
          );
        }
      } else {
        setIsUploading(false);
        toast.error(response.message);
      }
    } else {
      toast.error('Please Record the video/photo first!');
    }
  };

  let handleAddEnginePortion = () => {
    if (engineID && profileID && sectionID && subSectionID) {
      console.log(engineID, profileID, sectionID, subSectionID);
      handleAddSubSection();
    } else if (engineID && profileID && sectionID) {
      console.log(engineID, profileID, sectionID);
      handleAddSection();
    } else if (engineID && profileID) {
      console.log(engineID, profileID);
      handleAddProfile();
    }
  };

  let [recording, setRecording] = useState(false);
  let [progress, setProgress] = useState(0);
  // console.log('progress', progress);
  let timer;
  // console.log(timer);
  // startRecording,
  // stopRecording,
  // clearBlobUrl,

  let timerUpdationFrequency = 500;
  let progressUpdationAmount =
    (timerUpdationFrequency / Globals.recordingTimeout) * 100;

  let handleSetTimerProgress = () => {
    // console.log('progressUpdationAmount', progressUpdationAmount);
    // console.log('interval called', progress, progressUpdationAmount);
    // if (recording) {
    // let amount = progress + progressUpdationAmount;
    // console.log(amount)
    setProgress((prevProgress) => {
      if (prevProgress < 100) {
        return prevProgress + progressUpdationAmount;
      } else {
        return 100;
      }
    });
    // }
  };

  let handleStartRecording = () => {
    // setProgress(0);
    setRecording(true);
    props.handleSetRecordingFlag(true);
    props.startRecordingGG();
    // startRecording();

    setTimeout(() => {
      handleStopRecording();
    }, Globals.recordingTimeout);

    timer = setInterval(handleSetTimerProgress, timerUpdationFrequency);
  };

  let handleStopRecording = () => {
    clearInterval(timer);
    props.handleSetRecordingFlag(false);
    setRecording(false);
    setProgress(100);
    // stopRecording();
    props.endRecordingGG();
  };

  let handleToggleRecording = () => {
    if (recording) {
      handleStopRecording();
    } else {
      handleStartRecording();
    }
  };

  // EventEmitter.off('media_capture_start');
  // EventEmitter.off('media_capture_clear');

  let handleCaptureImage = () => {
    props.handleCapture();
    EventEmitter.emit('media_capture_start');
  };

  let handleClearImage = () => {
    // EventEmitter.emit('media_capture_clear');
    props.handleClear();
    handleMediaBlobURLChanged(undefined);
  };

  let handleRejectRecording = () => {
    setProgress(0);
    
    // if (engineData.type === 'video' && !isIos()) {
    //   clearBlobUrl();
    // } else {
      handleClearImage();
    // }
  };

  // let instructionModalButtonRef = createRef();
  // const [instructionsAnchorEl, setInstructionsAnchorEl] = useState(null);

  // useLayoutEffect(() => {
  //   isIos() && setInstructionsAnchorEl(instructionModalButtonRef.current);
  // }, []);

  // const handleOpenInstructions = (event) => {
  //   console.log(instructionsAnchorEl, event.currentTarget);
  //   setInstructionsAnchorEl(event.currentTarget);
  // };

  // const handleCloseInstructions = () => {
  //   setInstructionsAnchorEl(null);
  // };

  // const instructionsModalOpen = Boolean(instructionsAnchorEl);
  // const instructionModalID = instructionsModalOpen
  //   ? 'simple-popover'
  //   : undefined;
  // debugger;
  let imof = window.localStorage.getItem('imof');
  if (imof !== 'true' && imof !== 'false') {
    imof = false;
    localStorage.setItem('imof', false);
  }
  const [instructionsModalOpenFlag, setInstructionsModalOpenFlag] = useState(
    // true
    imof === 'true' ? false : isIos() && engineData.type === 'video'
  );

  const [doNotShowAgainFlag, setDoNotShowAgainFlag] = useState(imof === 'true');

  const handleDoNotShowAgainFlagChange = (event) => {
    setDoNotShowAgainFlag(event.target.checked);
  };

  const handleOpenInstructions = () => {
    setInstructionsModalOpenFlag(true);
  };

  const handleCloseInstructions = () => {
    setInstructionsModalOpenFlag(false);
    console.log(doNotShowAgainFlag);
    localStorage.setItem('imof', doNotShowAgainFlag);
  };

  return (
    <div className={rootClass}>
      {/* <ReactMediaRecorder
        video
        render={({ previewStream }) => {
          return <VideoPreview stream={previewStream} />;
        }}
      /> */}
      {/* <div> */}
      <Grid 
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
        className={classes.container}
      >
        <div className={classes.grow} />
        {/* {isIos() ? <div className={classes.grow} /> : null} */}
        <Grid item>
          <Button
            color={'primary'}
            variant="contained"
            size={'small'}
            // className={classes.recordButton}
            onClick={handleImageChange}
            // disabled={selectedImage || mediaBlobUrl}
          >
            <Typography variant="subtitle2">
              {!isIos() ? 'Switch to ' : null}
              {isIos() && selectedImage ? 'Go To ' : null}
              {selectedImage ? 'Camera' : 'Reference Image'}
            </Typography>
          </Button>
          {/* <FormControlLabel
            control={
              <Switch
                checked={selectedImage}
                onChange={handleImageChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Reference Image"
          /> */}
        </Grid>

        {/* {!isIos() ? <div className={classes.grow} /> : null} */}

        {mediaBlobUrl ? (
          <Grid item>
            <div style={{ paddingBottom: 5 }}>
              {engineData.type === 'video' ? (
                !props.videoPlayingFlag ? (
                  <IconButton
                    variant="contained"
                    size={'small'}
                    onClick={props.handlePlayVideo}
                    disabled={selectedImage}
                    className={classes.playButton}
                  >
                    <PlayCircleFilledTwoToneIcon fontSize={'inherit'} />
                  </IconButton>
                ) : (
                  <IconButton
                    variant="contained"
                    size={'small'}
                    onClick={props.handlePauseVideo}
                    disabled={selectedImage}
                    className={classes.pauseButton}
                  >
                    <PauseCircleFilledTwoToneIcon fontSize={'inherit'} />
                  </IconButton>
                )
              ) : (
                <IconButton
                  variant="contained"
                  size={'small'}
                  onClick={handleClearImage}
                  disabled={selectedImage}
                  className={classes.playButton}
                >
                  <HighlightOffTwoToneIcon fontSize={'inherit'} />
                </IconButton>
              )}
            </div>
          </Grid>
        ) : (
          <Grid item className={classes.progressWrapper}>
            {/* <p>{status === 'recording' ? 'Recording...' : null}</p> */}

            <div className={classes.startStopButtonsContainer}>
              {!mediaBlobUrl ? (
                engineData.type === 'video' ? (
                  
                  //video capture
                  <div>
                    {status === 'recording' ? (
                      <IconButton
                        variant="contained"
                        size={'small'}
                        // onClick={handleToggleRecording}
                        disabled={
                          !!(
                            selectedImage ||
                            mediaBlobUrl ||
                            status === 'recording'
                          )
                        }
                        className={classes.stopRecordingButton}
                      >
                        <AlbumIcon fontSize={'inherit'} />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="contained"
                        size={'small'}
                        onClick={handleToggleRecording}
                        disabled={!!(selectedImage || mediaBlobUrl)}
                        className={classes.startRecordingButton}
                      >
                        <RadioButtonCheckedIcon fontSize={'inherit'} />
                      </IconButton>
                    )}
                    <CircularProgress
                      value={progress}
                      variant="determinate"
                      size={70}
                      // onClick={handleToggleRecording}
                      // onClick={!mediaBlobUrl ? handleToggleRecording : undefined}
                      className={classes.buttonProgress}
                    />
                  </div>
                ) : (
                  //Image capture button
                  <IconButton
                    variant="contained"
                    size={'small'}
                    onClick={handleCaptureImage}
                    disabled={!!(selectedImage || mediaBlobUrl)}
                    className={classes.startRecordingButton} //not onclick
                  >
                    <RadioButtonCheckedIcon fontSize={'inherit'} />
                  </IconButton>
                )
              ) : null}
            </div>
          </Grid>
        )}

        {/* {!isIos() ? <div className={classes.grow} /> : null} */}

        {mediaBlobUrl ? (
          <Grid
            item
            // className={classes.approvedRejectContainer}
          >
            <div style={{ paddingBottom: 5 }}>
              <IconButton
                variant="contained"
                size={'small'}
                onClick={handleAddEnginePortion}
                disabled={selectedImage}
                className={classes.approveButton}
              >
                <CheckCircleOutlineIcon fontSize={'inherit'} />
              </IconButton>
              <IconButton
                variant="contained"
                size={'small'}
                onClick={
                  engineData.type === 'video'
                    ? handleRejectRecording
                    : handleClearImage
                }
                disabled={selectedImage}
                className={classes.rejectButton}
              >
                <CancelOutlinedIcon fontSize={'inherit'} />
              </IconButton>
            </div>
          </Grid>
        ) : isIos() && engineData.type === 'video' ? (
          <div>
            <div className={classes.grow} />

            <IconButton
              // ref={instructionModalButtonRef}
              aria-describedby={'instructions-modal'}
              variant="contained"
              style={{ float: 'right' }}
              onClick={handleOpenInstructions}
              className={classes.infoButton}
            >
              <InfoIcon fontSize={'inherit'} />
            </IconButton>
          </div>
        ) : (
          <div className={classes.grow} />
        )}
        <div className={classes.grow} />
        {/* {isIos() ? <div className={classes.grow} /> : null} */}
      </Grid>

      <Modal
        // id={instructionModalID}
        open={instructionsModalOpenFlag}
        // anchorEl={instructionsAnchorEl}
        onClose={handleCloseInstructions}
        // anchorOrigin={{
        //   vertical: 'center',
        //   horizontal: 'left',
        // }}
        // transformOrigin={{
        //   vertical: 'center',
        //   horizontal: 'right',
        // }}
        className={classes.instructionsModal}
      >
        <Paper className={classes.instructionsModalPaper}>
          <Typography
            variant="h5"
            className={classes.instructionsHeading}
            gutterBottom
          >
            Instructions:
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.instructionsSubtitle}
            gutterBottom
          >
            (Scroll down to view more, click outside the dialog box to close.)
          </Typography>

          <div className={classes.instructionStepsContainer}>
            <br />
            <div
              variant="body2"
              component="div"
              className={classes.instructionStep}
              gutterBottom
            >
              1. Align the phone's camera view with the overlaid outline. You
              can also click on the "Open Reference Image" button on the
              top-right corner to see what part of the engine needs to be
              captured.
            </div>
            <br />
            <div
              variant="body2"
              component="div"
              className={classes.instructionStep}
              gutterBottom
            >
              2. Click on the record button on the right and select "Open
              Camera"
            </div>
            <br />
            <div
              variant="body2"
              className={classes.instructionStep}
              gutterBottom
            >
              3. While keeping your phone still, record a 5 second video and
              submit it.
            </div>
            <br />
            <div
              variant="body2"
              className={classes.instructionStep}
              gutterBottom
            >
              4. In the new window that opens up, check if the recorded video
              aligns with the overlaid outline.
            </div>
            <br />
            <div
              variant="body2"
              className={classes.instructionStep}
              gutterBottom
            >
              5. Submit the video, or click on retake and follow the above steps
              again
            </div>
            <br />
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={doNotShowAgainFlag}
                onChange={handleDoNotShowAgainFlagChange}
                name="doNotShowAgainFlag"
                color="primary"
              />
            }
            label="Do Not Show Again"
          />
        </Paper>
      </Modal>
      {/* </div> */}

      {/* <Backdrop className={classes.backdrop} open={true}> */}
      <Backdrop className={classes.backdrop} open={isUploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default withStyles(styles)(RecordView);
