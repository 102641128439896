let DesktopNotificationCardStyles = (theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },

  profileIconThumbnail: {
    // width: 100,
    // height: 100,
    padding: theme.spacing(2),
    fontSize: theme.spacing(4),
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    width: 'fit-content',
    margin: 'auto',
    color: 'white',
    fontWeight: 700,
  },
  rowContainer: {
    padding: theme.spacing(4, 2, 1),
    position: 'relative',
  },
  rowInternalContainer: {
    padding: theme.spacing(4, 2, 1),
    // position: 'relative',
  },
  actionTakenDiv: {
    position: 'absolute',
    padding:theme.spacing(1,2),
    top: -12,
    left: -7,
    border: '1px solid',
    backgroundColor: 'white',
  },
  thumbnailImage: {
    width: '100%',
  },
});

export default DesktopNotificationCardStyles;
