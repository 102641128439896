import Axios from 'axios';
import ServiceResponse from './ServiceResponse';
import { toast } from 'material-react-toastify';

class APIServices {
  static async request(axiosConfig) {
    try {
      const response = await Axios.request(axiosConfig);
      console.log('API SERVICES RESPONSE', response);
      // console.log(response.data.success);
      // toast.error('File size limit of 200mb exceeded');
      if (response.status === 413) {
        toast.error('File size limit of 200mb exceeded');
      }
      if (response.data.success) {
        // console.log('calling service response');
        return ServiceResponse.success(
          response.data.msg,
          response.data.token || response.data.data
        );
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('api config', axiosConfig);
          console.log('api response',response);
        }
        return ServiceResponse.error(response.data.msg, {}, response.status);
      }
    } catch (e) {
      // console.log('response',e.response);
      // console.log('status',e.response.status);
      // console.log('request',e.request);
      // console.log('message',e.message,e.response.message);
      if (e.response) {
        console.log('api services error', e);
        return ServiceResponse.error('Connection timed out');
        // return ServiceResponse.error(e.response.data.msg)
      } else {
        return ServiceResponse.error('The connection timed out');
      }
    }
  }
}

export default APIServices;
