import RegexEnum from '../../models/RegexEnum';
import ValidatorResponse from './ValidatorResponse';

class EngineValidator {
  static async isValidESN(ESN) {
    // console.log(ESN, ESN.match(RegexEnum.ESN));
    if (!ESN || (ESN && ESN.trim().length === 0))
      return ValidatorResponse.error('ESN is Empty');
    else if (!ESN.match(RegexEnum.ESN)) {
      return ValidatorResponse.error(
        `Invalid ESN.\nPlease match the given format: Vxxxxx`
      );
    }
    // todo, check email

    return ValidatorResponse.success();
  }
}

export default EngineValidator;
