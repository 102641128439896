let StatusEnum = {
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  PARTIALLY_REVIEWED: 'partially reviewed',
  APPROVED: 'approved',
  CANCELLED: 'cancelled',

  REJECTED: 'rejected',
};

export default StatusEnum;
