import {
  Badge,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import React, { Component, Fragment } from 'react';
import { isIOS, isMobile } from 'mobile-device-detect';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import EngineDetailsSubSectionListStyles from './EngineDetailsSubSectionListStyles';
import EngineService from '../../../../../services/EngineService';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import FlipCameraIosTwoToneIcon from '@material-ui/icons/FlipCameraIosTwoTone';
import ReviewConfirmationModal from '../../../ReviewConfirmationPage/ReviewConfirmationModal';
import RolesEnum from '../../../../../models/RolesEnum';
import RoutePath from '../../../../../lib/RoutePath';
import SmallCommentList from '../../../SmallCommentList/SmallCommentList';
import VideoPlayerActions from '../../../../../redux/actions/VideoPlayerActions';
import VideoPlayerContainer from '../../../../CommonComponents/VideoPlayerContainer/VideoPlayerContainer';
import { capitalizeFirstLetter } from '../../../../../util/CommonUtils';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

// import SectionList from './SectionList/SectionList';

let styles = EngineDetailsSubSectionListStyles;

class EngineDetailsSubSectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsOpen: false,
      expandedSubSection: undefined,
      reviewConfirmationModalOpenFlag: false,
      reviewConfirmationMode: undefined,
      reviewPortionData: undefined,

      playerVisible: false,
      autoPlay: false,
      controlsVisible: false,
      loopVideo: false,
      type: 'video',
      videoPlayerSelectedPortion: undefined,
    };
  }

  componentDidMount = () => {
    // "?q=12&pp=gg"
    console.log(this.props.location.search);
    let params = new URLSearchParams(this.props.location.search);
    let expandedSubSection = params.get('expandedSubSection');
    let openComments = params.get('openComments');
    this.setState({ expandedSubSection });
  };

  handleSubSectionExpanded = (subSectionData) => (event) => {
    event.stopPropagation();
    let { expandedSubSection } = this.state;
    this.setState({
      expandedSubSection:
        expandedSubSection === subSectionData.awi_label
          ? false
          : subSectionData.awi_label,
    });
  };

  handleModifySubSection = (e) => {
    e.stopPropagation();
    console.log('handleModifySubSection');
  };

  handleDeleteSubSection = async (e) => {
    e.stopPropagation();
    console.log('handleDeleteSubSection');
  };

  handleOpenReviewConfirmation = (portionData, reviewConfirmationMode) => {
    this.setState({
      reviewConfirmationMode,
      reviewPortionData: portionData,
      reviewConfirmationModalOpenFlag: true,
    });
  };

  handleCloseReviewConfirmation = (portionData, reviewConfirmationMode) => {
    this.setState({
      reviewConfirmationMode: undefined,
      reviewPortionData: portionData,
      reviewConfirmationModalOpenFlag: false,
    });
  };

  handleApprovePortion = (portionData) => async (e) => {
    e.stopPropagation();
    // console.log(portionData, portionType);
    this.handleOpenReviewConfirmation(portionData, 'approve');
  };

  handleRejectPortion = (portionData) => async (e) => {
    e.stopPropagation();
    // console.log(portionData, portionType);
    this.handleOpenReviewConfirmation(portionData, 'reject');
  };

  handleSubSectionCommentsToggle = (panel) => (event) => {
    event.stopPropagation();
    let { commentsOpen, expandedSubSection } = this.state;
    this.setState({
      commentsOpen: expandedSubSection === panel ? !commentsOpen : true,
      expandedSubSection: panel,
      sectionCommentsOpen: false,
      subSectionCommentsOpen: false,
    });
  };

  handleVideoPlayerOpen = (selectedPortion) => (event) => {
    event.stopPropagation();
    let { engineData, openVideoPlayer } = this.props;
    // RoutePath.navigateTothis.props, RoutePath.engineDetails(engineData.id));
    this.setState({
      playerVisible: true,
      videoPlayerSelectedPortion: selectedPortion,
      autoPlay: true,
      controlsVisible: true,
      loopVideo: true,
      type: engineData.type,
    });
  };

  handleVideoPlayerClose = (event) => {
    event.stopPropagation();
    let { engineData, openVideoPlayer } = this.props;
    // RoutePath.navigateTothis.props, RoutePath.engineDetails(engineData.id));
    this.setState({
      playerVisible: false,
      videoPlayerSelectedPortion: undefined,
      autoPlay: false,
      controlsVisible: false,
      loopVideo: false,
      type: 'video',
    });
  };

  getRelatedStatusIcon = (approvalStatus) => {
    if (approvalStatus === 'pending') {
      return <ErrorTwoToneIcon fontSize="small" />;
    } else if (approvalStatus === 'approved') {
      return <CheckCircleTwoToneIcon fontSize="small" />;
    } else if (approvalStatus === 'rejected') {
      return <CancelTwoToneIcon fontSize="small" />;
    }
  };

  render() {
    let {
      subSectionList,
      classes,
      loggedUser,
      engineData,
      profileData,
      sectionData,
    } = this.props;
    let { engineID } = this.props.match.params;
    let {
      expandedSubSection,
      commentsOpen,
      reviewConfirmationModalOpenFlag,
      reviewPortionData,
      reviewConfirmationMode,

      playerVisible,
      videoPlayerSelectedPortion,
      autoPlay,
      controlsVisible,
      loopVideo,
      type,
    } = this.state;
    return (
      <div className={classes.root}>
        {subSectionList.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SubSection</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subSectionList.map((subSectionData, index) => (
                  <Fragment key={subSectionData.id}>
                    <TableRow
                      hover
                      className={classes.subSectionSummaryContainer}
                      onClick={this.handleSubSectionExpanded(subSectionData)}
                      aria-label="subSection-summary"
                    >
                      <TableCell align="center">
                        <Badge
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          key={subSectionData.id}
                          classes={{
                            badge: classes[subSectionData.approved_status],
                          }}
                          color="primary"
                          badgeContent={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {this.getRelatedStatusIcon(
                                subSectionData.approved_status
                              )}
                              &nbsp;
                              {capitalizeFirstLetter(
                                subSectionData.approved_status
                              )}
                            </div>
                          }
                        >
                          {engineData.type === 'video' ? (
                            // !isIOS ? (
                            true ? (
                              <video
                                muted
                                playsInline
                                autoPlay={isIOS}
                                src={subSectionData.awi_image_url}
                                alt={subSectionData.awi_content_id}
                                className={classes.subSectionMedia}
                                onClick={this.handleVideoPlayerOpen(
                                  subSectionData
                                )}
                              />
                            ) : (
                              <Typography
                                className={classes.unableToOpenVideoOnIOSError}
                              >
                                Video playback not supported on IOS. Please open
                                this page on a different platform.
                              </Typography>
                            )
                          ) : (
                            <img
                              src={subSectionData.awi_image_url}
                              alt={subSectionData.awi_content_id}
                              className={classes.subSectionMedia}
                              onClick={this.handleVideoPlayerOpen(
                                subSectionData
                              )}
                            />
                          )}
                        </Badge>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{subSectionData.awi_label}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        {moment(subSectionData.ts_creation).format(
                          isMobile ? 'l' : 'LL'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="column"
                          justify="center"
                          spacing={1}
                        >
                          <Grid
                            item
                            container
                            direction="row"
                            justify="center"
                            spacing={1}
                          >
                            {loggedUser.userObj?.level ===
                            RolesEnum.TECHNICIAN ? (
                              <Grid item>
                                {isMobile ? (
                                  <IconButton
                                    component={Link}
                                    // exact
                                    to={RoutePath.dataReCollectionSubSectionPath(
                                      engineID,
                                      profileData.awi_label,
                                      sectionData.awi_label,
                                      subSectionData.awi_label
                                    )}
                                    aria-label="view-comments"
                                    className={classes.mobileCommentsButton}
                                  >
                                    <FlipCameraIosTwoToneIcon
                                      fontSize={'inherit'}
                                    />
                                  </IconButton>
                                ) : (
                                  <Button
                                    component={Link}
                                    // exact
                                    to={RoutePath.dataReCollectionSubSectionPath(
                                      engineID,
                                      profileData.awi_label,
                                      sectionData.awi_label,
                                      subSectionData.awi_label
                                    )}
                                    startIcon={
                                      <FlipCameraIosTwoToneIcon
                                        fontSize={'inherit'}
                                      />
                                    }
                                    variant="outlined"
                                    className={classes.mobileNavLink}
                                  >
                                    Retake
                                  </Button>
                                )}
                              </Grid>
                            ) : (
                              <>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    className={classes.approveButton}
                                    startIcon={
                                      <CheckCircleOutlineIcon
                                        fontSize={'inherit'}
                                      />
                                    }
                                    onClick={this.handleApprovePortion(
                                      subSectionData,
                                      'subSection'
                                    )}
                                    aria-label="approve-button"
                                  >
                                    Approve
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.rejectButton}
                                    startIcon={
                                      <CancelOutlinedIcon
                                        fontSize={'inherit'}
                                      />
                                    }
                                    onClick={this.handleRejectPortion(
                                      subSectionData,
                                      'subSection'
                                    )}
                                    aria-label="reject-button"
                                  >
                                    Reject
                                  </Button>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Grid item>
                            {isMobile ? (
                              <IconButton
                                onClick={this.handleSubSectionExpanded(
                                  subSectionData
                                )}
                                aria-label="view-comments"
                                className={classes.mobileCommentsButton}
                              >
                                <ChatBubbleIcon />
                              </IconButton>
                            ) : (
                              <Button
                                variant="outlined"
                                color={
                                  expandedSubSection ===
                                  subSectionData.awi_label
                                    ? 'primary'
                                    : 'default'
                                }
                                className={classes.commentsButton}
                                startIcon={
                                  <ChatBubbleIcon fontSize={'inherit'} />
                                }
                                onClick={this.handleSubSectionExpanded(
                                  subSectionData
                                )}
                                aria-label="view-comments"
                              >
                                Comments
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.root}>
                      <TableCell colSpan={4}>
                        <Collapse
                          in={expandedSubSection === subSectionData.awi_label}
                          timeout="auto"
                          unmountOnExit
                        >
                          <SmallCommentList
                            open={commentsOpen}
                            portionData={{
                              profileData,
                              sectionData,
                              ...subSectionData,
                            }}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">No sub-sections uploaded.</Typography>
        )}

        <ReviewConfirmationModal
          open={reviewConfirmationModalOpenFlag}
          onClose={this.handleCloseReviewConfirmation}
          reviewPortionData={reviewPortionData}
          sectionData={sectionData}
          profileData={profileData}
          reviewConfirmationMode={reviewConfirmationMode}
          handleGetEngineStatusInHeirarchy={
            this.props.handleGetEngineStatusInHeirarchy
          }
        />

        <VideoPlayerContainer
          open={playerVisible}
          onClose={this.handleVideoPlayerClose}
          portionDetails={videoPlayerSelectedPortion}
          autoPlay={autoPlay}
          controlsVisible={controlsVisible}
          loopVideo={loopVideo}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  // console.log(state);
  return {
    loggedUser: state.UserReducer.loggedUser,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    openVideoPlayer: (videoData) =>
      dispatch(VideoPlayerActions.openVideoPlayer(videoData)),
    closeVideoPlayer: () => dispatch(VideoPlayerActions.closeVideoPlayer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(EngineDetailsSubSectionList)));
