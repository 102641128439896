let TechnicianDetailsStyles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
  },
  mobileHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1, 2),
    color: 'white',
    backgroundColor: '#204e79',
  },
  headingContainer: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    //   color: 'white',
    //   backgroundColor: '#204e79',
  },
  pageContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
  },
  profileIconThumbnail: {
    // width: 100,
    // height: 100,
    padding: 17,
    fontSize: 33,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    width: 'fit-content',
    margin: 'auto',
    color: 'white',
    fontWeight: 700,
  },
  boldText: {
    fontWeight: 'bold',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(9),
    right: theme.spacing(2),
  },
});

export default TechnicianDetailsStyles;
