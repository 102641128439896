let FilterModalStyles = (theme) => ({
  root: {},
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    width: '100%',
    padding: 10,
    maxHeight: '95%',
    outline: 0,
    [`@media(min-width:${theme.breakpoints.values.lg}px)`]: {
      maxWidth: 600,
    },
  },
  modalPaper: {
    position: 'relative',
  },
  closeButtonContainer: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  contentContainer: {
    padding: theme.spacing(3, 4, 9),
    // paddingTop: theme.spacing(3),
    margin: 'auto',
    
    // [`@media(min-width:${theme.breakpoints.values.lg}px)`]: {
    //   maxWidth: 500,
    // },
  },
  mainHeading: {
    marginBottom: theme.spacing(3),
  },
});

export default FilterModalStyles;
