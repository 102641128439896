let AppStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  '@global': {
    '.scrollbar_primary::-webkit-scrollbar': {
      width: 8,
      backgroundColor: '#F5F5F5',
    },
    '.scrollbar_primary::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'grey',
    },
    '.basic_flex_row': {
      display: 'flex',
      flexDirection: 'row',
    },
    '.justify_content_center': {
      justifyContent: 'center',
    },
    '.align_items_center': {
      alignItems: 'center',
    },
  },
});

export default AppStyles;
