import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';

import EngineCard from './EngineCard/EngineCard';
import EngineListStyles from './EngineListStyles';
import EngineService from '../../services/EngineService';
import EventEmitter from '../../util/EventEmitter';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterModal from './FilterModal/FilterModal';
import { isMobile } from 'mobile-device-detect';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = EngineListStyles;

class EngineList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engineList: [],
      filteredEngineList: [],
      searchString: '',
      selectedStatusList: [],
      selectedTechnicianList: [],
      filterModalOpen: false,
      selectedStartDate: undefined,
      selectedEndDate: undefined,
    };
  }

  componentDidMount = async () => {
    await this.handleGetEngineStatusInHierarchy();
    EventEmitter.on('newEngineAdded', this.handleNewEngineAdded);
    EventEmitter.on('engineDeleted', this.handleEngineDeleted);
  };

  componentWillUnMount = async () => {
    EventEmitter.off('newEngineAdded');
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {
      engineList,

      searchString,
      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;

    if (
      // prevState.engineList !== engineList ||
      prevState.selectedStatusList !== selectedStatusList ||
      prevState.selectedTechnicianList !== selectedTechnicianList ||
      prevState.selectedStartDate !== selectedStartDate ||
      prevState.selectedEndDate !== selectedEndDate
    ) {
      this.handleGetEngineStatusInHierarchy();
      // this.getFilteredList();
    }

    if (
      // prevState.engineList !== engineList ||
      prevState.searchString !== searchString
    ) {
      this.getFilteredList();
    }
  };

  // addNewEngine = async () => {
  //   let response = await EngineService.listOfEngines();
  //   console.log(response, 'enginelist');
  //   if (response.success) {
  //   }
  // };

  handleGetEngineStatusInHierarchy = async () => {
    let {
      // filteredEngineList,
      // filterModalOpen,
      // engineList,

      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    // console.log(selectedStartDate, selectedEndDate);
    let data = {
      start_time: selectedStartDate
        ? new Date(selectedStartDate).getTime()
        : undefined,
      end_time: selectedEndDate
        ? new Date(selectedEndDate).getTime()
        : undefined,
      status_filters: selectedStatusList,
      awi_user_ids: selectedTechnicianList.map((technicianData, index) => {
        return technicianData.id;
      }),
    };
    // console.log('getEngineStatusInHierarchy data', data);
    let response = await EngineService.getEngineStatusInHierarchy(data);
    // console.log(response, 'enginelist');
    if (response.success) {
      this.setState({ engineList: response.data }, () => {
        if (!selectedStartDate) {
          this.setInitialDates();
        }
        this.getFilteredList();
      });
    } else {
      this.setState({ engineList: [] }, this.getFilteredList);
    }
  };

  setInitialDates = () => {
    // let { initialStartDate, initialEndDate } = this.props;
    let { engineList } = this.state;
    this.setState({
      selectedStartDate: engineList.length && engineList[0].ts_creation,
      selectedEndDate:
        engineList.length && engineList[engineList.length - 1].ts_creation,
    });
  };

  getFilteredList = (engineList = this.state.engineList) => {
    let { searchString } = this.state;

    let filteredEngineList = engineList.filter((engineData, index) => {
      let searchStringMatched = false;
      // console.log(engineData);
      if (searchString) {
        if (
          engineData.awi_serial_num
            .toLowerCase()
            .search(searchString.toLowerCase()) >= 0 ||
          engineData.location
            .toLowerCase()
            .search(searchString.toLowerCase()) >= 0 ||
          engineData.approved_status
            .toLowerCase()
            .search(searchString.toLowerCase()) >= 0
        ) {
          searchStringMatched = true;
        }
      } else {
        searchStringMatched = true;
      }
      return searchStringMatched;
    });
    // console.log(filteredEngineList);
    this.setState({ filteredEngineList });
  };

  handleNewEngineAdded = async () => {
    await this.handleGetEngineStatusInHierarchy();
  };

  handleEngineDeleted = async () => {
    await this.handleGetEngineStatusInHierarchy();
  };

  handleFilterModalOpen = () => {
    this.setState({ filterModalOpen: true });
  };

  handleFilterModalClose = () => {
    this.setState({ filterModalOpen: false });
  };

  setSearchString = (searchString) => {
    this.setState({ searchString });
  };

  handleSetSelectedStatus = (selectedStatusList) => {
    this.setState({ selectedStatusList });
  };

  setSelectedTechnicianList = (selectedTechnicianList) => {
    this.setState({ selectedTechnicianList });
  };

  setSelectedStartDate = (selectedStartDate) => {
    this.setState({ selectedStartDate });
  };

  setSelectedEndDate = (selectedEndDate) => {
    this.setState({ selectedEndDate });
  };

  render() {
    let { classes } = this.props;
    let {
      filteredEngineList,
      filterModalOpen,
      engineList,

      searchString,
      selectedStatusList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    // console.log('lol', engineList);
    return (
      <div>
        <div className={classes.root}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                ENGINE LIST
              </Typography>
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                Engine List
              </Typography>
            </div>
          )}

          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.engineList}
            style={!isMobile ? { paddingTop: 8 } : null}
          >
            {filteredEngineList.length ? (
              filteredEngineList.reverse().map((engineData, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <EngineCard engineData={engineData} />
                  </Grid>
                );
              })
            ) : selectedStatusList ? (
              <Typography variant={'h6'} align="center">
                No engine data available for the selected Filters
              </Typography>
            ) : (
              <Typography variant={'h6'} align="center">
                No Engine Data Available
              </Typography>
            )}
          </Grid>
        </div>
        <Fab
          onClick={this.handleFilterModalOpen}
          color="primary"
          aria-label="filter"
          className={classes.fab}
          style={!isMobile ? { bottom: 16 } : null}
        >
          <FilterListIcon />
        </Fab>
        <FilterModal
          open={filterModalOpen}
          onClose={this.handleFilterModalClose}
          searchString={searchString}
          setSearchString={this.setSearchString}
          selectedStatusList={selectedStatusList}
          setSelectedStatus={this.handleSetSelectedStatus}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={this.setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={this.setSelectedEndDate}
          selectedTechnicianList={selectedTechnicianList}
          setSelectedTechnicianList={this.setSelectedTechnicianList}
          // selectedStartDate={
          //   engineList.length && engineList[engineList.length - 1].ts_creation
          // }
          // initialEndDate={this.handleSetSelectedStatus}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EngineList);
