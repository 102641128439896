import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { Component } from 'react';

import CancelEngineConfirmationDialogStyles from './CancelEngineConfirmationDialogStyles';
import EngineService from '../../../services/EngineService';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = CancelEngineConfirmationDialogStyles;

class CancelEngineConfirmationDialog extends Component {
  static defaultProps = {
    open: true,
    onClose: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      commentString: '',
    };
  }

  handleModalClose = () => {
    this.props.onClose();
  };

  handleCancelEngine = () => {
    let { commentString } = this.state;
    this.props.handleCancelEngine(commentString);
    this.props.onClose();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let { classes, open, onClose, handleCancelEngine } = this.props;
    let { commentString } = this.state;
    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to Cancel this Engine?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Cancelling this Engine would stop the data collection process for
              this Engine. By clicking confirm, you agree to continue with
              process of cancelling this engine.
            </DialogContentText>

            <div>
              <TextField
                multiline
                fullWidth
                id="outlined-multiline-flexible"
                label="Comment (Optional)"
                rowsMax={4}
                value={commentString}
                name={'commentString'}
                onChange={this.handleChange}
                variant="outlined"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleModalClose} color="primary">
              Close
            </Button>
            <Button
              onClick={this.handleCancelEngine}
              color="secondary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(CancelEngineConfirmationDialog);
