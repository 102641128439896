let FileUploadPageStyles = (theme) => ({
  root: { padding: 20 },
  dropzoneContainer: {
    // height: '90vh',
    marginTop: 50,
  },
  //   '.MuiDropzoneArea-root': {
  //     height: '100%',
  //   },
  dropzone: {
    height: '100%',
    width: '100%',
  },
});

export default FileUploadPageStyles;
