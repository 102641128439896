import { Button, CircularProgress, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import { DropzoneArea } from 'material-ui-dropzone';
import EngineService from '../../services/EngineService';
import FileUploadPageStyles from './FileUploadPageStyles';
import { Grid } from 'react-virtualized';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = FileUploadPageStyles;

class FileUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      loading: undefined,
    };
    this.addedFilesNotificationCount = 0;
  }

  handleFilesChange = (files) => {
    this.setState({
      files,
    });
  };

  handleFileUpload = async () => {
    let { files } = this.state;
    // if (true) {
    if (files.length) {
      let formData = new FormData();

      files.forEach((file, index) => {
        formData.append('file', file);
      });
      this.setState({ loading: true });
      let response = await EngineService.uploadBulkImages(formData);
      console.log('response', response);
      if (response.success) {
        toast.success(response.message);
        this.setState({ files: [], loading: false });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.message);
        toast.error('Please check your connection or the amount of data');
        this.setState({ loading: false });
      }
    } else {
      toast.error('Please add Files to Upload');
    }
  };

  onDrop = () => {
    this.setState({ fileAddingLoader: true });
    toast.success('Adding Files');
  };

  onAdd = () => {
    this.setState({ fileAddingLoader: false });
    // this.addedFilesNotificationCount++;
    // if(this.addedFilesNotificationCount === )
    // toast.success('added');
  };

  render() {
    let { classes } = this.props;
    let { files, loading, fileAddingLoader } = this.state;
    return (
      <div className={classes.root}>
        {/* FileUploadPage */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography>
            Total size limit is 200MB or 100 files whichever is less
          </Typography>
          {loading || fileAddingLoader ? (
            <CircularProgress color="primary" />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleFileUpload}
            >
              Submit
            </Button>
          )}
        </div>
        <div className={classes.dropzoneContainer}>
          <DropzoneArea
            dropzoneProps={{ className: classes.dropzone }}
            fileObjects={files}
            onChange={this.handleFilesChange}
            acceptedFiles={['image/jpeg', 'image/png']}
            filesLimit={100}
            maxFileSize={10000000}
            showAlerts={['error', 'info']}
            getFileAddedMessage={() => {
              //   this.setState({ fileAddingLoader: false });
              //   toast.success('added');
              this.onAdd();
              //   return 'File(s) Added'
            }}
            // getFileAddedMessage={() => 'File(s) added'}
            onDrop={this.onDrop}
            // onAdd={}
            // showPreviews={false}
            // showPreviewsInDropzone={false}
            useChipsForPreview
            // getFileAddedMessage={false}
            // showAlerts={false}
            // onChange={this.handleFilesChange}
            className={classes.dropzone}
          />
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default withStyles(styles)(FileUploadPage);
