const VideoPlayerInitialState = {
    playerVisible:false,
    playerURL:'',
    autoPlay:false,
    controlsVisible:false,
    loopVideo:false,
    type: 'video',
};

export default VideoPlayerInitialState;
