let EngineListStyles = (theme) => {
  console.log(theme);
  return {
    root: {
      position: 'relative',
      overflow: 'hidden',
    },
    mobileHeadingContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      zIndex: theme.zIndex.appBar,
      padding: theme.spacing(1, 2),
      color: 'white',
      backgroundColor: '#204e79',
    },
    headingContainer: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100vw',
      zIndex: theme.zIndex.appBar,
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      //   color: 'white',
      //   backgroundColor: '#204e79',
    },
    engineList: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(12),
      overflow: 'hidden',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(9),
      right: theme.spacing(2),
    },
  };
};

export default EngineListStyles;
