let AddNewEngineModalStyles = (theme) => ({
  root: {},
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    width: '100%',
    padding: 10,
    maxHeight: '95vh',
    outline: 0,
    [`@media(min-width:${theme.breakpoints.values.sm}px)`]: {
      maxWidth: 600,
    },
  },
  modalPaper: {
    position: 'relative',
    margin: theme.spacing(3, 1),
    padding: theme.spacing(3, 3),
    paddingTop: theme.spacing(6),
  },
  closeButtonContainer: {
    // position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  uploadOptionSelector: {},
  fileNameTextContainer: {
    // maxWidth:'50%'
  },
  contentContainer: {
    padding: theme.spacing(3, 4, 9),
    // paddingTop: theme.spacing(3),
    margin: 'auto',
    maxHeight: '75vh',
    overflowX: 'auto',
    // [`@media(min-width:${theme.breakpoints.values.lg}px)`]: {
    //   maxWidth: 500,
    // },
  },
  engineTypeSelect: {
    cursor: 'not-allowed',
  },
  submitButton: {
    marginTop: 15,
  },
});

export default AddNewEngineModalStyles;
