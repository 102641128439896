let VideoPreviewStyles = (theme) => ({
  root: {
    // position: 'relative',
    // overflow: 'hidden',
    // width: '100%',
    overflow:'hidden'
  },
  capturedImageContainer: {
    position: 'absolute',
    top: 0,
    maxHeight:'100vh',
    overflow:'hidden'
    // position: 'relative',
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
  },
  streamContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // width: '100vw',
    height: '100vh',
    overflow:'hidden'
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    position: 'relative',
    // overflow: 'hidden',
    // width: '100%',
  },

  captureButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99999,
  },
  // overlay:
  video: {
    position: 'absolute',
    top: 0,
    // left: '50%',
    // transform: 'translate(-50%, 0)',
    '&::-webkit-media-controls-play-button': {
      display: 'none !important',
      '-webkit-appearance': 'none',
    },
  },
  canvas: {
    border: '1px solid red',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export default VideoPreviewStyles;
