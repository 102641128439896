let RecordViewStyles = (theme) => ({
  hiddenRoot: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // color: '#fff',
  },
  container: {
    height: '100vh',
  },
  landscapeRoot: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
    maxWidth: 'calc(100% / 6)',
    height: '100vh',
    zIndex: theme.zIndex.cameraControls,
    backgroundColor: 'rgba(180,180,180,.7)',
    color: 'white',
  },
  portraitRoot: {
    // position: 'absolute',
    // bottom: 0,
    // left: 0,
    height: 56,
    width: '100vw',
    zIndex: theme.zIndex.cameraControls,
    padding: 5,
    backgroundColor: 'rgba(180,180,180,.7)',
  },
  recordButton: {
    position: 'absolute',
    top: '43%',
    bottom: '43%',
    right: 0,
  },
  approvedRejectContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  progressWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  startStopButtonsContainer: {
    // margin: theme.spacing(1),
    height: 77,
    width: 75,
    position: 'relative',
  },
  startRecordingButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.error.main,
    fontSize: 70,
    zIndex: 1100,
  },
  stopRecordingButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.success.main,
    fontSize: 70,
    zIndex: 1100,
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1099,
    transform: 'translate(-50%, -50%) !important',
    // marginTop: -12,
    // marginLeft: -12,
  },
  approveButton: {
    color: theme.palette.success.main,
    fontSize: 45,
  },
  rejectButton: {
    color: theme.palette.error.main,
    fontSize: 45,
  },
  infoButton: {
    color: 'white',
    fontSize: 45,
  },
  playButton: {
    color: theme.palette.primary.contrastText,
    fontSize: 65,
  },
  pauseButton: {
    color: theme.palette.primary.contrastText,
    fontSize: 65,
  },
  instructionsModal: {
    zIndex: '9000!important',
  },
  instructionsModalPaper: {
    width: '85vw',
    maxHeight: '75vh',
    padding: theme.spacing(2, 3),
    overflowY: 'auto',
    zIndex: 1500,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    outline: 0,
  },
  instructionsHeading: {
    // fontSize: 14,
  },
  instructionsSubtitle: {
    // fontSize: 14,
  },
  instructionStepsContainer: {
    // fontSize: '14px!important',
    // '-webkit-text-size-adjust': '100%',
  },
  instructionStep: {
    fontSize: '15px!important',
    '-webkit-text-size-adjust': '100%',
  },
});

export default RecordViewStyles;
