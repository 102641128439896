let ReviewConfirmationModalStyles = (theme) => ({
  root: {},
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    width: '100%',
    padding: 10,
    maxHeight: '95vh',
    outline: 0,
    [`@media(min-width:${theme.breakpoints.values.lg}px)`]: {
      maxWidth: 500,
    },
  },
  modalPaper: {
    position: 'relative',
    margin: theme.spacing(3, 1),
    padding: theme.spacing(3, 3),
    paddingTop: theme.spacing(6),
  },
  closeButtonContainer: {
    // position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  contentContainer: {
    paddingBottom: theme.spacing(4),
    // padding: theme.spacing(3, 4, 9),
    // paddingTop: theme.spacing(3),
    margin: 'auto',
    maxHeight: '75vh',
    overflowX: 'auto',
    // [`@media(min-width:${theme.breakpoints.values.lg}px)`]: {
    //   maxWidth: 500,
    // },
  },
  approveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginTop: 15,
  },
  rejectButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginTop: 15,
  },
});

export default ReviewConfirmationModalStyles;
