class RoutePath {
  static loginPath = '/login';
  static signupPath = '/signup';
  static homePath = '/';

  static invitePage = '/invite';
  static settingsPath = '/settings';
  static notificationPath = '/notifications';
  static accountPagePath = '/account';
  static uploadOutlinesPath = '/upload-predefined';
  static technicianListPath = '/technicians';
  static technicianDetailsPath = (technicianID = ':technicianID') =>
  `/technicians/${technicianID}`;
  static fileUploadPath = '/file-upload';

  static engineList = '/engines';
  static engineDetails = (engineID = ':engineID') => `/engines/${engineID}`;
  static sectionDetails = (
    engineID = ':engineID',
    profileID = ':profileID',
    sectionID = ':sectionID'
  ) => `/engines/${engineID}/${profileID}/${sectionID}`;

  static emailVerifiedRoute = '/email-verified';
  static emailAlreadyVerifiedRoute = '/email-already-verified';

  static dataCollectionRoute = '/data-collection';
  static dataCollectionProfileSelectionPath = (engineID = ':engineID') =>
    `/data-collection/${engineID}`;

  static dataCollectionProfileUploadPath = (
    engineID = ':engineID',
    profileID = ':profileID'
  ) => `/data-collection/${engineID}/${profileID}`;

  static dataCollectionSectionPath = (
    engineID = ':engineID',
    profileID = ':profileID',
    sectionID = ':sectionID'
  ) => `/data-collection/${engineID}/${profileID}/${sectionID}`;

  static dataCollectionSubSectionPath = (
    engineID = ':engineID',
    profileID = ':profileID',
    sectionID = ':sectionID',
    subSectionID = ':subSectionID'
  ) => `/data-collection/${engineID}/${profileID}/${sectionID}/${subSectionID}`;

  static dataReCollectionRoute = '/data-recollection';
  static dataReCollectionProfileUploadPath = (
    engineID = ':engineID',
    profileID = ':profileID'
  ) => `/data-recollection/${engineID}/${profileID}`;

  static dataReCollectionSectionPath = (
    engineID = ':engineID',
    profileID = ':profileID',
    sectionID = ':sectionID'
  ) => `/data-recollection/${engineID}/${profileID}/${sectionID}`;

  static dataReCollectionSubSectionPath = (
    engineID = ':engineID',
    profileID = ':profileID',
    sectionID = ':sectionID',
    subSectionID = ':subSectionID'
  ) =>
    `/data-recollection/${engineID}/${profileID}/${sectionID}/${subSectionID}`;

  static navigateTo(props, path) {
    props.history.push(path);
  }
}

export default RoutePath;
