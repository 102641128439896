import ReduxActionEnum from '../../models/ReduxActionEnum';
import VideoPlayerInitialState from '../states/VideoPlayerInitialState';

const VideoPlayerReducer = (state = VideoPlayerInitialState, action) => {
  switch (action.type) {
    case ReduxActionEnum.OPEN_PLAYER:
      return {
        playerVisible: true,
        ...action.payload,
      };

    case ReduxActionEnum.CLOSE_PLAYER:
      return {
        ...VideoPlayerInitialState,
      };

    default:
      return state;
  }
};

export default VideoPlayerReducer;
