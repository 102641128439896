let DataCollectionPageStyles = (theme) => ({
  portraitRoot: {
    //   position: 'relative',
    overflow: 'hidden',
  },
  landscapeRoot: {
    //   position: 'relative',
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    // overflow: 'hidden',
  },
  previewContainer: {
    // position: 'relative',
    height: '100vh',
  },
  overlayImageContainer: {
    position: 'relative',
  },
  videoContainer: {},
  videoRecorder: {
    position: 'absolute',
    // zIndex: 100000,
    top: 0,
    left: 0,
    textAlign: 'center',
    color: 'white',
    // display: 'flex',
    // justifyContent: 'center',
    // flexDirection: 'row',
    // alignItems: 'center',
  },
  overlayImage: {
    position: 'absolute',
    top: 0,
    // left: '50%',
    // transform: 'translate(-50%, 0)',
    opacity: '60%',
    zIndex: theme.zIndex.overlayImage,
    // maxWidth: '70vw!important',
  },
  referenceImage: {
    position: 'absolute',
    top: 0,
    // left: '50%',
    // transform: 'translate(-50%, 0)',
    // opacity: '60%',
    zIndex: theme.zIndex.overlayImage,
    // maxWidth: '70vw!important',
  },
  labelText: {
    color: 'white',
    padding: 5,
    borderRadius: 2,
    opacity: 0.85,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 5,
    left: 10,
    zIndex: 1400,
  },
  imageLabel: {
    color: 'white',
    padding: 5,
    borderRadius: 2,
    opacity: 0.85,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 5,
    left: '45%',
    transform: 'translate(-50%,0)',
    zIndex: 1400,
  },
  bottomInstructions: {
    color: 'white',
    textAlign: 'center',
    padding: 5,
    borderRadius: 2,
    width: 'fit-content',
    maxWidth: '73%',
    opacity: 0.85,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    bottom: 5,
    // left: 'calc(100vw*4/6 - 52%)',
    left: '45%',
    transform: 'translate(-50%,0)',
    zIndex: 1400,
  },
  replayVideoPlayer: {
    // '&::-webkit-media-controls-panel': {
    //   position: 'sticky',
    //   bottom: 0,
    //   // height: '100vh',
    //   backgroundColor: 'red',
    // },
    '&::-webkit-full-page-media::-webkit-media-controls-panel': {
      position: 'sticky',
      bottom: 0,
      // height: '100vh',
      backgroundColor: 'red',
    },
    '&::-webkit-media-controls-timeline-container': {
      position: 'sticky',
      bottom: 0,
      left: 0,
      // height: '100vh',
      backgroundColor: 'red',
    },
    // '&::-webkit-media-controls-timeline': {
    //   position: 'sticky',
    //   bottom: 0,
    //   // height: '100vh',
    //   backgroundColor: 'red',
    // },
    // 'video::-webkit-media-controls-panel': {
    //   // display: 'none',
    //   bottom: 0,
    // },
  },
  portraitImageHeight: {
    height: 'calc(100vh - 100px)',
  },
  landscapeImageHeight: {
    height: '100vh',
  },
});

export default DataCollectionPageStyles;
