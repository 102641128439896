import GeneralReducer from './GeneralReducer';
import PortionSelectionReducer from './PortionSelectionReducer';
import SettingsReducer from './SettingsReducer';
import UserReducer from './UserReducer';
import VideoPlayerReducer from './VideoPlayerReducer';
import { combineReducers } from 'redux';

const RootReducer = combineReducers({
  PortionSelectionReducer,
  GeneralReducer,
  VideoPlayerReducer,
  UserReducer,
  SettingsReducer,
});

export default RootReducer;
