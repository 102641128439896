import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import LandingPageStyles from './InvitePageStyles';
import UserService from '../../services/UserService';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = LandingPageStyles;

class InvitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleInviteUser = async () => {
    let { email } = this.state;
    let data = { email };
    let response = await UserService.invite(data);
    if (response.success) {
      console.log(
        '🚀 ~ file: InvitePage.js ~ line 24 ~ InvitePage ~ handleInviteUser= ~ response',
        response
      );
      toast.success(response.message);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let { classes } = this.props;
    let { email } = this.state;
    return (
      <div className={classes.root}>
        <fieldset>
          <legend>Invite Someone</legend>

          <div className={classes.container}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={10}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Email Address"
                  id="email"
                  name="email"
                  value={email}
                  autoComplete="email"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={this.handleInviteUser}
                >
                  <AddCircleTwoToneIcon />
                  &nbsp;Invite
                </Button>
              </Grid>
            </Grid>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default withStyles(styles)(InvitePage);
