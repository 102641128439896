import { Button, Fab, Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import DesktopNotificationCard from './DesktopNotificationCard/DesktopNotificationCard';
import EngineService from '../../services/EngineService';
import FilterListIcon from '@material-ui/icons/FilterList';
import MobileNotificationCard from './MobileNotificationCard/MobileNotificationCard';
import NotificationPageFilterModal from './NotificationPageFilterModal/NotificationPageFilterModal';
import NotificationsPageStyles from './NotificationsPageStyles';
import { isMobile } from 'mobile-device-detect';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = NotificationsPageStyles;

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsList: [],
      filteredNotificationsList: [],
      searchString: '',
      selectedTypeList: [],
      selectedTechnicianList: [],
      selectedLevelList: [],
      filterModalOpen: false,
      selectedStartDate: undefined,
      selectedEndDate: undefined,
    };
  }

  componentDidMount = () => {
    this.handleFetchAllRecievedComments();
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
      selectedLevelList,
      selectedTypeList,
    } = this.state;
    if (
      prevState.selectedTechnicianList !== selectedTechnicianList ||
      prevState.selectedStartDate !== selectedStartDate ||
      prevState.selectedEndDate !== selectedEndDate ||
      prevState.selectedLevelList !== selectedLevelList ||
      prevState.selectedTypeList !== selectedTypeList
    ) {
      this.handleGetFilteredComments();
    }
  };

  handleFetchAllRecievedComments = async () => {
    let { selectedStartDate } = this.state;
    let data = {};
    let response = await EngineService.fetchAllRecievedComments(data);
    console.log('handleFetchAllRecievedComments', response);
    if (response.success) {
      // let filteredNotificationsList = response.data;
      this.setState(
        {
          notificationsList: response.data,
          // filteredNotificationsList,
        },
        () => {
          if (!selectedStartDate) {
            this.setInitialDates();
          } else {
            this.handleGetFilteredComments();
          }
        }
      );
    }
  };

  setInitialDates = () => {
    // let { initialStartDate, initialEndDate } = this.props;
    let { notificationsList } = this.state;
    this.setState(
      {
        selectedEndDate:
          notificationsList.length &&
          new Date(notificationsList[0].comment_data.ts_creation),
        selectedStartDate:
          notificationsList.length &&
          new Date(
            notificationsList[
              notificationsList.length - 1
            ].comment_data.ts_creation
          ),
      },
      this.handleGetFilteredComments
    );
  };

  handleGetFilteredComments = (
    notificationsList = this.state.notificationsList
  ) => {
    let {
      filterModalOpen,
      engineList,

      searchString,
      selectedTypeList,
      selectedTechnicianList,
      selectedLevelList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    console.log('selectedTechnicianList', selectedTechnicianList);
    let filteredNotificationsList = notificationsList.filter(
      (notificationData, index) => {
        let notificationDate = new Date(
          notificationData.comment_data.ts_creation
        );

        // Date: From-To
        // Sender
        // Sender Level
        // Type: Accept, Reject, Comment

        let startDateMatched = false;
        console.log(
          selectedStartDate,
          new Date(selectedStartDate).getTime(),
          notificationDate.getTime(),
          new Date(selectedStartDate).getTime() <= notificationDate.getTime()
        );
        if (
          new Date(selectedStartDate).getTime() <= notificationDate.getTime()
        ) {
          startDateMatched = true;
        }

        let endDateMatched = false;
        if (new Date(selectedEndDate).getTime() >= notificationDate.getTime()) {
          endDateMatched = true;
        }

        let senderMatched = false;
        if (selectedTechnicianList.length) {
          selectedTechnicianList.forEach((technicianData, index) => {
            if (
              notificationData.comment_data.sender.firstname ===
              technicianData.awi_personal.firstname
            ) {
              senderMatched = true;
            }
          });
        } else {
          senderMatched = true;
        }

        let senderLevelMatched = false;
        if (selectedLevelList.length) {
          selectedLevelList.forEach((levelName, index) => {
            if (
              notificationData.comment_data.sender_title ===
              levelName.toLowerCase()
            ) {
              senderLevelMatched = true;
            }
          });
        } else {
          senderLevelMatched = true;
        }

        let typeMatched = false;
        if (selectedTypeList.length) {
          selectedTypeList.forEach((typeName, index) => {
            if (notificationData.comment_data.type === typeName) {
              console.log('true');
              typeMatched = true;
            }
          });
        } else {
          typeMatched = true;
        }

        console.log(startDateMatched);
        console.log(endDateMatched);
        console.log(senderMatched);
        console.log(typeMatched);
        console.log(senderLevelMatched);
        console.log('__________________');

        return (
          endDateMatched &&
          startDateMatched &&
          senderMatched &&
          typeMatched &&
          senderLevelMatched
        );
      }
    );
    this.setState({ filteredNotificationsList });
  };

  handleFilterModalOpen = () => {
    this.setState({ filterModalOpen: true });
  };

  handleFilterModalClose = () => {
    this.setState({ filterModalOpen: false });
  };

  setSearchString = (searchString) => {
    this.setState({ searchString });
  };

  handleSetSelectedType = (selectedTypeList) => {
    this.setState({ selectedTypeList });
  };

  setSelectedTechnicianList = (selectedTechnicianList) => {
    this.setState({ selectedTechnicianList });
  };

  setSelectedLevelList = (selectedLevelList) => {
    this.setState({ selectedLevelList });
  };

  setSelectedStartDate = (selectedStartDate) => {
    this.setState({ selectedStartDate });
  };

  setSelectedEndDate = (selectedEndDate) => {
    this.setState({ selectedEndDate });
  };

  handleMarkAllAsRead = async () => {
    let { filteredNotificationsList } = this.state;

    let awi_comment_ids = filteredNotificationsList.map(
      (notificationData, index) => {
        return notificationData.comment_data.awi_comment_id;
      }
    );
    let data = { awi_comment_ids };
    let response = await EngineService.updateCommentStatus(data);
    console.log('updateCommentStatus', data, response);
    if (response.success) {
      toast.success(response.message);
      this.handleFetchAllRecievedComments();
    }
  };

  render() {
    let { classes } = this.props;
    let {
      notificationsList,
      filteredNotificationsList,
      filteredEngineList,
      filterModalOpen,
      engineList,

      searchString,
      selectedTypeList,
      selectedLevelList,
      selectedTechnicianList,
      selectedStartDate,
      selectedEndDate,
    } = this.state;

    console.log('filteredNotificationsList', filteredNotificationsList);
    return (
      <div className={classes.root}>
        <div className={classes.headingContainer}>
          {isMobile ? (
            <div className={classes.mobileHeadingContainer}>
              <Typography
                variant="body1"
                // align="center"
                className={classes.mobileHeading}
              >
                NOTIFICATIONS
              </Typography>
              <Button onClick={this.handleMarkAllAsRead} variant="contained">
                Mark All as Read
              </Button>
            </div>
          ) : (
            <div className={classes.headingContainer}>
              <Typography
                variant="h4"
                // align="center"
                className={classes.heading}
              >
                Notifications
              </Typography>
              <Button onClick={this.handleMarkAllAsRead} variant="outlined">
                Mark All as Read
              </Button>
            </div>
          )}
        </div>
        <div className={classes.pageContainer}>
          {/* NotificationsPage */}
          {notificationsList.length ? (
            filteredNotificationsList.length ? (
              <Grid container direction="column" spacing={4}>
                {filteredNotificationsList.map((notificationData, index) => {
                  return (
                    <Grid item key={index}>
                      {isMobile ? (
                        <MobileNotificationCard
                          notificationData={notificationData}
                        />
                      ) : (
                        <DesktopNotificationCard
                          notificationData={notificationData}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography>No Notification Data for selected Filters</Typography>
            )
          ) : (
            <Typography>No Notification Data Available</Typography>
          )}
        </div>

        <Fab
          onClick={this.handleFilterModalOpen}
          color="primary"
          aria-label="filter"
          className={classes.fab}
          style={!isMobile ? { bottom: 16 } : null}
        >
          <FilterListIcon />
        </Fab>
        <NotificationPageFilterModal
          open={filterModalOpen}
          onClose={this.handleFilterModalClose}
          searchString={searchString}
          setSearchString={this.setSearchString}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={this.setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={this.setSelectedEndDate}
          selectedTypeList={selectedTypeList}
          setSelectedType={this.handleSetSelectedType}
          selectedLevelList={selectedLevelList}
          setSelectedLevelList={this.setSelectedLevelList}
          selectedTechnicianList={selectedTechnicianList}
          setSelectedTechnicianList={this.setSelectedTechnicianList}
          // selectedStartDate={
          //   engineList.length && engineList[engineList.length - 1].ts_creation
          // }
          // initialEndDate={this.handleSetSelectedType}
        />
      </div>
    );
  }
}

export default withStyles(styles)(NotificationsPage);
