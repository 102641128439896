import { Divider, Grid } from '@material-ui/core';
import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import GenUtil from '../../util/GenUtil';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import RoutePath from '../../lib/RoutePath';
import SignupContainerStyles from './SignupContainerStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import UserActions from '../../redux/actions/UserActions';
import UserService from '../../services/UserService';
import { connect } from 'react-redux';
import { toast } from 'material-react-toastify';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = SignupContainerStyles;

class SignupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (process.env.NODE_ENV === 'development') {
      this.state = {
        email: '',
        password: '123123',
        tokenValid: undefined,
        parentUserToken: undefined,
        parentUserTokenObj: undefined,
        firstname: 'shivansh',
        lastname: 'test',
        location: 'gurgam',
        designation: 'frontend',
        signupButtonDisabled: false,
        // email: 'demo@awiros.com',
        // password: 'awisys555',
      };
    } else if (process.env.NODE_ENV === 'production') {
      this.state = {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        location: '',
        designation: '',
        tokenValid: undefined,
        parentUserToken: undefined,
        parentUserTokenObj: undefined,
        signupButtonDisabled: false,
        // email: 'demo@awiros.com',
        // password: 'awisys555',
      };
    }
  }

  componentDidMount = () => {
    this.checkTokenValidity();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {};

  checkTokenValidity = () => {
    let params = new URL(document.location).searchParams;
    let token = params.get('t');
    console.log(token);
    if (token) {
      this.setState({ parentUserToken: token });
      try {
        let tokenObj;
        tokenObj = JSON.parse(window?.atob(token.split('.')[1]));
        console.log(
          '🚀 ~ file: SignupContainer.js ~ line 61 ~ SignupContainer ~ tokenObj',
          tokenObj
        );
        if (new Date().getTime() < tokenObj.exp * 1000) {
          this.setState({
            tokenValid: true,
            parentUserToken: token,
            parentUserTokenObj: tokenObj,
            email: tokenObj.user,
            // email: 'abc@gg.com',
          });
        } else {
          toast.error('Invite Expired!!! Please contact your admin');
          RoutePath.navigateTo(this.props, RoutePath.signupPath);
          this.setState({
            tokenValid: false,
            parentUserToken: undefined,
            parentUserTokenObj: undefined,
            email: undefined,
          });
          // console.log('login');
        }
      } catch (error) {
        RoutePath.navigateTo(this.props, RoutePath.signupPath);
        this.setState({
          tokenValid: false,
          parentUserToken: undefined,
          parentUserTokenObj: undefined,
          email: undefined,
        });
      }
    } else {
      this.setState({
        tokenValid: undefined,
        parentUserToken: undefined,
        parentUserTokenObj: undefined,
      });
    }
  };

  handleSignup = async () => {
    let {
      email,
      password,
      parentUserToken,
      firstname,
      lastname,
      location,
      designation,
    } = this.state;
    let data = {
      email,
      password,
      location,
      title: designation,
      awi_personal: {
        firstname,
        lastname,
      },
    };
    // debugger;
    this.setState({ signupButtonDisabled: true });
    let response = await UserService.signup(data, parentUserToken);
    console.log(response);
    if (response.success) {
      console.log(
        '🚀 ~ file: SignupContainer.js ~ line 50 ~ handleSignup ~ response.data',
        response.data
      );
      toast.success(response.message);
    } else {
      toast.error(response.message);
      this.setState({ signupButtonDisabled: false });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.handleSignup();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let { classes } = this.props;
    let {
      email,
      password,
      parentUserToken,
      tokenValid,
      firstname,
      lastname,
      location,
      designation,
      signupButtonDisabled,
    } = this.state;
    return (
      <div className={classes.root}>
        {tokenValid ? (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="center"
                spacing={2}
                className={classes.logoContainer}
              >
                <Grid item>
                  <img
                    src={
                      require('./../../assets/images/percept-logo.png').default
                    }
                    alt="percept"
                    className={classes.logoImage}
                  />
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <img
                    src={
                      require('./../../assets/images/raytheon-logo.png').default
                    }
                    alt="raytheon"
                    className={classes.logoImage}
                  />
                </Grid>
              </Grid>

              <Typography variant="body1" align="center" bold>
                Welcome, {email}
              </Typography>
              <Typography variant="body2" align="center">
                Enter your details to Sign Up
              </Typography>
              <form
                className={classes.form}
                onSubmit={this.handleSubmit}
                // noValidate
              >
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="First Name"
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      autoComplete="given-name"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      // autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Last Name"
                      id="lastname"
                      name="lastname"
                      value={lastname}
                      autoComplete="family-name"
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                {/* <TextField
                  required
                  //autoFocus
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Email Address"
                  id="email"
                  name="email"
                  value={email}
                  autoComplete="email"
                  onChange={this.handleChange}
                /> */}
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={this.handleChange}
                />

                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  margin="normal"
                  label="Location"
                  name="location"
                  id="location"
                  value={location}
                  autoComplete="current-password"
                  onChange={this.handleChange}
                />
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  margin="normal"
                  label="Designation"
                  name="designation"
                  id="designation"
                  value={designation}
                  autoComplete="current-password"
                  onChange={this.handleChange}
                />
                {/*<FormControlLabel*/}
                {/*   control={<Checkbox value="remember" color="primary"/>}*/}
                {/*   label="Remember me"*/}
                {/*/>*/}
                <div style={{ height: 40 }}></div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={signupButtonDisabled}
                  // onClick={this.handleLogin}
                  // className={classes.submit}
                >
                  Sign Up
                </Button>
                {/*<Grid container>*/}
                {/*   <Grid item xs>*/}
                {/*      <Link href="#" variant="body2">*/}
                {/*         Forgot password?*/}
                {/*      </Link>*/}
                {/*   </Grid>*/}
                {/*   <Grid item>*/}
                {/*      <Link href="#" variant="body2">*/}
                {/*         {"Don't have an account? Sign Up"}*/}
                {/*      </Link>*/}
                {/*   </Grid>*/}
                {/*</Grid>*/}
              </form>
            </div>
          </Container>
        ) : (
          <div>
            <Typography variant="h5" align="center">
              This page does not exist. Please contact your manager if you think
              this is a mistake.
            </Typography>
          </div>
        )}

        <img
          className={classes.poweredByAwirosLogo}
          src={require('./../../assets/images/powered-by-awiros.png').default}
          alt="powered by awiros"
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    loggedIn: state.UserReducer.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (data) => dispatch(UserActions.loginUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SignupContainer));
