import {
  Badge,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  capitalizeFirstLetter,
  convertUnderscoreCaseToTitleCase,
} from '../../../util/CommonUtils';

import EngineService from '../../../services/EngineService';
import MobileNotificationCardStyles from './MobileNotificationCardStyles';
import RoutePath from '../../../lib/RoutePath';
import TypeEnum from '../../../models/TypeEnum';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = MobileNotificationCardStyles;

class MobileNotificationCard extends Component {
  handleNavigateToEngineDetailsURL = () => {
    //     awi_comment_id: "1613536401879"
    // awi_content_label: "lf_1a"
    // awi_content_type: "sub_section"
    // awi_engine_type: "video"
    // awi_parent_label: "left front"
    // awi_root_parent_label: "left"
    // awi_serial_num: "V23444"
    // is_seen: false
    let { notificationData } = this.props;
    let queryParams;
    if (notificationData.comment_data.type === 'comment') {
      if (notificationData.comment_data.awi_root_parent_label) {
        queryParams = `?openComments=true&expandedProfile=${notificationData.comment_data.awi_root_parent_label}&expandedSection=${notificationData.comment_data.awi_parent_label}&expandedSubSection=${notificationData.comment_data.awi_content_label}`;
      } else if (notificationData.comment_data.awi_parent_label) {
        queryParams = `?openComments=true&expandedProfile=${notificationData.comment_data.awi_parent_label}&expandedSection=${notificationData.comment_data.awi_content_label}`;
      } else if (notificationData.comment_data.awi_content_label) {
        queryParams = `?openComments=true&expandedProfile=${notificationData.comment_data.awi_content_label}`;
      }
    } else {
      if (notificationData.comment_data.awi_root_parent_label) {
        queryParams = `?expandedProfile=${notificationData.comment_data.awi_root_parent_label}&expandedSection=${notificationData.comment_data.awi_parent_label}`;
      } else if (notificationData.comment_data.awi_parent_label) {
        queryParams = `?expandedProfile=${notificationData.comment_data.awi_parent_label}`;
      } else if (notificationData.comment_data.awi_content_label) {
        queryParams = '';
      }
    }
    this.handleMarkCommentAsRead(notificationData);

    if (notificationData.comment_data.type === 'cancellation') {
      RoutePath.navigateTo(this.props, RoutePath.engineList);
    } else {
      RoutePath.navigateTo(
        this.props,
        `${RoutePath.engineDetails(
          notificationData.comment_data.awi_engine_id
        )}${queryParams}`
      );
    }
  };

  handleMarkCommentAsRead = async (notificationData) => {
    let data = {
      awi_comment_ids: [notificationData.comment_data.awi_comment_id],
    };
    let response = await EngineService.updateCommentStatus(data);
    console.log('updateCommentStatus', data, response);
    if (response.success) {
      // toast.success(response.message);
      // this.handleFetchAllRecievedComments();
    }
  };

  render() {
    let { classes, notificationData } = this.props;
    let actionTaken = '';
    let borderColor = '';
    if (notificationData.comment_data.type === TypeEnum.COMMENT) {
      actionTaken = 'commented on your Image';
      borderColor = 'blue';
    } else if (notificationData.comment_data.type === TypeEnum.APPROVAL) {
      actionTaken = `approved a ${notificationData.comment_data.awi_content_type}`;
      borderColor = 'green';
    } else if (notificationData.comment_data.type === TypeEnum.REJECTION) {
      actionTaken = `rejected a ${notificationData.comment_data.awi_content_type}`;
      borderColor = 'red';
    } else if (notificationData.comment_data.type === TypeEnum.CANCELLATION) {
      actionTaken = 'cancelled an Engine';
      borderColor = 'orange';
    } else if (notificationData.comment_data.type === TypeEnum.NEW_ENGINE) {
      actionTaken = 'added a new Engine';
      borderColor = 'blue';
    }
    console.log(notificationData);
    return (
      <Badge
        invisible={notificationData.comment_data.is_seen}
        variant="dot"
        color="error"
      >
        <Card>
          <Grid
            container
            component={CardActionArea}
            direction="row"
            onClick={this.handleNavigateToEngineDetailsURL}
            className={classes.root}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={7} container direction="column" spacing={2}>
              <Grid
                item
                // xs={7}
                container
                direction="row"
                justify="space-between"
                spacing={1}
              >
                <Grid item xs={3}>
                  <div className={classes.profileIconThumbnail}>
                    {notificationData.comment_data.sender.firstname
                      .charAt(0)
                      .toUpperCase()}
                    {notificationData.comment_data.sender.lastname
                      .charAt(0)
                      .toUpperCase()}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div>
                    <Typography variant={'body2'} noWrap>
                      {capitalizeFirstLetter(
                        notificationData.comment_data.sender.firstname
                      )}
                      &nbsp;
                      {capitalizeFirstLetter(
                        notificationData.comment_data.sender.lastname
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant={'body2'} noWrap>
                      {capitalizeFirstLetter(
                        notificationData.comment_data.sender_title
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant={'caption'} component={'span'} noWrap>
                    {moment(notificationData.comment_data.ts_creation).format(
                      'LT'
                    )}
                    <br />
                    {moment(notificationData.comment_data.ts_creation).format(
                      'l'
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                // xs={7}
                container
                direction="row"
                justify="space-between"
                spacing={1}
              >
                <Typography
                  variant={'body2'}
                  component={'span'}
                  style={{ color: borderColor }}
                >
                  {capitalizeFirstLetter(
                    notificationData.comment_data.sender.firstname
                  )}{' '}
                  {actionTaken}
                </Typography>

                <Typography variant={'body2'} component={'span'}>
                  {notificationData.comment_data.text}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider orientation="vertical" />
            </Grid>

            <Grid
              item
              xs={5}
              container
              direction="row"
              spacing={1}
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                {notificationData.comment_data.awi_engine_type === 'image' ? (
                  <img
                    src={notificationData.awi_image_url}
                    alt={notificationData.comment_data.awi_content_label}
                    className={classes.thumbnailImage}
                  />
                ) : (
                  <video
                    src={notificationData.awi_image_url}
                    alt={notificationData.comment_data.awi_content_label}
                    className={classes.thumbnailImage}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="column"
                justify="center"
                // alignItems="center"
              >
                <Typography variant={'caption'} noWrap>
                  ESN: {notificationData.comment_data.awi_serial_num}
                </Typography>
                <Typography variant={'caption'} noWrap>
                  {convertUnderscoreCaseToTitleCase(
                    notificationData.comment_data.awi_content_type
                  )}
                  :
                  <br /> {notificationData.comment_data.awi_content_label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Badge>
    );
  }
}

export default withRouter(withStyles(styles)(MobileNotificationCard));
