import {
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  capitalizeFirstLetter,
  convertToTitleCase,
  convertUnderscoreCaseToTitleCase,
} from '../../../util/CommonUtils';

import CancelIcon from '@material-ui/icons/Cancel';
import EngineService from '../../../services/EngineService';
import Paper from '@material-ui/core/Paper';
import ReviewConfirmationModalStyles from './ReviewConfirmationModalStyles';
import clsx from 'clsx';
import { toast } from 'material-react-toastify';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = ReviewConfirmationModalStyles;

class ReviewConfirmationModal extends Component {
  static defaultProps = {
    open: true,
    onClose: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      commentString: '',
    };
  }

  handleApprovePortion = async (e) => {
    e.stopPropagation();
    let { reviewPortionData } = this.props;
    // console.log(portionData, portionData?.awi_type);

    let { engineID } = this.props.match.params;
    let data = {
      awi_ids: [reviewPortionData.id],
      type: reviewPortionData?.awi_type,
      awi_engine_id: engineID,
    };
    // toast.success('handleApprovePortion');
    console.log('handleApprovePortion', data);
    let response = await EngineService.approvePortions(data);
    console.log('handleApprovePortion', response);
    if (response.success) {
      this.handleReviewPortionNotifications();
      this.props.handleGetEngineStatusInHeirarchy();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  handleRejectPortion = async (e) => {
    e.stopPropagation();
    let { reviewPortionData } = this.props;
    // console.log(portionData, portionData?.awi_type);
    let { engineID } = this.props.match.params;
    let data = {
      awi_ids: [reviewPortionData.id],
      type: reviewPortionData?.awi_type,
      awi_engine_id: engineID,
    };
    // toast.success('handleRejectPortion');
    console.log('handleRejectPortion', data);
    let response = await EngineService.rejectPortions(data);
    console.log('handleApprovePortion', response);
    if (response.success) {
      this.handleReviewPortionNotifications();
      this.props.handleGetEngineStatusInHeirarchy();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  handleReviewPortionNotifications = async () => {
    let {
      reviewConfirmationMode,
      reviewPortionData,
      profileData,
      sectionData,
    } = this.props;
    let { commentString } = this.state;
    console.log('handleApprovePortion', reviewPortionData);

    let parentLabel = undefined;
    let rootParentLabel = undefined;
    if (sectionData) {
      parentLabel = sectionData.awi_label;
      rootParentLabel = profileData.awi_label;
    } else if (profileData) {
      parentLabel = profileData.awi_label;
    }

    let data = {
      awi_engine_id: reviewPortionData.awi_engine_id,
      awi_content_id: reviewPortionData.awi_content_id,
      text: commentString,
      type: reviewConfirmationMode,
      awi_content_type: reviewPortionData.awi_type,
      awi_content_label: reviewPortionData.awi_label,
      awi_parent_label: parentLabel,
      awi_root_parent_label: rootParentLabel,
    };
    console.log('handleApprovePortionNotifications', data);
    let response = await EngineService.addNewComment(data);
    console.log('handleApprovePortionNotifications', response);
    if (response.success) {
      this.props.onClose();
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let {
      classes,
      open,
      onClose,
      reviewConfirmationMode,
      reviewPortionData,
      profileData,
    } = this.props;
    let { commentString } = this.state;
    return (
      <div className={classes.root}>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.modalContainer}>
            <Paper className={classes.modalPaper}>
              <div className={classes.closeButtonContainer}>
                <IconButton onClick={onClose} className={classes.closeButton}>
                  <CancelIcon />
                </IconButton>
              </div>

              <div
                className={clsx(classes.contentContainer, 'scrollbar_primary')}
              >
                <Typography variant="h5">
                  <span>
                    {capitalizeFirstLetter(reviewConfirmationMode)}{' '}
                    {convertUnderscoreCaseToTitleCase(
                      reviewPortionData?.awi_type
                    )}
                    :&nbsp;
                  </span>
                  <span>
                    {convertToTitleCase(reviewPortionData?.awi_label)}
                  </span>
                </Typography>
                <br />
                <br />
                <div>
                  <TextField
                    multiline
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Comment (Optional)"
                    rowsMax={4}
                    value={commentString}
                    name={'commentString'}
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                </div>

                <Button
                  fullWidth
                  type="submit"
                  color={
                    reviewConfirmationMode === 'approve'
                      ? 'primary'
                      : 'secondary'
                  }
                  variant="contained"
                  className={classes[`${reviewConfirmationMode}Button`]}
                  onClick={
                    this[
                      `handle${capitalizeFirstLetter(
                        reviewConfirmationMode
                      )}Portion`
                    ]
                  }
                >
                  {capitalizeFirstLetter(reviewConfirmationMode)}{' '}
                  {convertUnderscoreCaseToTitleCase(
                    reviewPortionData?.awi_type
                  )}
                </Button>
                {/* <Typography variant="h5"></Typography> */}
              </div>
            </Paper>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ReviewConfirmationModal));
