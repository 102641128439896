import ReduxActionEnum from '../../models/ReduxActionEnum';

let PortionSelectionActions = {
  setExpandedProfile: (value) => {
    return {
      type: ReduxActionEnum.SET_EXPANDED_PROFILE,
      payload: value,
    };
  },
  setExpandedSection: (value) => {
    return {
      type: ReduxActionEnum.SET_EXPANDED_SECTION,
      payload: value,
    };
  },
  setExpandedSubSection: (value) => {
    return {
      type: ReduxActionEnum.SET_EXPANDED_SUB_SECTION,
      payload: value,
    };
  },
};

export default PortionSelectionActions;
