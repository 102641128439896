let TechnicianEngineDetailsCardStyles = (theme) => ({
  root: {
    padding: 5,
    height: '100%',
  },
  thumbnailImage: {
    height: 180,
    textAlign: 'center',
  },
  disabledCardRoot: {
    cursor: 'not-allowed',
  },
  disabledCard: {
    background: 'rgba(0,0,0,0.3)',
  },
  boldText: {
    fontWeight: 'bold',
  },
});

export default TechnicianEngineDetailsCardStyles;
