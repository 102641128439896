import ReduxActionEnum from "../../models/ReduxActionEnum";

const VideoPlayerActions = {
  openVideoPlayer: (videoDetails,type) => {
    return {
      type: ReduxActionEnum.OPEN_PLAYER,
      payload: videoDetails,
    };
  },

  closeVideoPlayer: () => {
    return {
      type: ReduxActionEnum.CLOSE_PLAYER,
      payload: {},
    };
  },
};

export default VideoPlayerActions;
