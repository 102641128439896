import { fade, lighten } from '@material-ui/core';

let TechnicianListStyles = (theme) => ({
  root: {
    position: 'relative',
    overflowX: 'hidden',
  },
  mobileHeadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1, 2),
    color: 'white',
    backgroundColor: '#204e79',
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headingContainer: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100vw',
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    //   color: 'white',
    //   backgroundColor: '#204e79',
  },
  pageContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  // Toolbar
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
    // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  toolbarOptionsContainer: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
    // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '8ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },

  tableContainer: {
    maxWidth: '100%',
    maxHeight: `calc(100vh - 172px)`,
  },
  table: {
    // minWidth: 750,
    maxHeight: '20vh',
  },
  imageThumbnail: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.text.primary,
    },
  },
  grow: {
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  profileIconThumbnail: {
    // width: 100,
    // height: 100,
    padding: 13,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    width: 'fit-content',
    margin: 'auto',
    color: 'white',
    fontWeight: 700,
  },

  noDataText: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    textAlign: 'center',

    // position: 'absolute',
    // left: 0,
    // right: 0,
    // margin: 'auto',
  },

  statusCircle: {
    width: 12,
    margin: '0 auto',
    height: 12,
    border: '6px solid',
    borderRadius: '50%',
  },
  statusCirclePresent: {
    borderColor: '#79d70f',
  },
  statusCircleAbsent: {
    borderColor: 'red',
  },

  actionCell: {
    minWidth: 135,
  },
});

export default TechnicianListStyles;
